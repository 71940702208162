<template>
	<v-container fluid dense>
		<PageTitle icon="mdi mdi-phone" main="Telemarketing" sub=""></PageTitle>
		<v-col>	
			<v-row>
				<v-col>
					<v-btn block class="d-flex pa-3 font-weight-bold" style="font-size: small;" color="success darken-3" dark @click="reset">
						<v-flex>Lista Principal</v-flex>
						<v-flex><h1></h1></v-flex><br>
					</v-btn>	
				</v-col>
				<v-col>
					<v-btn block class="d-flex pa-3 font-weight-bold" style="font-size: small;" color="info darken-3" dark @click.prevent="link">
						<v-flex>Agendados</v-flex>
						<v-flex><h1>{{ stacks.ag }}</h1></v-flex><br>
					</v-btn>	
				</v-col>
				<v-col>
					<v-btn block class="d-flex pa-3 font-weight-bold" style="font-size: small;" color="error darken-3" dark @click="loadStatus('nao_interessado')">
						<v-flex>Não Interessados</v-flex>
						<v-flex><h1>{{ stacks.ni }}</h1></v-flex><br>
					</v-btn>	
				</v-col>
				<v-col>	
					<v-btn block class="d-flex pa-3 font-weight-bold" style="font-size: small;" color="orange darken-1" dark @click="loadStatus('ligar_depois')">
						<v-flex>Ligar Depois</v-flex>
						<v-flex><h1>{{ stacks.ld }}</h1></v-flex><br>
					</v-btn>
				</v-col>
				<v-col>
					<v-btn block class="d-flex pa-3 font-weight-bold" style="font-size: small;" color="grey darken-1" dark @click="loadStatus('sem_resposta')">
						<v-flex>Sem Resposta</v-flex>
						<v-flex><h1>{{ stacks.sr }}</h1></v-flex><br>
					</v-btn>	
				</v-col>
			</v-row>
		</v-col>
		<v-col>
			<v-dialog v-model="dialog" width="700">
				<v-card>
					<v-card-title>
						<v-layout>
							<v-flex lg="12">
								<small>{{ title.nome }}</small>
							</v-flex>
							<v-flex lg="12">
								<small v-for=" (num,i) in title.fones" :key="i">
									<small>{{ num }}</small>
								</small>
							</v-flex>
							<v-flex>
								<v-btn @click="editarCliente(title)" x-small color="orange">
									<v-icon x-small>mdi-pencil</v-icon> editar
								</v-btn>
							</v-flex>
						</v-layout>
					</v-card-title>
					<v-card-actions>
						<v-btn x-small color="success" @click="efetivar(title)" class="mr-1">
							efetivar
						</v-btn>
						<v-btn x-small color="info" @click="addAgendamento(title)" class="mr-1">
							agendar
						</v-btn>
						<v-btn x-small color="red" dark @click="addMotivo(title, 'nao_interessado')">
							sem interesse
						</v-btn>
						<v-btn x-small color="orange" dark @click="addMotivo(title, 'ligar_depois')">
							ligar depois
						</v-btn>
						<v-btn x-small color="grey" dark @click="addMotivo(title, 'sem_resposta')">
							sem resposta
						</v-btn>
						<v-btn x-small color="error" outlined @click="removeLista(title.id_telemarketing)" v-show="user.cargo === 'Administrador'">
							<v-icon x-small>mdi-delete</v-icon>
							excluir da lista
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-alert class="font-italic font-weight-bold" style="font-size: small;" :color="status.cor" dense dark v-show="status.show">
				{{ status.title }}
			</v-alert>
			<v-row align="center" justify="space-around">
				<v-col md="2" sm="12" v-show="selected.length > 1">
					<v-fab-transition>
						<v-btn color="error" dark fixed center class="elevation-5" @click="remove">
							<v-icon>mdi-delete</v-icon>
							excluir seleção 
						</v-btn>
					</v-fab-transition>
				</v-col>
			</v-row>
			<v-col>
				<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>
			<v-data-table :items="telemarketing" :headers="table" :search="busca" show-select item-key=id_telemarketing v-model="selected" dense>
				<template v-slot:item.nome_cliente ="{ item }">
					<small>
						{{ item.nome_cliente }}
					</small>
				</template>
				<template v-slot:item.cpf_cliente ="{ item }">
					<small>
						{{ item.cpf_cliente }}
					</small>
				</template>
				<template v-slot:item.telefones ="{ item }">
					<small v-for="(num,i) in item.telefones" :key="i">
						{{ num.numero_telefone }}
					</small>
				</template>
				<template v-slot:item.data_ligacao ="{ item }">
					<small>{{ formatoData(item.data_ligacao) }}</small>
				</template>
				<template v-slot:item.nome ="{ item }">
					<small>{{ item.nome }}</small>
				</template>
				<template v-slot:item.observacoes ="{ item }">
					<small>{{ item.observacoes }}</small>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn @click="loadCliente(item)" color="success" x-small>
						<v-icon small>mdi-help</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-col>
		<DialogAgendamento :data="agendar"/>
		<DialogMotivo :data="motivo"/>
		<DialogEfetivar :data="contrato"/>
		<DialogClientes :data="client"/>
	</v-container>
</template>

<script>
	import moment from 'moment'
	import { mapState } from "vuex";
	import axios from "axios";
	import { baseApiUrl, showError } from "@/global";
	import PageTitle from '@/templates/PageTitle'
	import DialogAgendamento from '@/components/dialogs/DialogAgendamento'
	import DialogMotivo from '@/components/dialogs/DialogMotivo'
	import DialogEfetivar from '@/components/dialogs/DialogEfetivar'
	import DialogClientes from '@/components/dialogs/DialogClientes'
	import Bus from '@/config/bus'

	export default {

		name: 'Telemarketing',
		components:{ PageTitle, DialogAgendamento, DialogMotivo,DialogEfetivar, DialogClientes },
		data() {
			return {
				selected:[],
				busca:'',
				status:'',
				dialog:false,
				agendar:{},
				contrato:{},
				motivo:{},
				title:{},
				stacks:{},
				show:false,
				cliente:{},
				client:{},
				telemarketing:[],
				table:[
				{text:'Nome', value:'nome_cliente'},
				{text:'CPF', value:'cpf_cliente'},
				{text:'Contatos', value:'telefones'},
				{text:'Data Ultima Ligação', value:'data_ligacao'},
				{text:'Tentativas', value:'tentativas'},
				{text:'Observações', value:'observacoes'},
				{text:'Consultor', value:'nome'},
				{text:'###', value:'actions'},
				]
			};
		},
		methods:{
			reset(){
				this.status = {}
				this.loadTelemarketing()
				this.selected = []
			},
			loadTelemarketing() {
				let arr = []
				axios.get(`${baseApiUrl}/telemarketing`)
				.then(res => {
					if (
						this.user.cargo === "Administrador" ||
						this.user.cargo === "Supervisor" ||
						this.user.cargo === "Financeiro"
						) {
						arr = res.data;
				} else {
					res.data.map((tmk, i) => {

						if (tmk.fk_usuario === this.user.id) {
							arr[i] = tmk;
						}
					});
				}
				this.telemarketing = arr
			})
				.catch(showError)
			},
			loadStacks() {
				if(this.user.cargo === 'Consultor'){
					var id = this.user.id
					axios.get(`${baseApiUrl}/stacks/${id}`)
					.then((res) => {
						this.stacks = res.data
					})
					.catch(showError)	
				}else{
					axios.get(`${baseApiUrl}/stacks`)
					.then((res) => {
						this.stacks = res.data
					})
					.catch(showError)	
				}


			},
			editarCliente(item) {
				axios.get(`${baseApiUrl}/clientes/${item.fk_cliente}`)
				.then(res => this.client = {
					id_cliente: res.data.id_cliente,
					nome_cliente: res.data.nome_cliente,
					apelido_cliente: res.data.apelido_cliente,
					cpf_cliente: res.data.cpf_cliente,
					nascimento_cliente: res.data.nascimento_cliente,
					rua_cliente: res.data.rua_cliente,
					num_cliente: res.data.num_cliente,
					localidade_cliente: res.data.localidade_cliente,
					cidade_cliente: res.data.cidade_cliente,
					atendimento: res.data.atendimento,
					telefones: res.data.telefones.length > 0 ? res.data.telefones : [{numero_telefone: '' }], 
					modal:true,
					status:this.title.status
				})
				.catch(showError);
			},
			loadCliente(item) {
				this.dialog = true
				this.title.id_telemarketing = item.id_telemarketing
				this.title.fk_cliente = item.id_cliente
				this.title.nome = item.nome_cliente
				this.title.status = item.status
				this.title.fones = item.telefones.map(t => t.numero_telefone) 
				this.cliente = {
					fk_cliente:item.id_cliente,
					nome_cliente:item.nome_cliente,
					telefones:item.telefones
				}
			},
			efetivar(item) {
				this.editarCliente(item)
				Bus.$on('refresh', status => {
					this.contrato = {
						cliente:item,
						status:status,
						modal:true
					}		
				})
			},
			addAgendamento(item) {
				this.agendar = {
					cliente:item,
					modal:true
				}
			},
			addMotivo(item, status) {
				this.motivo = {
					cliente:item,
					modal:true,
					status:status
				}
			},
			remove() {
				let promises = []
				for(var i in this.selected){
					promises.push(
						axios.delete(`${baseApiUrl}/telemarketing/${this.selected[i].id_telemarketing}`)

						)
				}
				Promise.all(promises).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadTelemarketing()
					this.reset()
				}).catch(showError)
			},
			removeLista(id) {
				axios.delete(`${baseApiUrl}/telemarketing/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialog = false
					this.reset()
				})
				.catch(showError)
			},
			loadStatus(param) {
				let arr = []
				if(param === 'nao_interessado') {
					this.status = {
						title:'Não Interessados',
						cor:'error',
						show:true
					}
				}
				if(param === 'ligar_depois') {
					this.status = {
						title:'Ligar Depois',
						cor:'orange',
						show:true
					}
				}
				if(param === 'sem_resposta') {
					this.status = {
						title:'Sem Resposta',
						cor:'grey darken-1',
						show:true
					}
				}
				axios.get(`${baseApiUrl}/telemarketing/${param}`)
				.then(res => {
					if (
						this.user.cargo === "Administrador" ||
						this.user.cargo === "Supervisor" ||
						this.user.cargo === "Financeiro"
						) {
						arr = res.data;
				} else {
					res.data.map((tmk, i) => {

						if (tmk.fk_usuario === this.user.id) {
							arr[i] = tmk;
						}
					});
				}
				this.telemarketing = arr
			})
				.catch(showError)

				return status
			},
			formatoData(data) {
				if (data) {
					return moment(data).format('DD/MM/YYYY HH:mm')
				}
			},
			link() {
				this.$router.push('/agendamentos')
			}
		},
		computed: {
			...mapState(["user"])
		},
		mounted() {
			this.loadTelemarketing()
			this.loadStacks()
			Bus.$on('refresh', item => {
				this.loadStatus(item)
				this.loadStacks()
				this.dialog = false
			})
			Bus.$on('reload',()=> {
				this.loadTelemarketing()
				this.loadStacks()
				this.dialog = false
			})
			if(this.user.cargo === 'Consultor'){
				var id = this.user.id
				axios.get(`${baseApiUrl}/stacks/${id}`)
				.then(res => {
					this.stacks = res.data
				})
				.catch(showError)	
			}else{
				axios.get(`${baseApiUrl}/stacks`)
				.then(res => {
					this.stacks = res.data
				})
				.catch(showError)	
			}	

		}
	};
</script>

<style lang="css" scoped>
</style>
