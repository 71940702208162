import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: null,
		bday:null
	},
	mutations: {
		setUser(state, user) {
			state.user = user
			if(user) {
				axios.defaults.headers.common['Authorization'] = 	`bearer ${ user.token }`
			} else {
				delete axios.defaults.headers.common['Authorization']
			}
		},
		setBday(state, bday) {
			state.bday = bday
		}

	}
})