<template>
	<GChart type="PieChart" :data="data" :options="chartOptions"></GChart>
</template>

<script>
	export default {

		name: 'GoogelChartLine',
		props: {
			dataChart:{ type: Array },
			options:{ type: Object }
		},
		data() {
			return {
				data:[],
				chartOptions:this.options
			};
		},
		methods: {
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			}
		},
		mounted() {
			const data = this.dataChart.map(d => [d.nome, {v:d.total,f:this.formatoMoeda(d.total)}])
			const header = ['Nome', 'Total']
			var arr = []
			arr = [header].concat(data)
			this.data = arr
		}
	};
</script>

<style lang="css" scoped>
</style>
