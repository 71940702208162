<template>
	<v-container>
		<PageTitle icon="mdi mdi-account-group" main="Usuários" sub="cadastro de usuários"/>
		<v-dialog v-model="dialog">
			<template v-slot:activator="{ on }">
				<v-col md="6" sm="12">
					<v-btn @click="loadUser('', 'save')" x-small color="error" v-on="on" outlined>Novo usuário</v-btn>
				</v-col>
			</template>
			<v-card>
				<v-card-title primary-title>
					{{ titleForm }}
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form>
							<v-row>
								<v-col>		
									<v-text-field v-model="user.nome" label="Nome" required :disabled="mode === 'remove'"></v-text-field>
								</v-col>
								<v-col md="4">	
									<v-text-field v-model="user.usuario" label="Usuario" required :disabled="mode === 'remove'"></v-text-field>
								</v-col>
								<v-col>	
									<v-text-field type="text" v-model="user.email" label="E-mail" required :disabled="mode === 'remove'" :rules="validEmail"></v-text-field>
								</v-col>
							</v-row>
							<v-row v-show="mode === 'save'">
								<v-col>	
									<v-text-field v-model="user.endereco" label="Endereço" required></v-text-field>
								</v-col>
								<v-col md="3">	
									<v-text-field type="date" v-model="user.nascimento" label="Nascimento" required></v-text-field>
								</v-col>
								<v-col md="3">	
									<v-text-field v-model="user.celular" label="Celular" v-mask="['(##)#####-####']" required></v-text-field>
								</v-col>
							</v-row>
							<v-row v-show="mode === 'save'">
								<v-col>	
									<v-text-field v-model="user.cidade" label="Cidade" required></v-text-field>
								</v-col>
								<v-col>	
									<v-autocomplete
									:items="opcoes"
									v-model="user.cargo"
									label="Função"
									></v-autocomplete>
								</v-col>
								<v-col>	
									<v-currency-field label="Meta Mensal/R$" v-model="user.metamensal" :readonly="mode === 'remove'"></v-currency-field>
								</v-col>
								<v-col>	
									<v-currency-field v-model="user.comissao" label="Comissão/%" required></v-currency-field>
								</v-col>
							</v-row>
							<v-row v-show="mode === 'save'">
								<v-col>	
									<v-text-field type="password" v-model="user.senha" label="Senha" required></v-text-field>
								</v-col>
								<v-col>	
									<v-text-field type="password" v-model="user.confirmSenha" label="Confirmar Senha" required></v-text-field>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-row>
									<v-col>
										<v-btn color="primary" class="mr-1" v-if="mode === 'save'" @click="save">salvar</v-btn>
										<v-btn color="error" class="mr-1" v-else @click="remove">exluir</v-btn>
										<v-btn color="grey" class="ml-1" dark @click="reset">cancelar</v-btn>			
									</v-col>
								</v-row>
							</v-card-actions>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-card>
			<v-col>
				<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>
			<v-data-table :headers="table" :items="users" :search="busca" class="elevation-1">
				<template v-slot:item.actions="{ item }">
					<v-btn icon small color="orange" @click="loadUser(item, 'save')"><v-icon small>mdi-pencil</v-icon></v-btn>
					<v-btn icon small color="error" @click="loadUser(item, 'remove')"><v-icon small>mdi-delete</v-icon></v-btn>
				</template>
				<template v-slot:item.metamensal="{ item }">
					{{ formatoMoeda(item.metamensal) }}
				</template>
			</v-data-table>
		</v-card>	
	</v-container>
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import PageTitle from '@/templates/PageTitle'
	import moment from 'moment'
	export default {

		name: 'Usuarios',
		components: { PageTitle },
		data() {
			return {
				busca:'',
				users:[],
				user:{},
				mode:'save',
				dialog:false,
				titleForm:'',
				table:[
				{ text:"Nome", value:"nome"},
				{ text:"Email", value:"email"},
				{ text:"Celular", value:"celular"},
				{ text:"Função", value:"cargo"},
				{ text:"Meta Mensal / R$", value:"metamensal"},
				{ text:"Comissão / %", value:"comissao"},
				{ text:"Ações", value:"actions"},
				],
				opcoes:[
				{ text:"Consultor", value:"Consultor"},
				{ text:"Supervisor", value:"Supervisor"},
				{ text:"Financeiro", value:"Financeiro"},
				{ text:"Administrador", value:"Administrador"}
				],
				validEmail:[this.isEmailValid],
				reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
			};
		},
		methods: {
			loadUsers() {
				axios.get(`${baseApiUrl}/usuarios`).then(res => {
					this.users = res.data
				})
				.catch(showError)
			},
			loadUser(item, mode = 'save') {
				this.dialog = true,
				this.mode = mode
				this.user = { ...item }
				this.user.nascimento = item === '' ? this.user.nascimento ='' : moment(item.nascimento).format("YYYY-MM-DD")
				this.titleForm = item.id_usuario === undefined ? `Novo Usuário` :
				this.mode === 'remove' ? `Excluir Usuário ${ item.nome } ?` : `Editar Usuário - ${ item.nome}`
			},
			save() {
				const metodo = this.user.id_usuario ? 'put' : 'post'
				const id = this.user.id_usuario ? `/${this.user.id_usuario}` : ''
				axios[metodo](`${baseApiUrl}/usuarios${id}`, this.user).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadUsers()
					this.dialog = false
				})
				.catch(showError)
			},
			remove() {
				const id = this.user.id_usuario
				axios.delete(`${baseApiUrl}/usuarios/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialog = false
					this.loadUsers()
					this.reset()
				})
				.catch(showError)
			},
			reset() {
				this.dialog = false,
				this.user = {}
			},
			isEmailValid() {
				return (this.reg.test(this.user.email)) ? true : 'Email inválido!!'
			},
			formatoData(data){
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
			},
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			}
		},
		mounted() {
			this.loadUsers()
		}
	};
</script>

<style lang="css" scoped>
</style>
