<template>
	<v-container fluid>	
		<v-col>
			<v-text-field
			v-model="busca"
			append-icon="mdi-magnify"
			label="Buscar"
			single-line
			hide-details
			dense
			></v-text-field>
		</v-col>
		<v-col>
			<v-data-table :headers="table" :items="items" :search="busca">
				<template v-slot:item.data="{ item }">
					{{ formatoData(item.data) }}										
				</template>
				<template v-slot:item.valor="{ item }">
					{{ formatoMoeda(item.valor) }}										
				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn x-small icon @click="loadFinanceiro(item, 'save')">
						<v-icon color="orange">mdi-pencil</v-icon>
					</v-btn>
					<v-btn x-small icon @click="loadFinanceiro(item, 'remove')">
						<v-icon color="error">mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-col>
	</v-container>
</template>

<script>
	import moment from 'moment'
	import Bus from '@/config/bus'
	export default {

		name: 'DataTable',
		props: {
			headers: Array,
			items: Array,
		},
		data () {
			return {
				busca:'',
				table:[
					{text:'ID', value:'transacao_id'},
					{text:'Data', value:'data'},
					{text:'Descrição', value:'descricao'},
					{text:'Valor', value:'valor'},
					{text:'Consumidora', value:'consumidora'},
					{text:'Categoria',value:'categoria'},
					{text:'Financeira',value:'financeira'},
					{text:'Ações',value:'actions'},
					],
			}
		},
		methods:{
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			},
			formatoData(data){
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")
			},
			loadFinanceiro(item, modo = 'save') {
				Bus.$emit('loadFinanceiro', {item, modo})
			}
		}
	}
</script>

<style lang="css" scoped>
</style>