<template>
	<GChart type="LineChart" :data="data" :options="chartOptions"></GChart>
</template>

<script>
	export default {

		name: 'GoogelChartLine',
		props: {
			dataChart:{ type: Array },
			options:{ type: Object }
		},
		data() {
			return {
				data:[],
				chartOptions:this.options
			};
		},
		methods: {
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			},
			updateChartData() {
				// Primeiro, crie uma lista dos anos
				const anos = this.dataChart.map(item => item.ano.toString());

// Inicialize um objeto para armazenar os totais para cada mês
				let mesTotais = {};

// Processar cada entrada em dataChart
				this.dataChart.forEach(data => {
					data.meses.forEach(mes => {
						if (!mesTotais[mes.mes]) {
							mesTotais[mes.mes] = {};
						}
						mesTotais[mes.mes][data.ano] = mes.total;
					});
				});

// Construir a matriz final para o gráfico
				let chartData = [['Mês', ...anos]];

				for (let mes in mesTotais) {
					let row = [mes];
					anos.forEach(ano => {
						row.push(mesTotais[mes][ano] || 0);
					});
					chartData.push(row);
				}

				this.data = chartData;

			}
		},
		mounted() {
			this.updateChartData()
		},
		watch: {
			dataChart() {
				this.updateChartData()
			}
		}
	};
</script>

<style lang="css" scoped>
</style>