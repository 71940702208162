<template>
	<v-container fluid>
		<PageTitle icon="mdi mdi-road-variant" :main="enabled === false ? 'Esteira' : 'Esteira Nova Margem'" :sub="enabled === false ? 'contratos ativos' : 'AUMENTO SALARIAL'" :class="enabled === false ? '' : 'primary--text font-weight-bold'"/>
		<v-dialog v-model="dialogContrato" fullscreen>
			<v-card>
				<v-card-title>{{ contratoTitle }}</v-card-title>
				<v-card-text>
					<v-form @submit.stop.prevent>
						<v-row>
							<v-col md="4" sm="12">
								<v-text-field
								label="Matrícula"
								v-model="contrato.matricula_contrato"
								type="number"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"

								></v-text-field>
							</v-col>
							<v-col md="4" sm="12">
								<v-text-field
								label="Número Contrato"
								v-model="contrato.numero_contrato"
								type="number"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"

								></v-text-field>
							</v-col>
							<v-col md="2" sm="12">
								<v-text-field
								label="Data Contrato"
								v-model="contrato.data_contrato"
								type="date"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"

								></v-text-field>
							</v-col>
							<v-col md="2" sm="12">
								<v-currency-field
								label="Valor Contrato"
								v-model="contrato.valor_contrato"
								type="text"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="valorReq"
								></v-currency-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="2" sm="12">
								<v-autocomplete
								:items="bancos"
								item-text="nome_banco"
								item-value="nome_banco"
								v-model="contrato.banco_contrato"
								label="Banco"
								required
								:disabled="mode === 'remove'"
								:rules="requerido"
								@input="loadTabelas()"
								></v-autocomplete>
							</v-col>
							<v-col md="4" sm="12">
								<v-autocomplete
								:items="tabelas"
								item-text="nome_tabela"
								item-value="nome_tabela"
								v-model="contrato.tabela_contrato"
								label="Tabela"
								required
								:disabled="mode === 'remove'"
								:rules="requerido"
								@change="atualizarTaxa"
								></v-autocomplete>
							</v-col>
							<v-col>
								<v-text-field label="Taxa" v-model="contrato.taxa" :disabled="disabled"></v-text-field>
							</v-col>
							<v-col md="3" sm="12">
								<v-select
								:items="produtos"
								v-model="contrato.produto_contrato"
								item-text="nome_produto"
								item-value="nome_produto"
								label="Produto"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
							<v-col md="2" sm="12">
								<v-select
								:items="convenios"
								v-model="contrato.convenio_contrato"
								item-text="nome_convenio"
								label="Convênio"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="2" sm="12">
								<v-text-field
								type="number"
								v-model="contrato.prazo_contrato"
								label="Prazo"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-text-field>
							</v-col>
							<v-col md="3" sm="12">
								<v-select
								:items="pagamentos"
								v-model="contrato.tipo_pagamento"
								label="Tipo Pagamento"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
							<v-col md="3">
								<v-select
								:items="formalizacao"
								v-model="contrato.formalizacao"
								label="Tipo Formalizacão"
								:disabled="mode === 'remove'"
								require
								:rules="requerido"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea
								label="Informações"
								outlined
								v-model="contrato.informacoes"
								:disabled="mode === 'remove'"

								></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-col md="12" sm="12" class="text-center">
						<v-btn
						color="primary"
						@click="saveContrato"
						v-show="mode === 'save'"
						>salvar</v-btn
						>
						<v-btn
						color="error"
						@click="removeContrato"
						v-show="mode === 'remove'"
						>excluir</v-btn
						>
						<v-btn color="grey" class="ml-2" dark @click="reset"
						>cancelar</v-btn
						>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogStatus" width="400">
			<v-card>
				<v-card-title>
					Alterar Status
				</v-card-title>
				<v-form ref="formStatus" @submit.stop.prevent>
					<v-card-text>
						<v-col>
							<v-select label="Status" :items="status" v-model="selStatus"></v-select>
						</v-col>
						<v-col v-if="selStatus === 'RECUSADO' || selStatus === 'CANCELADO'">
							<v-textarea label="Motivo" outlined v-model="contrato.informacoes" :disabled="mode === 'remove'" :rules="[rules.required]" required></v-textarea>					
						</v-col>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="saveStatus">salvar</v-btn>
						<v-btn color="grey" @click="reset">cancelar</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col v-show="enabled === false">
				<v-btn x-small color="success" @click="loadContratosNM">nova margem</v-btn>
			</v-col>
			<v-col v-show="enabled === true">
				<v-btn x-small color="primary" @click="loadContratos">voltar</v-btn>
			</v-col>
		</v-row>
		<v-row align="center" justify="space-around">	
			<v-col>	
				<v-text-field @input="debouncedSearch" v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>
			<v-col md="2" sm="12" v-show="selected.length > 0">
				<v-btn @click="dialogStatus=true" small color="primary">alterar status</v-btn>
			</v-col>
		</v-row>
		<v-data-table id="tabela" :headers="table" :items="filteredItems" :search="busca" :loading="loading" loading-text="Carregando... Espere por favor!" v-model="selected" show-select item-key="id_contrato" class="elevation-1 mt-2" dense>
			<template v-slot:item.data_contrato="{ item }">
				<td class="font-weight-bold">
					<v-flex style="font-size:11px;">
						id: {{ item.id_contrato }}
					</v-flex>
					<v-flex
					class="font-weight-bold"
					:class="hoje(Date()) == formatoData(item.data_contrato) ? 'success--text': ''"
					>
					{{ formatoData(item.data_contrato) }}
				</v-flex>
			</td>
		</template>
		<template v-slot:item.numero_contrato="{ item }">
			<td class="font-weight-bold" style="font-size:10.5px;">
				{{ item.numero_contrato }}
				<small class="grey--text" style="font-size:10px;">
					<v-flex>
						m: {{ item.matricula_contrato }}
					</v-flex>
					<v-flex></v-flex>
				</small>
			</td>
		</template>
		<template v-slot:item.banco_contrato="{ item }">
			<td class="font-weight-bold" style="font-size:10px;">
				{{ item.banco_contrato }}
				<small class="grey--text">
					<v-flex>{{ item.produto_contrato }}</v-flex>
					<v-flex>{{ item.tabela_contrato }}</v-flex>
					<v-flex>{{ item.formalizacao }}</v-flex>
				</small>
			</td>
		</template>
		<template v-slot:item.taxa="{ item }">
			<td>
				<v-flex class="dark--text font-weight-bold">
					<small>{{ item.taxa }}%</small>
				</v-flex>
				<v-flex class="primary--text font-weight-bold">
					<small>{{ formatoMoeda(item.valor_contrato) }}</small>
				</v-flex>
			</td>
		</template>
		<template v-slot:item.nome="{ item }">
			<td>
				<v-badge color="yellow" v-if="isNew(item.createdAt)">
					<template v-slot:badge>
						<span style="color: black;">novo</span>
					</template>
				</v-badge>
				<v-flex class="info--text font-weight-bold" style="font-size:11.5px;">
					<small>{{ item.nome }}</small>
				</v-flex>
			</td>
		</template>
		<template v-slot:item.cpf_cliente="{ item }">
			<td class="font-weight-bold" style="font-size:11px;">
				<small class="grey--text">{{ item.atendimento }}</small><br>
				{{ item.cpf_cliente }}
				<small class="grey--text">
					<v-flex>{{ item.nome_cliente }}</v-flex>
					<span v-show="item.apelido_cliente">- {{ item.apelido_cliente }}</span>
				</small>
				<v-flex v-for="(tel,i) in item.telefones" :key="i">
					<b class="primary--text">{{ tel.numero_telefone }}</b>
				</v-flex>
			</td>
		</template>
		<template v-slot:item.status_contrato="{ item }">
			<v-flex style="font-size:10px;" class="indigo--text font-weight-bold" dark>
				<small>{{ item.status }}</small>
			</v-flex>
			<v-flex>
				<small class="info--text" style="font-size:10px;">
					{{ item.status_telemarketing === 'efetivado' ? `telemarketing` : ''}}
				</small>
			</v-flex>
		</template>
		<template v-slot:item.actions="{ item }">
			<v-btn dark x-small style="font-size:10px;" color="orange" @click="loadContrato(item)" block>
				editar
			</v-btn>
		</template>
	</v-data-table>

</v-container>
</template>

<script>
	import { mapState } from 'vuex'
	import PageTitle from '@/templates/PageTitle'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import moment from 'moment'
	import { debounce } from 'lodash'
	export default {

		name: 'Esteira',
		components: {PageTitle},
		data() {
			return {
				filteredItems: [],
				debouncedSearch: null,
				busca:'',
				enabled:false,
				loading:false,
				selected:[],
				selStatus:null,
				mode:'',
				dialogContrato:false,
				dialogStatus:false,
				contratoTitle:'',
				contratos:[],
				contrato:{},
				tabelas:[],
				bancos:[],
				produtos:[],
				convenios:[],
				prazos:[],
				ruleData:[
					v => v && this.validData
					],
				rules: {
					required: v => !!v || 'Campo obrigatório.',
					minLength: v => (v && v.length >= 3) || 'Mínimo 3 caracteres.'
				},
				table:[
					{text:'Data Contrato', value:'data_contrato' },
					{text:'Contrato', value:'numero_contrato'},
					{text:'Banco', value:'banco_contrato'},
					{text:'Taxa', value:'taxa'},
					{text:'CPF', value:'cpf_cliente'},
					{text:'Consultor', value:'nome'},
					{text:'Status', value:'status_contrato'},
					{text:'Ações', value:'actions'}
					],
				options:[
					{text:'Presencial', value:'presencial'},
					{text:'Telefone', value:'telefone'}
					],
				pagamentos:[
					{text:'OP', value:'OP'},
					{text:'CONTA', value:'CONTA'}
					],
				formalizacao:[
					{text:'Digital', value:'Digital'},
					{text:'Física', value:'Fisica'}
					],
				status:[
					{text:'Pag. Enviado', value:'PAG ENVIADO'},
					{text:'Ag. Averbação', value:'AG AVERBACAO'},
					{text:'Averbação', value:'AVERBACAO'},
					{text:'Finalizado', value:'FINALIZADO'},
					{text:'Recusado', value:'RECUSADO'},
					{text:'Cancelado', value:'CANCELADO'}
					],
				requerido: [(v) => !!v || "*"],
				valorReq: [(v) => !!v || v == 0 || "*"],
				disabled:true
			};
		},
		methods:{
			loadContratos(){
				this.enabled = false
				this.contratos = []
				this.loading = true
				axios.get(`${baseApiUrl}/esteira`).then(res => {
					res.data.esteira.map(c => {
						if(c.id_usuario === this.user.id || this.user.cargo === 'Administrador' || this.user.cargo === 'Supervisor' || this.user.cargo === 'Financeiro'){
							this.contratos.push(c)
						}
					})
					this.loading = false
				})
				.catch(showError)
			},
			loadContratosNM() {
				// item, mode ='save'
				this.enabled = true

				this.contratos = []
				this.loading = true
				axios.get(`${baseApiUrl}/esteira`).then(res => {
					res.data.esteiraNovaMargem.map(c => {
						if(c.id_usuario === this.user.id || this.user.cargo === 'Administrador' || this.user.cargo === 'Supervisor' || this.user.cargo === 'Financeiro'){
							this.contratos.push(c)
						}
					})
					this.loading = false
				})
				.catch(showError)
				

			},
			loadTabelas() {
				this.tabelas = []
				axios
				.get(`${baseApiUrl}/tabelas`)
				.then((res) => {
					res.data.map(t => {
						if(this.contrato.banco_contrato && t.nome_banco === this.contrato.banco_contrato){
							this.tabelas.push(t)
						}
					})
				})
				.catch(showError);
			},
			loadBancos() {
				axios.get(`${baseApiUrl}/bancos`).then(res => {	
					this.bancos = res.data
				})
				.catch(showError)
			},
			loadProdutos() {
				axios.get(`${baseApiUrl}/produtos`).then(res => {	
					this.produtos = res.data
				})
				.catch(showError)
			},
			loadConvenios() {
				axios.get(`${baseApiUrl}/convenios`).then(res => {	
					this.convenios = res.data
				})
				.catch(showError)
			},
			loadPrazos() {
				axios.get(`${baseApiUrl}/prazos`).then(res => {	
					this.prazos = res.data
				})
				.catch(showError)
			},
			loadContrato(item, mode ='save') {
				this.loadTabelas()
				this.loadBancos()
				this.loadProdutos()
				this.loadConvenios()
				this.loadPrazos()
				this.mode = mode
				this.dialogContrato =true
				this.contrato = {
					id_contrato:item.id_contrato,
					matricula_contrato:item.matricula_contrato,
					numero_contrato:item.numero_contrato,
					data_contrato:moment(item.data_contrato).format('YYYY-MM-DD'),
					valor_contrato:item.valor_contrato,
					produto_contrato:item.produto_contrato,
					codigo_tabela_contrato:Number(item.codigo_tabela_contrato),
					taxa:item.taxa,
					tabela_contrato:item.tabela_contrato,
					banco_contrato:item.banco_contrato,
					convenio_contrato:item.convenio_contrato,
					prazo_contrato:parseInt(item.prazo_contrato),
					tipo_pagamento:item.tipo_pagamento,
					formalizacao:item.formalizacao,
					fk_cliente:item.fk_cliente,
					informacoes:item.informacoes,
					createdAt:item.createdAt
				}
				this.contratoTitle =  item !== '' && this.mode === 'remove' ? `Excluir Contrato ${ item.numero_contrato} ?` : `Editar Contrato ${ item.numero_contrato }`

			},
			
			saveContrato() {
				console.log(this.contrato.taxa)
				this.trimAllInputValues()
				const metodo = this.contrato.id_contrato ? "put" : "post";
				this.contrato.fk_usuario = metodo === "post" ? this.user.id : this.contrato.fk_usuario
				if(metodo === 'put') {
					this.contrato.bancoTabela = {
						codigo_tabela:this.contrato.codigo_tabela_contrato,
						percentual:this.contrato.taxa,
						nome_tabela:this.contrato.tabela_contrato,
						nome_banco:this.contrato.banco_contrato,
						comissao_tabela:this.contrato.comissao_contrato
					}
				}
				const id = this.contrato.id_contrato
				? `/${this.contrato.id_contrato}`
				: "";
				this.contrato.ativo =
				this.contrato.formalizacao === "digital" ? false : true;
				axios[metodo](`${baseApiUrl}/contratos${id}`, this.contrato)
				.then(() => {
					this.$toasted.global.defaultSuccess();
					this.dialogContrato = false;
					this.loadContratos()
					this.reset();

				})
				.catch(showError);
			},
			saveStatus() {
				if (this.$refs.formStatus.validate()) {

					let novoStatus = [], ids = [];
					const currentDate = moment(new Date()).format('YYYY-MM-DD');

					const createStatusObject = (s, status, ativo, data_finalizado = null, motivo = null) => {
						let obj = {
							id_contrato: s.id_contrato,
							status: status,
							ativo: ativo
						};
						if (data_finalizado) obj.data_finalizado = data_finalizado;
						if (motivo) obj.motivo = motivo;

						return obj;
					};

					this.selected.forEach(s => {
						let statusObj;

						if (this.selStatus !== null) {
							if (this.selStatus === 'FINALIZADO') {
								statusObj = (s.formalizacao === 'Fisica') 
								? createStatusObject(s, this.selStatus, true, currentDate) 
								: createStatusObject(s, this.selStatus, false, currentDate);
							} else if (this.selStatus === 'RECUSADO' || this.selStatus === 'CANCELADO') {
								statusObj = createStatusObject(s, this.selStatus, false, null, this.selStatus.informacoes);
							} else {
								statusObj = createStatusObject(s, this.selStatus, true);
							}
						} else {
							statusObj = createStatusObject(s, null, true);
						}

						novoStatus.push(statusObj);
						ids.push(s.id_contrato);
					});

					this.contrato.novoStatus = novoStatus;

					axios.put(`${baseApiUrl}/esteira/${ids.join(",")}`, this.contrato)
					.then(() => {
						this.$toasted.global.defaultSuccess();
						this.reset();
						this.loadContratos();
					})
					.catch(showError);

				} else {
					alert('Por favor, corrija os erros antes de enviar.');
				}
			},
			removeContrato() {
				const id = this.contrato.id_contrato;
				axios
				.delete(`${baseApiUrl}/contratos/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess();
					this.dialogContrato = false;
					this.loadContratos(this.contrato.fk_cliente);
					this.reset();
				})
				.catch(showError);
			},
			reset() {
				this.contrato = {}
				this.dialogContrato = false
				this.dialogStatus = false
				this.selected = []
				this.selStatus = null
			},
			formatoData(data){
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
			},
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			},
			hoje(data) {
				return moment(data,"YYYY-MM-DD").format('DD/MM/YYYY')
			},
			atualizarTaxa() {
				this.contrato.taxa = ''
				const tab = []
				this.tabelas.map(t =>{
					const regex = /REFIN|PORT|RFN|CARTAO|SAQUE|ATIVACAO|COMPLEMENTAR/i
					if(this.contrato.tabela_contrato === t.nome_tabela){
						if (regex.test(t.nome_tabela) || !t.percentual) {
							
							if(this.contrato.banco_contrato === t.nome_banco) {
								tab.push(t)
							}	

							this.disabled = false
						} else {
							if(this.contrato.banco_contrato === t.nome_banco) {
								tab.push(t)
							}
							this.contrato.taxa = t.percentual
							this.disabled = true
						}
					}
				})
				this.contrato.bancoTabela = tab
			},
			trimAllInputValues() {
				for (const key in this.contrato) {
					if (typeof this.contrato[key] === 'string') {
						this.contrato[key] = this.contrato[key].trim();
					}
				}
			},
			search() {
				if (!this.busca) {
					this.filteredItems = this.contratos;
				} else {
					const searchTerm = this.busca.toLowerCase();
					this.filteredItems = this.contratos.filter(item => this.recursiveSearch(item, searchTerm));
				}
			},
			recursiveSearch(value, searchTerm) {
				if (typeof value === 'object' && value !== null) {
					return Object.values(value).some(subvalue => this.recursiveSearch(subvalue, searchTerm));
				}
				if (Array.isArray(value)) {
					return value.some(element => this.recursiveSearch(element, searchTerm));
				}
				return String(value).toLowerCase().includes(searchTerm);
			},
			isNew(item) {
				const mesAtual = moment().format('MM-YYYY')
				const mesCreatedAt = moment(item).format('MM-YYYY')
				if(mesAtual === mesCreatedAt){
					return true
				}
				return false
			}
		},
		watch: {
			contratos(newVal) {
				this.filteredItems = newVal;
			}
		},
		computed: {
			...mapState(['user']),
			validData() {
				const erros = []
				let hoje = moment().format('YYYY-MM-DD')
				this.contrato.data_contrato < hoje ? erros : erros.push('Não é possivel contrato com data futura!')

				return erros
			},
		},
		created() {
			this.debouncedSearch = debounce(this.search, 300)
			this.filteredItems = this.contratos;
		},
		mounted(){
			this.loadContratos()
		}
	};
</script>

<style lang="css" scoped>
</style>
