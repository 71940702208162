<template>
	<v-container fluid dense>
		<PageTitle icon="mdi mdi-file-document" main="Relatório"></PageTitle>
		<v-card>
			<v-card-title>
				<v-row wrap>
					<v-col>
						<v-menu
						ref="menu"
						v-model="menu"
						:close-on-content-click="false"
						:return-value.sync="dates"
						transition="scale-transition"
						offset-y
						min-width="auto"
						>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
							v-model="dateRangeText"
							label="Data Inicio ~ Data Fim"
							prepend-icon="mdi-calendar"
							readonly
							v-bind="attrs"
							v-on="on"
							dense
							@click="reset"
							></v-text-field>
						</template>
						<v-date-picker
						v-model="dates"
						no-title
						scrollable
						range
						locale="pt-br"
						>
						<v-spacer></v-spacer>
						<v-btn
						text
						color="primary"
						@click="$refs.menu.save((dates = []), reset())"
						>
						Cancel
					</v-btn>
					<v-btn text color="primary" @click="$refs.menu.save(dates)">
						OK
					</v-btn>
				</v-date-picker>
			</v-menu>
		</v-col>
		<v-col>
			<v-btn
			small
			outlined
			color="primary"
			@click="loadContratos(dates)"
			dense
			v-show="!contratos.contratosDB || dates.length == 0"
			:disabled="dates.length == 0"
			>OK</v-btn
			>
			<v-btn
			small
			outlined
			color="green"
			@click="csvExportSaeso(contratosDB)"
			v-show="contratos.contratosDB && dates.length > 0"
			>
			<v-icon>mdi-microsoft-excel</v-icon>
		</v-btn>
	</v-col>
	<v-col>
		<v-text-field
		v-model="busca"
		append-icon="mdi-magnify"
		label="Buscar"
		single-line
		hide-details
		dense
		></v-text-field>
	</v-col>
</v-row>
</v-card-title>
<v-container class="grey lighten-5">
	<v-row class="flex-child mb-1">
		<v-col>
			<v-sheet
			class="text-center"
			color="green"
			height="auto"
			dark
			rounded
			>
			<sheet-footer>
				Total comissões - Empresa
			</sheet-footer>
			<v-row>
				<v-col md="4">Contratos</v-col>
				<v-col md="4">
					<span class="font-weight-bold text-center">
						{{formatoMoeda(totalComissaoBanco) || 0}}
					</span>
				</v-col>
			</v-row>
			<!-- <v-row>
				<v-col md="4">Abertura de Conta</v-col>
				<v-col md="4"
				><span class="font-weight-bold text-center">{{
					formatoMoeda(totalAbertBanco) || 0
				}}</span></v-col
				>
			</v-row> -->
		</v-sheet>
	</v-col>
	<v-col>
		<v-sheet
		class="text-center"
		color="info"
		height="auto"
		dark
		rounded
		>
		<sheet-footer>
			Total comissões - Consultores
		</sheet-footer>
		<v-row>
			<v-col md="4">Contratos</v-col>
			<v-col md="4"
			><span class="font-weight-bold text-center">{{
				formatoMoeda(totalComissaoConsultor) || 0
			}}</span></v-col
			>
		</v-row>
		<!-- <v-row>
			<v-col md="4">Abertura de Conta</v-col>
			<v-col md="4"
			><span class="font-weight-bold text-center">{{
				formatoMoeda(totalAbertConsultor) || 0
			}}</span></v-col
			>
		</v-row> -->
	</v-sheet>
</v-col>
</v-row>
<v-row>
	<v-col>
		<v-sheet class="text-center" color="grey" height="auto" dark rounded>
			<sheet-footer>
				Total Contratos
			</sheet-footer>
			<v-col>{{ formatoMoeda(totalContratos) }}</v-col>
		</v-sheet>
	</v-col>
</v-row>
</v-container>
<v-container v-show="user.cargo === 'Administrador'">
	<v-btn
	small
	outlined
	color="green"
	@click="csvExportContatos(contratos.contatosDB)"
	v-show="contratos.contratosDB && dates.length > 0"
	>
	contatos clientes<v-icon>mdi-microsoft-excel</v-icon>
</v-btn>
</v-container>
<v-container v-show="contratos.contratosDB">
	<v-col class="text-center">
		<h3>Total por Consultor</h3>
	</v-col>
	<v-data-table
	id="select_txt"
	dense
	:search="busca"
	:headers="tableConsultor"
	:items="contratos.porConsultorDB"
	class="elevation-1"
	hide-default-footer
	disable-pagination
	>
	<template v-slot:item.total="{ item }">
		{{ formatoMoeda(item.total) }}
	</template>
</v-data-table>
</v-container>
<v-container v-show="contratos.contratosDB">
	<v-col class="text-center">
		<h3>Total Produto por Consultor</h3>
	</v-col>
	<v-data-table
	dense
	:search="busca"
	:headers="tableConsultoreProduto"
	:items="contratos.porConsultorDBeProduto"
	class="elevation-1"
	hide-default-footer
	disable-pagination
	>
	<template v-slot:item.total="{ item }">
		{{ formatoMoeda(item.total) }}
	</template>
</v-data-table>
</v-container>
<v-container v-show="contratos.agendamentos">
	<v-col class="text-center">
		<h3>Total Agendamentos por Consultor</h3>
	</v-col>
	<v-data-table
	dense
	:search="busca"
	:headers="tableAgendamentos"
	:items="contratos.agendamentos"
	class="elevation-1"
	hide-default-footer
	disable-pagination
	>
	<template v-slot:item.total="{ item }">
		{{ formatoMoeda(item.total) }}
	</template>
</v-data-table>
</v-container>
<v-container v-show="contratos.contratosDB">
	<v-col class="text-center">
		<h3>Total por Cidade</h3>
	</v-col>
	<v-data-table
	dense
	:search="busca"
	:headers="tableCidade"
	:items="contratos.porCidadeDB"
	class="elevation-1"
	hide-default-footer
	disable-pagination
	>
	<template v-slot:item.total="{ item }">
		{{ formatoMoeda(item.total) }}
	</template>
</v-data-table>
</v-container>
<v-container v-show="contratos.contratosDB">
	<v-col class="text-center">
		<h3>Total por Banco</h3>
	</v-col>
	<v-data-table
	dense
	:search="busca"
	:headers="tableBanco"
	:items="contratos.porBancoDB"
	class="elevation-1"
	hide-default-footer
	disable-pagination
	>
	<template v-slot:item.total="{ item }">
		{{ formatoMoeda(item.total) }}
	</template>
</v-data-table>
</v-container>
<v-container v-show="contratos.contratosDB">
	<v-col class="text-center">
		<h3>Total por Produto</h3>
	</v-col>
	<v-data-table
	dense
	:search="busca"
	:headers="tableProduto"
	:items="contratos.porProdutoDB"
	class="elevation-1"
	hide-default-footer
	disable-pagination
	>
	<template v-slot:item.total="{ item }">
		{{ formatoMoeda(item.total) }}
	</template>
</v-data-table>
</v-container>
<v-container v-show="contratos.contratosDB">
	<v-col class="text-center">
		<h3>Total por Telemarketing</h3>
	</v-col>
	<v-data-table
	dense
	:search="busca"
	:headers="tableTelemarketing"
	:items="contratos.porTelemarketing"
	class="elevation-1"
	hide-default-footer
	disable-pagination
	>
	<template v-slot:item.total="{ item }">
		{{ formatoMoeda(item.total) }}
	</template>
</v-data-table>
</v-container>
</v-card>
</v-container>
</template>

<script>
	import moment from 'moment'
	import PageTitle from '@/templates/PageTitle'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import { mapState } from 'vuex'
	export default {

		name: 'Relatorios',
		components: {
			PageTitle,
			SheetFooter: {
				functional: true,
				render (h, { children }) {
					return h('v-sheet', {
						staticClass: 'mt-auto align-center justify-center d-flex px-2',
						props: {
							color: 'rgba(0, 1, 100, .6)',
							dark: true,
							height: '50%',
						},
					}, children)
				}
			}
		},
		data() {
			return {
				busca:'',
				fields:{},
				menu:false,
				dates:[],
				totalContratos:0,
				totalComissaoBanco:0,
				// totalAbertBanco:0,
				totalComissaoConsultor:0,
				// totalAbertConsultor:0,
				contratos:[],
				contratosDB:[],
				table:[
					{text:'Data Contrato', value:'data_contrato'},
					{text:'Nº Contrato', value:'numero_contrato'},
					{text:'Banco', value:'banco_contrato'},
					{text:'Produto', value:'produto_contrato'},
					{text:'Convênio', value:'convenio_contrato'},
					],
				tableConsultor:[
					{text:'Nome', value:'nome'},
					{text:'Quantidade Contratos', value:'qtd_contrato'},
					{text:'Total', value:'total'},
					],
				tableConsultoreProduto:[
					{text:'Produto', value:'produto_contrato'},
					{text:'Nome', value:'nome'},
					{text:'Quantidade', value:'qtd_contrato'},
					{text:'Total', value:'total'},
					],
				tableBanco:[
					{text:'Banco', value:'banco_contrato'},
					{text:'Quantidade', value:'qtd_contrato'},
					{text:'Total', value:'total'},
					],
				tableProduto:[
					{text:'Produto', value:'produto_contrato'},
					{text:'Quantidade', value:'qtd_produto'},
					{text:'Total', value:'total'},
					],
				tableTelemarketing:[
					{text:'Nome', value:'nome'},
					{text:'Quantidade', value:'qtd'},
					{text:'Total', value:'total'},
					],
				tableAgendamentos:[
					{text:'Nome', value:'nome'},
					{text:'Quantidade', value:'qtd'},
					],
				tableCidade:[
					{text:'Cidade', value:'cidade_cliente'},
					{text:'Quantidade', value:'qtd_contrato'},
					{text:'Total', value:'total'},
					]
			};
		},
		methods: {
			
			async loadContratos(data) {
				const datas = {
					dataInicial: data[0],
					dataFinal: data[1]
				};

				try {
					const res = await axios.post(`${baseApiUrl}/relatorio`, datas);
					this.contratos = res.data
					const {
						totalContratos,
						// comACDBanco: totalAbertBanco,
						// comACDConsultor: totalAbertConsultor,
						comissoes,
						contratosDB
					} = res.data;
					this.totalContratos = totalContratos;
					// this.totalAbertBanco = totalAbertBanco;
					// this.totalAbertConsultor = totalAbertConsultor;
					this.totalComissaoBanco = comissoes.comBanco;
					this.totalComissaoConsultor = comissoes.comConsultor;
					this.contratosDB = this.transformContratosDB(contratosDB);

				} catch (error) {
					showError(error);
				}
			},

			transformContratosDB(contratos) {
				return contratos.map(c => ({
					nome_cliente: c.nome_cliente,
					apelido_cliente: c.apelido_cliente,
					cpf_cliente: c.cpf_cliente,
					rua_cliente: c.rua_cliente,
					localidade_cliente: c.localidade_cliente,
					cidade_cliente: c.cidade_cliente,
					nome: c.nome,
					matricula_contrato: c.matricula_contrato,
					numero_contrato: c.numero_contrato,
					data_contrato: this.formatoData(c.data_contrato),
					valor_contrato: this.formatoMoeda(c.valor_contrato),
					banco_contrato: c.banco_contrato,
					convenio_contrato: c.convenio_contrato,
					produto_contrato: c.produto_contrato,
					tabela_contrato: c.tabela_contrato,
					prazo_contrato: c.prazo_contrato,
					comissao_contrato: this.formatoMoeda(c.comissao_contrato),
					// comissao: this.formatoMoeda(c.comissao),
					metamensal: c.metamensal,
					data_finalizado: this.formatoData(c.data_finalizado)
				}));
			},

			reset() {
				this.menu = false
				this.dates = []
				this.contratos = []
				this.totalContratos = 0
				this.totalComissaoBanco = 0
				this.totalComissaoConsultor = 0
				// this.totalAbertBanco = 0
				// this.totalAbertConsultor = 0

			},
			csvExportSaeso(arr) {
				this.exportCSV(arr, 'relatorio_SaesoConsig');
			},

			csvExportContatos(arr) {
				const contatos = arr.map(a => {
					return {
						nome:a.nome_cliente,
						numero:a.numero_telefone.replace(/[^0-9]/g, ""),
						cidade:a.cidade_cliente,
						consultor:a.nome,
						produto:a.produto_contrato,
						cpf:a.cpf_cliente
					}
				})
				this.exportCSV(contatos, 'contatos_clientes_saesoconsig');
			},

			exportCSV(arr, arquivoName) {
				let content = "data:text/csv;charset=utf-8,";
				content += [
					Object.keys(arr[0]).join(";"),
					...arr.map(item => Object.values(item).join(";"))
					].join("\n").replace(/(^\[)|(\]$)/gm, "");

				const data = encodeURI(content);
				const num = moment(new Date()).format('DMY.Hms');
				const link = document.createElement("a");

				link.setAttribute("href", data);
				link.setAttribute("download", `${arquivoName}-${num}.csv`);
				link.click();

				this.reset();
			},

			formatoData(data){
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")
			},
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			},
			reg3(porcent, value) {
				let result = []
				result = porcent * value / 100
				return result
			}
		},
		computed: {
			...mapState(['user']),
			dateRangeText () {
				return this.dates.join(' ~ ')
			}
		},
		mounted() {
	// this.loadContratos()
		}
	};
</script>

<style lang="css" scoped></style>
