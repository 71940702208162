<template>
	<div>
		<GChart type="ColumnChart" :data="chartData" :options="chartOptions"></GChart>
	</div>
</template>

<script>
	import { GChart } from 'vue-google-charts'
	export default {

		name: 'GoogleChartBarPesquisa',
		components: { GChart },
		props:{ 
			dataChart:{ type:Array }, 
			options: { type: Object}
		},
		data() {
			return {
				chartData: [],
				chartOptions: this.options  
			}
		},
		methods: {
			generateChartData() {
				const header = ['Como Soube', 'Quantidade'];

    // Cria um objeto para mapear comoSoube para quantidade total
				const aggregation = this.dataChart.reduce((acc, p) => {
					const comoSoube = p.comoSoube;
					const quantidade = Number(p.quantidade);

					if (!acc[comoSoube]) {
						acc[comoSoube] = quantidade;
					} else {
						acc[comoSoube] += quantidade;
					}
					return acc;
				}, {});

    // Transforma o objeto de agregação em uma matriz de dados para o gráfico
				const data = Object.entries(aggregation).map(([key, value]) => [key, value]);

				this.chartData = [header, ...data];
			}
		},
		watch: {
			dataChart: {
				immediate: true,
				handler() {
					this.generateChartData();
				}
			}
		},
		mounted(){
			this.generateChartData(); 

		}
	};
</script>

<style lang="css" scoped>
</style>
