<template>
	<v-app id="inspire">
		<v-main>
			<v-container class="login fill-height" fluid>
				<v-row align="center">
					<v-col cols="12" sm="8"	md="4">
						<v-card class="elevation-1" color="transparent">
							<v-card-text>
								<v-form>
									<v-text-field	
									id="email"
									label="Email ou Usuário"	
									name="email"	
									prepend-icon="mdi-account"	
									type="text"	
									v-model="user.email"
									@keydown.enter.prevent="signin"
									/>

									<v-text-field	
									id="password"
									label="Password"	
									name="password"	
									prepend-icon="mdi-lock"	
									type="password"	
									v-model="user.senha"
									@keydown.enter.prevent="signin"
									/>
								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-col class="text-center">									
									<v-btn outlined color="primary lighten-2" @click="signin">Entrar</v-btn>
								</v-col>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>		
		</v-main>		
	</v-app>
</template>

<script>
	import { baseApiUrl, showError, userKey } from '@/global'
	import axios from 'axios'
	import io from 'socket.io-client'
	export default {

		name: 'Auth',

		data() {
			return {
				user:{},
				socket:io('https://facilita.saeso.app.br:5000',{autoConnect:false})
			}
		},
		methods: {
			signin() {
				axios.post(`${baseApiUrl}/signin`, this.user)
				.then(res => {
					this.$store.commit('setUser', res.data)
					localStorage.setItem(userKey, JSON.stringify(res.data))
					this.$router.push({ path: '/' })

				})
				.catch(showError)
			},
		}
	};
</script>
<style lang="css" scoped>
	#inspire {
		background-image: url('../../../public/img/authPage.png');
		background-size:auto;
		background-position:center;
	}
	.login {
		margin-top: 2%;
		margin-left:8%;
	}

</style>
