<template>
	<v-container>
		<v-dialog v-model="data.modal" fullscreen>
			<v-card>
				<v-card-title>Novo contrato</v-card-title>
				<v-card-text>
					<v-form @submit.stop.prevent>
						<v-row>
							<v-col md="4" sm="12">
								<v-text-field
								id="contrato-matricula"
								label="Matrícula"
								v-model="contrato.matricula_contrato"
								type="number"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-text-field>
							</v-col>
							<v-col md="4" sm="12">
								<v-text-field
								id="contrato-numero"
								label="Número Contrato"
								v-model="contrato.numero_contrato"
								type="number"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-text-field>
							</v-col>
							<v-col md="2" sm="12">
								<v-text-field
								id="contrato-data"
								label="Data Contrato"
								v-model="contrato.data_contrato"
								type="date"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-text-field>
							</v-col>
							<v-col md="2" sm="12">
								<v-currency-field
								id="contrato-valor"
								label="Valor Contrato"
								v-model="contrato.valor_contrato"
								type="text"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="valorReq"
								></v-currency-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="4" sm="12">
								<v-autocomplete
								:items="tabelaBanco"
								:item-text="
								(item) => item.nome_tabela + ' (' + item.nome_banco + ')'
								"
								:item-value="(item) => item"
								v-model="contrato.bancoTabela"
								label="Tabela"
								required
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-autocomplete>
							</v-col>
							<v-col md="3" sm="12">
								<v-select
								:items="produtos"
								v-model="contrato.produto_contrato"
								item-text="nome_produto"
								item-value="nome_produto"
								label="Produto"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
							<v-col md="2" sm="12">
								<v-select
								:items="convenios"
								v-model="contrato.convenio_contrato"
								item-text="nome_convenio"
								label="Convênio"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="2" sm="12">
								<v-select
								:items="prazos"
								v-model="contrato.prazo_contrato"
								item-text="prazo"
								label="Prazo"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
							<v-col md="3" sm="12">
								<v-select
								:items="pagamentos"
								v-model="contrato.tipo_pagamento"
								label="Tipo Pagamento"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
							<v-col md="3">
								<v-select
								:items="formalizacao"
								v-model="contrato.formalizacao"
								label="Tipo Formalizacão"
								:disabled="mode === 'remove'"
								require
								:rules="requerido"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea
								label="Informações"
								outlined
								v-model="contrato.informacoes"
								:disabled="mode === 'remove'"
								></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-col md="12" sm="12" class="text-center">
						<v-btn
						color="primary"
						@click="save"
						v-show="mode === 'save'"
						>salvar</v-btn
						>
						<v-btn
						color="error"
						@click="remove"
						v-show="mode === 'remove'"
						>excluir</v-btn
						>
						<v-btn color="grey" class="ml-2" dark @click="reset"
						>cancelar</v-btn
						>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import axios from "axios";
	import { baseApiUrl, showError } from "@/global";
	import { mapState } from "vuex";
	import { cpf } from "cpf-cnpj-validator";
	import { required } from "vuelidate/lib/validators";
	import { validationMixin } from "vuelidate";
	import Bus from '@/config/bus'
	export default {

		name: 'DialogEfetivar',
		props:{
			data:{},
		},
		data () {
			return {
				contrato:{},
				efetivar:{},
				bancos:[],
				produtos:[],
				convenios:[],
				prazos:[],
				tabelas:[],
				pagamentos: [
				{ text: "OP", value: "OP" },
				{ text: "CONTA", value: "CONTA" },
				],
				formalizacao: [
				{ text: "Digital", value: "Digital" },
				{ text: "Física", value: "Fisica" },
				],
				mode:'save',
				requerido: [(v) => !!v || "*"],
				valorReq: [(v) => !!v || v == 0 || "*"],
				mixins: [validationMixin],
				validations: {
					client: {
						cpf_cliente: { required },
					},
				},
			}
		},
		methods: {
			loadTelemarketing(){
				axios.get(`${baseApiUrl}/telemarketing`)
				.then(res => {
					this.telemarketing = res.data
				})
				.catch(showError)
			},
			loadTabelas() {
				axios
				.get(`${baseApiUrl}/tabelas`)
				.then(res => {
					this.tabelas = res.data;
				})
				.catch(showError);
			},
			loadBancos() {
				axios
				.get(`${baseApiUrl}/bancos`)
				.then((res) => {
					this.bancos = res.data;
				})
				.catch(showError);
			},
			loadProdutos() {
				axios
				.get(`${baseApiUrl}/produtos`)
				.then((res) => {
					this.produtos = res.data;
				})
				.catch(showError);
			},
			loadConvenios() {
				axios
				.get(`${baseApiUrl}/convenios`)
				.then((res) => {
					this.convenios = res.data;
				})
				.catch(showError);
			},
			loadPrazos() {
				axios
				.get(`${baseApiUrl}/prazos`)
				.then((res) => {
					this.prazos = res.data;
				})
				.catch(showError);
			},
			save() {
				var id = this.data.cliente.id_telemarketing
				this.efetivar.status = 'efetivado'
				this.efetivar.fk_cliente = this.data.cliente.fk_cliente
				this.contrato.ativo =
				this.contrato.formalizacao === "digital" ? false : true;
				this.contrato.fk_usuario = this.user.id
				this.contrato.fk_cliente = this.data.cliente.fk_cliente

				axios.post(`${baseApiUrl}/contratos`, this.contrato)
				.then(() => {
					axios.put(`${baseApiUrl}/telemarketing/${id}`, this.efetivar)
					this.$toasted.global.defaultSuccess();
					this.reset();
					Bus.$emit('reload')
				})
				.catch(showError);
			},
			remove() {},
			reset() {
				this.data.modal = false
				this.loadTelemarketing()
			}
		},
		computed: {
			...mapState(["user"]),
			validationCpf() {
				const errors = [{msg:"", color:"error", status:true}];
				if (this.client.cpf_cliente){
					cpf.isValid(this.client.cpf_cliente)
					? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
				}
				if (this.cpf){
					cpf.isValid(this.cpf) ? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
				}
				return errors;
			},
			tabelaBanco() {
				var tab = [];
				this.tabelas.map((t, i) => {
					this.bancos.map((b) => {
						if (t.fk_banco_tabela === b.id_banco) {
							tab[i] = {
								nome_tabela: t.nome_tabela,
								comissao_tabela: t.comissao_tabela,
								nome_banco: b.nome_banco,
							};
						}
					});
				});
				return tab;
			},
		},
		mounted() {
			this.loadTabelas();
			this.loadBancos();
			this.loadProdutos();
			this.loadConvenios();
			this.loadPrazos();
		}
	}
</script>

<style lang="css" scoped>
</style>