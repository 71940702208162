<template>
  <v-container>
    <v-dialog @click:outside="closeDialog" v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          Importar Clientes
          <v-spacer></v-spacer>
          <v-btn icon dark color="red" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-col
          @drop.prevent="csvJSON($event); onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          :class="{ 'grey lighten-2': dragover }"
          @click="$refs.input_csv.click()"
          >
          <v-row class=" grey lighten-3 d-flex flex-column" dense align="center" justify="center" style="cursor:pointer;" v-if="uploadedFiles.length === 0">
            <v-icon color="primary" :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
              mdi-cloud-upload
            </v-icon>
            <input
            ref="input_csv"
            type="file"
            @change="csvJSON($event);onDrop($event)"
            style="display: none"
            />
            Arraste e solte seu arquivo aqui ou click
          </v-row>
          <v-virtual-scroll
          v-if="uploadedFiles.length > 0"
          :items="uploadedFiles"
          height="150"
          item-height="50"
          >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.name">
              <v-list-item-content>
                <v-list-item-title class="font-italic font-weight-bold grey--text">
                  {{ item.name }}
                  <span class="ml-3 success--text">
                    {{ item.size }} bytes</span
                    >
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn @click.stop="removeFile(item.name)" icon>
                    <v-icon color="red"> mdi-close-circle </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-col>
        <!-- <v-simple-table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Apelido</th>
              <th>CPF</th>
              <th>Nascimento</th>
              <th>Rua</th>
              <th>Localidade</th>
              <th>Cidade</th>
              <th>Atendimento</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in planilha" :key="i">
              <td>{{ item.nome_cliente }}</td>
              <td>{{ item.apelido_cliente }}</td>
              <td>{{ item.cpf_cliente }}</td>
              <td>{{ formatoData(item.nascimento_cliente) }}</td>
              <td>{{ item.rua_cliente }}</td>
              <td>{{ item.localidade_cliente }}</td>
              <td>{{ item.cidade_cliente }}</td>
              <td>{{ item.atendimento }}</td>
            </tr>
          </tbody>
        </v-simple-table> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon color="primary">
          <v-icon id="upload-button" @click="saveUpload">mdi-upload</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-container>
</template>
<script>
import XLSX from "xlsx";
import moment from "moment";
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
// import axios from "axios";
// import { baseApiUrl, showError } from "@/global";
export default {
  name: "Upload",
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dragover: false,
      uploadedFiles: [],
      csv: [],
      planilha: [],
    };
  },
  methods: {
    closeDialog() {
      // Remove all the uploaded files
      this.uploadedFiles = [];
      this.csv = [];
      // Close the dialog box
      this.$emit("update:dialog", false);
    },
    onDrop(e) {
      this.dragover = false

      if (this.uploadedFiles.length > 0) this.uploadedFiles = []

      if (!this.multiple && e.dataTransfer.files.length > 1) {
        this.$store.dispatch("addNotification", {
          message: "Only one file can be uploaded at a time..",
          colour: "error"
        });
      }

      else
        Array.from(e.dataTransfer.files).forEach(element =>
          this.uploadedFiles.push(element)
        )


    },
    csvJSON(event) {
      var eFiles = event.target.files || event.dataTransfer.files
      this.csv = eFiles ? eFiles[0]  : null;
      if (this.csv) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary", cellDates: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          const keys = Object.values(data[0]);
          var values = [];
          for (let i = 1; i < data.length; i++) {
            values.push(data[i]);
          }
          var arr = [];
          var obj = {};
          for (let i = 0; i < values.length; i++) {
            for (let j = 0; j < keys.length; j++) {
              arr.push({ key: keys[j], value: values[i][j] });
              obj[i] = Object.assign(...arr.map((a) => ({ [a.key]: a.value })));
            }
          }
          this.planilha = Object.values(obj).map(c =>{
            return {
             nome_cliente: c.nome_cliente,
             apelido_cliente: c.apelido_cliente,
             cpf_cliente: c.cpf_cliente,
             nascimento_cliente: moment(c.nascimento_cliente,"YYYY-MM-DD").format("YYYY-MM-DD"),
             rua_cliente: c.rua_cliente,
             localidade_cliente: c.localidade_cliente,
             cidade_cliente: c.cidade_cliente,
             fk_usuario: c.fk_usuario,
             fk_agendamento: c.fk_agendamento,
             atendimento: c.atendimento,
             fonte_cliente: c.fonte_cliente,
             numero_telefone:c.numero_telefone
           }
         });          
        };
        reader.readAsBinaryString(this.csv);
      }
    },
    saveUpload() {
      axios.post(`${baseApiUrl}/upload`, this.planilha)
      .then(() => {
        this.$toasted.global.defaultSuccess();
        this.loadClients();
        this.dialog = false;
        this.reset();
      })
      .catch(showError);
    },
    removeFile(fileName) {
      // fileName;
      // // Find the index of the
      const index = Array.from(this.uploadedFiles[0]).findIndex((file) => {
        file.name === fileName
      })
      //   );
      // // If file is in uploaded files remove it
      if (index >= -1) this.uploadedFiles.splice(index, 1);
    },
    formatoData(data) {
      if (data) {
        return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
      }
    },
  },
};
</script>

<style>
</style>