<template>
	<v-container>
		<v-dialog v-model="dialog" max-width="400">
			<template v-slot:activator="{ on }">
				<v-col md="6" sm="12">
					<v-btn @click="loadConvenio('', 'save')" x-small color="error" v-on="on" outlined>Novo convênio</v-btn>
				</v-col>
			</template>
			<v-card>
				<v-card-title primary-title>
					{{ titleForm }}
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form>
							<v-row>
								<v-col>		
									<v-text-field v-model="convenio.nome_convenio" label="Convênio" required :disabled="mode === 'remove'" autofocus="true"></v-text-field>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-row>
									<v-col>
										<v-btn color="primary" class="mr-1" v-if="mode === 'save'" @click="save">salvar</v-btn>
										<v-btn color="error" class="mr-1" v-else @click="remove">exluir</v-btn>
										<v-btn color="grey" class="ml-1" dark @click="reset">cancelar</v-btn>			
									</v-col>
								</v-row>
							</v-card-actions>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-card>
			<v-col>
				<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>
			<v-data-table :headers="table" :items="convenios" :search="busca" class="elevation-1">
				<template v-slot:item.actions="{ item }">
					<v-btn icon small color="orange" @click="loadConvenio(item, 'save')"><v-icon small>mdi-pencil</v-icon></v-btn>
					<v-btn icon small color="error" @click="loadConvenio(item, 'remove')"><v-icon small>mdi-delete</v-icon></v-btn>
				</template>
				<template v-slot:item.createdAt="{ item }">
					{{ formatoData(item.createdAt) }}
				</template>
			</v-data-table>
		</v-card>	
	</v-container>
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import moment from 'moment'
	export default {

		name: 'Convenios',

		data() {
			return {
				busca:'',
				dialog:false,
				titleForm:'',
				mode:'save',
				convenios:[],
				convenio:{},
				table:[
				{text:'Convênio', value:'nome_convenio'},
				{text:'Criado em:', value:'createdAt'},
				{text:'Ações', value:'actions'},
				]
			};
		},
		methods: {
			loadConvenios() {
				axios.get(`${baseApiUrl}/convenios`).then(res => {
					this.convenios = res.data
				})
				.catch(showError)
			},
			loadConvenio(item, mode = 'save') {
				this.dialog = true,
				this.mode = mode
				this.convenio = { ...item }
				this.titleForm = item.id_convenio === undefined ? `Novo Convenio` :
				this.mode === 'remove' ? `Excluir Convenio ${ item.nome_convenio } ?` : `Editar Convenio - ${ item.nome_convenio}`
			},
			save() {
				const metodo = this.convenio.id_convenio ? 'put' : 'post'
				const id = this.convenio.id_convenio ? `/${this.convenio.id_convenio}` : ''

				axios[metodo](`${baseApiUrl}/convenios${id}`, this.convenio).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadConvenios()
					this.dialog = false
				})
				.catch(showError)
			},
			remove() {
				const id = this.convenio.id_convenio
				axios.delete(`${baseApiUrl}/convenios/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialog = false
					this.loadConvenios()
					this.reset()
				})
			},
			reset() {
				this.dialog = false
				this.convenio = [] 
			},
			formatoData(data) {
				if(data){
					return moment(data,"YYYY-MM-DD HH:mm").format("DD/MM/YYYY - HH:mm")
				}
			}
		},
		mounted() {
			this.loadConvenios()
		}
	};
</script>

<style lang="css" scoped>
</style>
