<template>
	<div class="h3 text-center">
		<v-toolbar-title><v-icon v-if="icon" :class="icon"></v-icon> {{ main }}			
		</v-toolbar-title>
		<small>{{ sub }}</small>
		<hr class="teal">
	</div>
</template>

<script>
	export default {
		name: 'PageTitle',
		props: ['icon', 'main', 'sub']
	};
</script>

<style lang="css" scoped>
</style>
