<template>
  <v-container dense>
    <PageTitle icon="mdi mdi-handshake" main="Clientes" sub="" />
    <DialogNotas/>
    <DialogContrato/>
    <DialogPesquisa/>
    <small class="grey--text">
      <v-icon color="primary">
        mdi-arrow-bottom-left-bold-box
      </v-icon>
      clientes internos
    </small>
    <small class="grey--text">
      <v-icon color="orange darken-4">
        mdi-arrow-top-right-bold-box
      </v-icon>
      clientes externos
    </small>
    <v-spacer></v-spacer>
    <v-col>
      <v-dialog v-model="dialog" fullscreen>
        <template v-slot:activator="{ on }">
          <v-row>
            <v-col md="3" sm="12" v-show="user.cargo === 'Administrador'">
              <v-btn
              @click="loadClient('', 'save')"
              block
              color="error"
              v-on="on"
              outlined
              >Novo cliente</v-btn
              >
            </v-col>
            <v-col md="3" sm="12" v-show="user.cargo === 'Administrador'">
              <v-btn block color="success" outlined @click="loadClients()"
              >Todos Clientes</v-btn
              >
            </v-col>
            <v-col md="3" sm="12" v-show="user.cargo === 'Administrador'">
              <v-btn block color="cyan" outlined @click="ClienteSemConsultor()"> Clientes s/ Consultor </v-btn>
            </v-col>
            <v-col md="3" sm="12" v-show="user.cargo === 'Administrador'">
              <v-btn block color="primary" @click="uploadDialog = true" outlined> Upload </v-btn>
            </v-col>
          </v-row>
          <Upload :dialog.sync="uploadDialog"/>
        </template>
        <v-card>
          <v-card-title primary-title>
            {{ titleForm }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-form @submit.stop.prevent>
              <input id="client-id" type="hidden" v-model="client.id_cliente" />
              <v-row>
                <v-col md="6" sm="12">
                  <v-text-field
                  id="client-nome"
                  label="Nome"
                  v-model="client.nome_cliente"
                  type="text"
                  size="sm"
                  :disabled="mode === 'remove'"
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                  id="client-apelido"
                  label="Apelido"
                  v-model="client.apelido_cliente"
                  type="text"
                  size="sm"
                  :disabled="mode === 'remove'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2" sm="12">
                  <v-text-field
                  id="client-cpf"
                  label="CPF"
                  v-model="client.cpf_cliente"
                  type="text"
                  size="sm"
                  :disabled="mode === 'remove'"
                  v-mask="['###.###.###-##']"
                  :error-messages="validationCpf[0].msg"
                  :color="validationCpf[0].color"
                  ></v-text-field>
                </v-col>
                <v-col md="2" sm="12">
                  <v-text-field
                  id="client-nascimento"
                  label="Nascimento"
                  v-model="client.nascimento_cliente"
                  type="date"
                  size="sm"
                  :disabled="mode === 'remove'"
                  :error-messages="calcularIdade[0].msg"
                  :color="calcularIdade[0].color"
                  ></v-text-field>
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                  id="client-rua"
                  label="Logradouro(rua, avenida,fazenda, travessa, etc)"
                  v-model="client.rua_cliente"
                  type="text"
                  size="sm"
                  :disabled="mode === 'remove'"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="12">
                  <v-text-field
                  id="client-localidade"
                  label="Localidade(bairro, povoado, distrito, km, etc)"
                  v-model="client.localidade_cliente"
                  type="text"
                  size="sm"
                  :disabled="mode === 'remove'"
                  />
                </v-col>
                <v-col md="6" sm="12">
                  <v-text-field
                  id="client-cidade"
                  label="Cidade"
                  v-model="client.cidade_cliente"
                  type="text"
                  size="sm"
                  :disabled="mode === 'remove'"
                  />
                </v-col>
                <v-col md="2" sm="12">
                  <v-select
                  :items="options"
                  v-model="client.atendimento"
                  label="Atendimento"
                  :disabled="mode === 'remove'"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                md="2"
                sm="12"
                v-for="(tel, i) in client.telefones"
                :key="i"
                >
                <v-text-field
                v-model="tel.numero_telefone"
                :disabled="mode === 'remove'"
                v-mask="['(##)#####-####']"
                elevation-2
                label="Telefone"
                :append-icon="
                i + 1 == client.telefones.length ? 'mdi-plus-circle' : ''
                "
                @click:append="addTel"
                :prepend-icon="i >= 0 ? 'mdi-minus-circle' : ''"
                @click:prepend="remTel(i, tel)"
                :rules="telefoneRules"
                :counter="14"
                :error-messages="telefoneError"
                @input="validateTelefone"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-textarea v-model="client.observacoes" label="Observações" :disabled="mode===remove" outlined></v-textarea>
            </v-row>
            <v-row>
              <v-col md="12" sm="12" class="text-center">
                <v-btn color="primary" v-if="mode === 'save' && !client.id_cliente" @click="save"
                :disabled="calcularIdade[0].disabled">Salvar</v-btn
                >
                <v-btn color="success" v-if="mode === 'save'&& client.id_cliente" @click="save"
                >Atualizar Cliente</v-btn
                >
                <v-btn color="error" v-if="mode === 'remove'" @click="remove"
                >Excluir</v-btn
                >
                <v-btn color="grey" class="ml-2" @click="reset" dark
                >Cancelar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
  <v-container>
    <v-row v-show="user.cargo === 'Administrador' || user.cargo === 'Financeiro'">
      <!-- <v-col>
        <v-select label="Origem Cliente" v-model="client.fonte_cliente" :items="origem" dense></v-select>
      </v-col> -->
      <v-col v-show="client.fonte_cliente === 'interno'">
        <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          v-model="dateRangeText"
          label="Data Inicio ~ Data Fim"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          dense
          ></v-text-field>
        </template>
        <v-date-picker
        v-model="client.dates"
        no-title
        scrollable
        range
        locale="pt-br"
        >
        <v-spacer></v-spacer>
        <v-btn
        text
        color="primary"
        @click="$refs.menu.save((client.dates = []), reset())"
        >
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(client.dates)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</v-col>
<v-col>
  <v-btn
  small
  outlined
  color="primary"
  @click="loadClientsSelected(client)"
  dense
  v-if="(dates.length > 0 && client.fonte_cliente =='interno') || client.fonte_cliente === 'externo'"
  >OK</v-btn
  >
</v-col>
</v-row>
<v-row v-show="user.cargo === 'Administrador' || user.cargo === 'Financeiro'">
 <v-container>
  <v-row>
    <v-col sm="4">
      <v-select label="Banco" v-model="client.banco" :items="bancos" item-text="nome_banco" dense></v-select>
    </v-col>
    <v-col sm="4">
      <v-text-field label="Tx Inicial" v-model="client.taxaIni" dense></v-text-field>
    </v-col>
    <v-col sm="4">
      <v-text-field label="Tx Final" v-model="client.taxaFin" dense></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="4">
      <v-text-field type="number" label="Parc. Pagas" v-model="client.pagas" dense></v-text-field>
    </v-col>
    <v-col cols="12" sm="4">
      <v-text-field v-model="client.idadeMin" label="idade Min" type="number" @blur="validateAge" dense></v-text-field>
    </v-col>
    <v-col cols="12" sm="4">
      <v-text-field v-model="client.idadeMax" label="Idade Max" type="number" @blur="validateAge" dense></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col> 
      <v-autocomplete :items="consultores" item-text="nome" item-value="id_usuario" label="Consultor" v-model="client.consultor" dense></v-autocomplete>
    </v-col>
    <v-col md="3">
      <v-menu
      ref="menu2"
      v-model="menu2"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="scale-transition"
      offset-y
      min-width="auto"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
        v-model="dateRangeText"
        label="Data Inicio ~ Data Fim"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
        ></v-text-field>
      </template>
      <v-date-picker
      v-model="client.periodos"
      no-title
      scrollable
      range
      locale="pt-br"
      >
      <v-spacer></v-spacer>
      <v-btn
      text
      color="primary"
      @click="$refs.menu2.save((client.periodos = []), reset())"
      >
      Cancel
    </v-btn>
    <v-btn text color="primary" @click="$refs.menu2.save(client.periodos)">
      OK
    </v-btn>
  </v-date-picker>
</v-menu>
</v-col>
<v-col>
  <v-btn x-small color="primary" :disabled="!client.banco || !client.taxaIni ||!client.taxaFin || !client.idadeMin || !client.idadeMax ||!client.periodos" @click="buscaPorProduto(client)">Buscar</v-btn>
</v-col>
</v-row>
</v-container>
</v-row>
</v-container>
<v-dialog v-model="dialogTelefone" width="400">
  <v-card>
    <v-card-title>{{ telTitle }}</v-card-title>
    <v-card-actions>
      <v-btn color="primary" @click="removeTelefone()">confirmar</v-btn>
      <v-btn color="grey" @click="resetTel" class="ml-2">cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<v-dialog
v-model="dialogAgendamento"
max-width="500"
transition="dialog-transition"
>
<v-card>
  <v-form @submit.stop.prevent>
    <v-card-title> Agendamento Cliente </v-card-title>
    <v-card-text>
      <v-col md="6" sm="12">
        <v-text-field
        type="date"
        v-model="agendamento.data_agendamento"
        label="Data Agendamento"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-textarea
        v-model="agendamento.informacao_agendamento"
        label="Observação"
        outlined
        ></v-textarea>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="saveAg">salvar</v-btn>
      <v-btn color="grey" dark @click="reset">cancelar</v-btn>
      <v-btn color="error" v-if="agendamento" @click="removeAg(agendamento.id_agendamento)">excluir agendamento</v-btn>
    </v-card-actions>
  </v-form>
</v-card>
</v-dialog>
<v-divider></v-divider>
<v-dialog v-model="dialogAlterar" width="400">
  <v-card>
    <v-card-title> Alterar Consultor </v-card-title>
    <v-card-text>
      <v-select
      label="Consultor"
      :items="consultores"
      v-model="selConsultor"
      item-text="nome"
      item-value="id_usuario"
      ></v-select>
      <v-checkbox label="Enviar pro Telemarketing?" v-model="checkTmk" color="error" v-if="user.cargo === 'Administrador'"></v-checkbox>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="saveAlterar">salvar</v-btn>
      <v-btn color="grey lighten-1" @click="reset">cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<v-dialog v-model="dialogClasse" width="400">
  <v-card>
    <v-card-title> Tipo Cliente </v-card-title>
    <v-card-text>
      <v-select
      label="Classificar Cliente"
      :items="classes"
      v-model="selClasse"
      item-text="text"
      item-value="value"
      ></v-select>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="saveClasse">salvar</v-btn>
      <v-btn color="grey lighten-1" @click="reset">cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<div>
  <v-col sm="12" v-show="user.cargo === 'Consultor' || user.cargo === 'Supervisor'" align="justify">
    <v-row justify="center">
      <v-col md="3">
        <v-text-field
        v-model="cpf"
        v-mask="['###.###.###-##']"
        maxlength="14"
        label="CPF"
        :error-messages="validationCpf[0].msg"
        :color="validationCpf[0].color"
        outlined
        dense
        @keydown.enter.prevent="clienteCpf(cpf)"
        ></v-text-field>
      </v-col>
      <v-col md="1">
        <v-btn color="error" @click="clienteCpf(cpf)" :disabled="validationCpf[0].status"
        ><v-icon>mdi-check</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-col>
  <v-col v-show="user.cargo === 'Administrador' || user.cargo === 'Financeiro'">
    <v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
  </v-col>
  <v-row>
    <v-col md="2" sm="12" v-show="selected.length > 0 && (user.cargo === 'Administrador' || user.cargo === 'Supervisor')">
      <v-btn @click="dialogAlterar = true" small color="primary">
        alterar consulto (Enviar Telemarketing)
      </v-btn>
    </v-col>
    <v-col md="2" sm="12" v-show="selected.length > 0">
      <v-btn @click="enviarTmk" small color="success" v-show="user === 'Administrador'">
        enviar para telemarketing
      </v-btn>
    </v-col>
    <v-col md="2" sm="12" v-show="selected.length > 0  && user.cargo === 'Administrador'">
      <v-btn @click="dialogClasse = true" small dark color="indigo">
        tipo de cliente 
      </v-btn>
    </v-col>
  </v-row>
</div>
<v-col :class="`${alerta.cor}--text`" v-show="alerta.exibir" dark class="font-weight-bold text-center">
  <v-icon v-text="`${alerta.icon}`" :color="alerta.cor"></v-icon>{{ alerta.msg }}
</v-col>
<!--TABELA CONSULTOR-->
<v-data-table
:headers="table"
:items="porCpf"
:search="busca"
:expanded.sync="expanded"
:single-expand="true"
item-key="id_cliente"
show-expand
v-model="selected"
:show-select="user.cargo === 'Supervisor' ? true : false"
checkbox-color="error"
:loading="loading"
loading-text="Carregando... Espere por favor!"
class="elevation-1"
v-if="user.cargo === 'Consultor' || user.cargo === 'Supervisor'"
hide-default-footer
disable-pagination
dense
>
<template v-slot:item.data-table-expand="{ expand, isExpanded, item }">
  <v-icon
  @click="expand(!isExpanded), loadContratos(item.id_cliente)"
  color="blue"
  small
  >mdi-eye</v-icon
  >
</template>
<template v-slot:item.nome_cliente="{ item }">
  <small>
    <td colspan="1">
      <v-sheet >{{ item.nome_cliente }}<small style="font-size:8px;" class="font-italic font-weight-bold" :class="item.categoria === 'quente' ? 'success--text' : item.categoria === 'morno' ? 'info--text' : item.categoria === 'frio' ? 'red--text' : 'grey--text'">{{ item.categoria === 'quente' ? '(QUENTE)' : item.categoria === 'morno' ? '(MORNO)' : item.categoria === 'frio' ? '(FRIO)' : ''}}</small></v-sheet>
      <v-icon small color="primary" v-if="item.fonte_cliente === 'interno'">
        mdi-arrow-bottom-left-bold-box
      </v-icon>
      <v-icon small color="orange darken-4" v-else>
        mdi-arrow-top-right-bold-box
      </v-icon>
      <small>
        <v-flex class="grey--text">
          apelido: {{ item.apelido_cliente }}
        </v-flex>
        <v-flex class="grey--text">
          nascimento: {{ formatoData(item.nascimento_cliente) }}
        </v-flex>
      </small>
    </td>
  </small>
</template>
<template v-slot:item.cpf_cliente="{ item }">
  <td colspan="2" style="font-size:12px;" class="font-italic font-weight-bold">
    {{ item.cpf_cliente }}
  </td>
  <small class="success--text font-weight-bold" v-if="item.agendamento">Agendado: {{ formatoData(item.agendamento.data_agendamento) }}</small>
</template>
<template v-slot:item.rua_cliente="{ item }">
  <td style="font-size:10px;" class="font-italic font-weight-bold">
    {{ item.rua_cliente }}
    <small>
      <v-flex class="grey--text">
        {{ item.localidade_cliente }}
      </v-flex>
      <v-flex class="grey--text">
        {{ item.cidade_cliente }}
      </v-flex>
    </small>
  </td>
</template>
<template v-slot:item.telefones="{ item }">
  <td>
    <small v-for="(num, i) in item.telefones" :key="i">
      {{ num.numero_telefone }}<br />
    </small>
  </td>
</template>
<template v-slot:item.observacoes="{ item }">
  <td>
    <small>
      {{ item.observacoes }}
    </small>
  </td>
</template>
<template v-slot:item.actions="{ item }">
  <div v-if="isTelemarketing(item.id_cliente)">
    <v-flex class="error--text font-italic font-weight-bold">
      Telemarketing
    </v-flex>
  </div>
  <div v-else>
    <v-btn class="mb-2 mt-2"
    x-small
    :color="isAgended(item.agendamento) ? 'success' : 'dark'"
    readonly
    @click="
    !isAgended(item.agendamento) ? addAgendamento(item.id_cliente) : loadAgendamento(item, 'save')
    " dark block>{{ isAgended(item.agendamento) ? 'AGENDADO' : 'AGENDAR' }}</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="indigo" @click="addContrato(item)"
    dark block>add contrato</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="orange" @click="loadClient(item, 'save')"
    dark block>editar</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="info" @click="loadClasse(item)" dark block>classificar</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="error" @click="loadClient(item, 'remove')"
    v-if="user.cargo === 'Supervisor'" block>excluir</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="black" @click="loadLembrete(item, 'save')" dark block outlined>add lembrete</v-btn>
  </div>
  <small class="indigo--text font-weight-bold">
    {{ item.nome }}
  </small>

</template>
<template v-slot:expanded-item="{ headers }">
  <td :colspan="headers.length" class="blue-grey">
    <div class="h4 white--text mt-2 font-weight-bold text-center">
      Histórico de Contratos
    </div>
    <hr class="error" />
    <v-data-table
    class="mb-5"
    :items="contratos"
    :headers="table2"
    :loading="loading2"
    hide-default-footer
    disable-pagination
    >
    <template v-slot:item.valor_contrato="{ item }">
      {{ formatoMoeda(item.valor_contrato) }}
    </template>
    <template v-slot:item.data_contrato="{ item }">
      {{ formatoData(item.data_contrato) }}
    </template>
    <template v-slot:item.data_finalizado="{ item }">
      {{ formatoData(item.data_finalizado) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
      icon
      x-small
      color="warning"
      @click="loadContrato(item)"
      v-show="
      item.data_finalizado === null ||
      user.cargo_user === 'Administrador'
      "
      ><v-icon>mdi-pencil</v-icon></v-btn
      >
      <!-- <v-btn
      icon
      x-small
      color="error"
      @click="loadContrato(item)"
      v-show="
      item.data_finalizado === null ||
      user.cargo_user === 'Administrador' 
      "
      ><v-icon>mdi-delete</v-icon></v-btn
      > -->
    </template>
  </v-data-table>
</td>
</template>
</v-data-table>

<!--TABELA ADMINISTRADOR-->
<v-data-table
:headers="table"
:items="clients"
:search="busca"
class="elevation-1 mt-2"
:expanded.sync="expanded"
:single-expand="true"
item-key="id_cliente"
show-expand
:loading="loading"
loading-text="Carregando... Espere por favor!"
v-model="selected"
:show-select="user.cargo === 'Administrador' ? true : false"
checkbox-color="error"
v-else
>
<template v-slot:item.data-table-expand="{ expand, isExpanded, item }">
  <v-icon
  @click="expand(!isExpanded), loadContratos(item.id_cliente)"
  color="blue"
  small
  >mdi-eye</v-icon>
</template>
<template v-slot:item.id_cliente>
  {{ item.id_cliente}}
</template>
<template v-slot:item.nome_cliente="{ item }">
  <v-badge color="yellow" v-if="isNew(item.createdAt)">
    <template v-slot:badge>
      <span style="color: black;">novo</span>
    </template>
  </v-badge>
  <small>
   <v-sheet :color="item.categoria === 'quente' ? 'success' : item.categoria === 'morno' ? 'info' : item.categoria === 'frio' ? 'red' : 'grey'" dark> {{item.categoria}}
   </v-sheet> 
   {{ item.nome_cliente }}
   <v-icon small color="primary" v-if="item.fonte_cliente === 'interno'">
    mdi-arrow-bottom-left-bold-box
  </v-icon>
  <v-icon small color="orange darken-4" v-else>
    mdi-arrow-top-right-bold-box
  </v-icon>
  <td colspan="1">
    <small class="green--text">
      {{ `resultado pesquisa: ${item.produto_contrato}` }}
    </small>
    <small>
      <v-flex class="grey--text">
        apelido: {{ item.apelido_cliente }}
      </v-flex>
      <v-flex class="grey--text">
        nascimento: {{ formatoData(item.nascimento_cliente) }}
      </v-flex>
    </small>
  </td>
</small>
</template>
<template v-slot:item.cpf_cliente="{ item }">
  <td colspan="1" style="font-size:11px">
    {{ item.cpf_cliente }}
  </td>
</template>
<template v-slot:item.rua_cliente="{ item }">
  <td style="font-size:11px">
    {{ item.rua_cliente }}
    <small>
      <v-flex class="grey--text">
        {{ item.localidade_cliente }}
      </v-flex>
      <v-flex class="grey--text">
        {{ item.cidade_cliente }}
      </v-flex>
    </small>
  </td>
</template>
<template v-slot:item.telefones="{ item }">
  <td>
    <small v-for="(num, i) in item.telefones" :key="i">
      {{ num.numero_telefone }}<br />
    </small>
  </td>
</template>
<template v-slot:item.observacoes="{ item }">
  <td>
    <small>
      {{ item.observacoes }}
    </small>
  </td>
</template>
<template v-slot:item.createdAt="{ item }">
  {{ formatoData(item.createdAt) }}
</template>
<template v-slot:item.actions="{ item }">
  <div v-if="isTelemarketing(item.id_cliente)">
    <v-flex class="error--text font-italic font-weight-bold">
      Telemarketing
    </v-flex>
  </div>
  <div v-else>
    <v-btn class="mb-2 mt-2"
    x-small
    :color="isAgended(item.agendamento) ? 'success' : 'dark'"
    readonly
    @click="
    !isAgended(item.agendamento) ? addAgendamento(item.id_cliente) : loadAgendamento(item, 'save')
    " dark block>{{ isAgended(item.agendamento) ? 'AGENDADO' : 'AGENDAR' }}</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="indigo" @click="addContrato(item)"
    dark block>add contrato</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="orange" @click="loadClient(item, 'save')"
    dark block>editar</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="info" @click="loadClasse(item)" dark block>classificar</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="error" @click="loadClient(item, 'remove')"
    v-if="user.cargo === 'Supervisor'" block>excluir</v-btn>
    <v-btn class="mb-2 mt-2" x-small color="black" @click="loadLembrete(item, 'save')" dark block outlined>add lembrete</v-btn>
  </div>
  <small class="indigo--text font-weight-bold">{{ item.nome }}</small>
</template>
<template v-slot:expanded-item="{ headers }">
  <td :colspan="headers.length" class="blue-grey">
    <div class="h4 white--text mt-2">Histórico de Contratos</div>
    <hr class="error" />
    <v-data-table
    class="mb-5"
    :items="contratos"
    :headers="table2"
    :loading="loading2"
    hide-default-footer
    disable-pagination
    >
    <template v-slot:item.valor_contrato="{ item }">
      {{ formatoMoeda(item.valor_contrato) }}
    </template>
    <template v-slot:item.data_contrato="{ item }">
      {{ formatoData(item.data_contrato) }}
    </template>
    <template v-slot:item.data_finalizado="{ item }">
      {{ formatoData(item.data_finalizado) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn icon x-small color="warning" @click="loadContrato(item,'save')"
      ><v-icon>mdi-pencil</v-icon></v-btn
      >
      <v-btn icon x-small color="error" @click="loadContrato(item,'remove')"
      ><v-icon>mdi-delete</v-icon></v-btn
      >
      <small class="indigo--text font-weight-bold">
        {{ item.nome }}
      </small>
    </template>
  </v-data-table>
</td>
</template>
</v-data-table>
</v-container>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import { mapState } from "vuex";
  import PageTitle from "@/templates/PageTitle";
  import Upload from './Upload'
  import { cpf } from "cpf-cnpj-validator";
  import axios from "axios";
  import { baseApiUrl, showError } from "@/global";
  import moment from "moment";
  import Bus from '@/barramento'
  import DialogNotas from '../clientes/modals/DialogNotas'
  import DialogPesquisa from '../clientes/modals/DialogPesquisa'
  import DialogContrato from '../clientes/modals/DialogContrato'
  export default {
    name: "Clientes",
    components: { PageTitle, Upload, DialogNotas, DialogPesquisa, DialogContrato },
    data() {
      return {
        lembrete:{},
        checkTmk:false,
        dates:[],
        menu:false,
        menu2:false,
        alerta: {},
        cpf: "",
        porCpf: [],
        loading: false,
        loading2: true,
        selConsultor: null,
        selClasse: null,
        selected: [],
        statusAgenda: {},
        disabled:true,
        requerido: [(v) => !!v || "*"],
        valorReq: [(v) => !!v || v == 0 || "*"],
        expanded: [],
        busca: "",
        mode: "save",
        dialog: false,
        dialogTelefone: false,
        dialogContrato: false,
        dialogAgendamento: false,
        dialogAlterar: false,
        dialogClasse: false,
        uploadDialog:false,
        contratoTitle: "",
        titleForm: "",
        telTitle: "",
        telId: "",
        clients: [],
        client: {},
        contratos: [],
        contrato: {},
        consultores: [],
        tabelas: [],
        bancos: [],
        produtos: [],
        convenios: [],
        prazos: [],
        agendamentos: [],
        agendamento: {},
        telemarketing:[],
        nascimento: "",
        telefoneError: "",
        table: [
          { text: "Nome", value: "nome_cliente" },
          { text: "CPF", value: "cpf_cliente" },
          { text: "Logradouro", value: "rua_cliente" },
          { text: "Contato", value: "telefones" },
          { text: "Observacoes", value: "observacoes" },
          { text: "Criado em", value: "createdAt" },
          { text: "Ações", value: "actions" },
          ],
        table2: [
          { text: "Matrícula", value: "matricula_contrato" },
          { text: "Banco", value: "banco_contrato" },
          { text: "Contrato", value: "numero_contrato" },
          { text: "Valor", value: "valor_contrato" },
          { text: "Data Contrato", value: "data_contrato" },
          { text: "Status", value: "status" },
          { text: "Finalizado em", value: "data_finalizado" },
          { text: "Ações", value: "actions" },
          ],
        options: [
          { text: "Presencial", value: "presencial" },
          { text: "Telefone", value: "telefone" },
          ],
        pagamentos: [
          { text: "OP", value: "OP" },
          { text: "CONTA", value: "CONTA" },
          ],
        formalizacao: [
          { text: "Digital", value: "Digital" },
          { text: "Física", value: "Fisica" },
          ],
        classes:[
          { text: "Quente (super tomador)", value: "quente" },
          { text: "Morno (tomador)", value: "morno" },
          { text: "Frio (não tomador)", value: "frio" },
          ],
        mixins: [validationMixin],
        validations: {
          client: {
            cpf_cliente: { required },
          },
        },
        origem:[
          {text:'Cliente Externos', value:'externo'},
          {text:'Cliente Internos', value:'interno'},
          ],
      }
    },
    methods: {
      loadClientsSelected(item) {
        this.reset()
        this.loading = true

        if (item.dates) {
          const [startDate, endDate] = item.dates
          const params = {
            dataIni: moment(startDate, "YYYY-MM-DD"),
            dataFin: moment(endDate, "YYYY-MM-DD")
          }

          axios.get(`${baseApiUrl}/clientesInternos/${item.fonte_cliente}?v=${JSON.stringify(params)}`)
          .then(res => {
            this.clients = res.data
          })
          .catch(err => {
            showError("Erro ao carregar os clientes:", err)
          })
          .finally(() => {
            this.loading = false
          })
        }
      },
      async ClienteSemConsultor() {
        this.reset();
        this.loading = true;

        try {
          const { data } = await axios.get(`${baseApiUrl}/clientes?v=${JSON.stringify('notConsultor')}`);
          this.clients = data

        // Filtra os null values resultantes de clientes que não cumpram a condição
          // this.clients = clients.filter(client => client !== null);

        } catch (error) {
          showError(error);
        } finally {
          this.loading = false;
        }
      }
      ,
      buscaPorProduto(texto) {
        this.trimAllInputValues()
        axios.post(`${baseApiUrl}/searchClienteProduto/`,{texto})
        .then(res => {
          this.clients = res.data
          this.client = {}
          this.date = ''
        })
        .catch(showError)
      },
      loadClients() {
        this.reset();
        this.loading = true;

        axios.get(`${baseApiUrl}/clientes`)
        .then((res) => {
          if (["Administrador", "Supervisor", "Financeiro"].includes(this.user.cargo)) {
            this.clients = res.data
          } else {
            this.clients = res.data.filter(cl => cl.fk_usuario === this.user.id)
          }
        })
        .catch(showError)
        .finally(() => {
          this.loading = false;
        });
      }
      ,
      loadTabelas() {
        axios
        .get(`${baseApiUrl}/tabelas`)
        .then((res) => {
          this.tabelas = res.data;
        })
        .catch(showError);
      },
      loadBancos() {
        axios
        .get(`${baseApiUrl}/bancos`)
        .then((res) => {
          this.bancos = res.data;
        })
        .catch(showError);
      },
      loadProdutos() {
        axios
        .get(`${baseApiUrl}/produtos`)
        .then((res) => {
          this.produtos = res.data;
        })
        .catch(showError);
      },
      loadConvenios() {
        axios
        .get(`${baseApiUrl}/convenios`)
        .then((res) => {
          this.convenios = res.data;
        })
        .catch(showError);
      },
      loadPrazos() {
        axios
        .get(`${baseApiUrl}/prazos`)
        .then((res) => {
          this.prazos = res.data;
        })
        .catch(showError);
      },
      loadAgendamentos() {
        axios
        .get(`${baseApiUrl}/agendamentos`)
        .then((res) => {
          this.agendamentos = res.data.agendamentosDB;
        })
        .catch(showError);
      },
      loadAgendamento(item, mode = 'save') {
        this.agendamento = {
          id_agendamento:item.agendamento.id_agendamento,
          fk_cliente:item.agendamento.fk_cliente,
          data_agendamento:moment(item.agendamento.data_agendamento,'YYYY-MM-DD').format('YYYY-MM-DD'),
          informacao_agendamento:item.agendamento.informacao_agendamento
        }
        this.mode = mode
        this.dialogAgendamento = true
        this.titleForm = item !== '' && this.mode === 'remove' ? `Excluir Agendamento - ${ item.nome_cliente} ?` : `Alterar Agendamento - ${ item.nome_cliente }`
      },
      loadTelemarketing() {
        axios
        .get(`${baseApiUrl}/telemarketing`)
        .then((res) => {
          this.telemarketing = res.data
        })
        .catch(showError);
      },
      loadContratos(item) {
        this.loading2 = true;
        this.contratos = [];
        let arr = [];
        axios
        .get(`${baseApiUrl}/contratos`)
        .then((res) => {
          res.data.map((c, i) => {
            if (c.fk_cliente === item) {
              arr[i] = c;
            }
          });
          this.contratos = arr;
          this.loading2 = false;
        })
        .catch(showError);
      },
      loadConsultores() {
        axios
        .get(`${baseApiUrl}/usuarios`)
        .then((res) => {
          this.consultores = res.data;
        })
        .catch(showError);
      },
      loadContrato(item, mode ='save') {
       Bus.$emit('loadContrato',{item, mode})
     },
     loadClient(item, mode = "save") {
      this.mode = mode;
      this.client = {
        id_cliente: item.id_cliente,
        nome_cliente: item.nome_cliente,
        apelido_cliente: item.apelido_cliente,
        cpf_cliente: item.cpf_cliente,
        nascimento_cliente: item.nascimento_cliente,
        rua_cliente: item.rua_cliente,
        num_cliente: item.num_cliente,
        localidade_cliente: item.localidade_cliente,
        cidade_cliente: item.cidade_cliente,
        atendimento: item.atendimento,
        telefones:
        item === ""
        ? [{ numero_telefone: "" }]
        : item.telefones.length === 0
        ? [{ numero_telefone: "" }]
        : item.telefones,
        observacoes:item.observacoes
      };
      this.dialog = true;
      this.titleForm =
      item === ""
      ? "Cadastrar Novo Cliente"
      : item !== "" && this.mode === "remove"
      ? `Excluir Cliente ${item.nome_cliente} ?`
      : `Editar Cliente ${item.nome_cliente}`;
    },
    addContrato(item, mode = "save") {
      this.contrato.fk_cliente = item.id_cliente;
      this.mode = mode;
      this.dialogContrato = true;
      this.contratoTitle = "Cadastrar Novo Contrato";
      Bus.$emit('loadContrato', {item, mode})
    },
    addAgendamento(item) {
      this.agendamento.fk_cliente = item;
      this.dialogAgendamento = true;
    },
    loadLembrete(item, modo = 'save') {
      Bus.$emit('loadLembrete', { item, modo })
    },
    loadPesquisa(item, modo = 'save') {
      item = this.cpf
      Bus.$emit('loadPesquisa', { item, modo })
    },
    reset() {
      this.client = {};
      this.contrato = {};
      this.agendamento = {};
      this.dates = [];
      this.dialog = false;
      this.dialogContrato = false;
      this.dialogAgendamento = false;
      this.dialogAlterar = false;
      this.dialogClasse = false;
      this.selConsultor = ''
      this.selClasse = ''
      this.selected = []
    },
    resetTel() {
      this.dialogTelefone = false;
      this.telId = {};
    },
    loadClasse(item) {
      this.dialogClasse = true
      this.selected = [item]
    },
    save() {
      const metodo = this.client.id_cliente ? "put" : "post";
      this.client.fk_usuario =
      metodo === "post" ? this.user.id : this.client.fk_usuario;
      const id = this.client.id_cliente ? `/${this.client.id_cliente}` : "";
      axios[metodo](`${baseApiUrl}/clientes${id}`, this.client)
      .then(() => {
        this.$toasted.global.defaultSuccess();
        if(this.user.cargo === 'Administrador') {
          this.loadClients();
        }
        this.dialog = false;
      })
      .catch(showError);
    },
    saveContrato() {
      const metodo = this.contrato.id_contrato ? "put" : "post";
      this.contrato.fk_usuario =
      metodo === "post" ? this.user.id : this.contrato.fk_usuario;
      const id = this.contrato.id_contrato
      ? `/${this.contrato.id_contrato}`
      : "";
      this.contrato.ativo =
      this.contrato.formalizacao === "digital" ? false : true;
      axios[metodo](`${baseApiUrl}/contratos${id}`, this.contrato)
      .then(() => {
        this.$toasted.global.defaultSuccess();
        this.loadContratos(this.contrato.fk_cliente);
        this.dialogContrato = false;
        this.reset();
      })
      .catch(showError);
    },
    saveAg() {
      const metodo = this.agendamento.id_agendamento ? "put" : "post";
      const id = this.agendamento.id_agendamento
      ? `/${this.agendamento.id_agendamento}`
      : "";
      axios[metodo](`${baseApiUrl}/agendamentos${id}`, this.agendamento)
      .then(() => {
        this.$toasted.global.defaultSuccess();
        this.loadAgendamentos();
        this.clienteCpf(this.porCpf[0].cpf_cliente)
        this.dialogAgendamento = false;
        this.dialog = false;
        this.reset();
      })
      .catch(showError);
    },
    removeAg(id) {
      if(confirm('tem certeza?')){
        axios.delete(`${baseApiUrl}/agendamentos/${id}`)
        .then(() => {

          this.$toasted.global.defaultSuccess()
          this.dialog = false
          this.loadAgendamentos()
          this.clienteCpf(this.porCpf[0].cpf_cliente)
          this.dialogAgendamento = false;
          this.reset()
        })
        .catch(showError)
      }

    },
    saveClasse() {
      var classe =[], ids =[]
      this.selected.map((s, i) => {
        if(this.selClasse !== null) {
          classe[i] = {
            id_cliente: s.id_cliente,
            categoria: this.selClasse
          }
        }
        ids[i] = s.id_cliente
        return { classe, ids }

      })

      axios.put(`${baseApiUrl}/alterarClasse/[${ids}]`, {classe, ids})
      .then(() => {
        this.$toasted.global.defaultSuccess();
        this.reset()
        this.loadClients()
      })
      .catch(showError)

    },
    saveAlterar() {
      if(this.checkTmk){
        this.enviarTmk()
      }
      var novoConsultor = [],
      ids = [];
      this.selected.map((s, i) => {
        if (this.selConsultor !== null) {
          novoConsultor[i] = {
            id_cliente: s.id_cliente,
            fk_usuario: this.selConsultor,
          };
        }
        ids[i] = s.id_cliente;
        return { novoConsultor, ids };
      });
      this.client.novoConsultor = novoConsultor;
      axios
      .put(`${baseApiUrl}/alterarConsultor/[${ids}]`, this.client)
      .then(() => {
        this.loadClients();
        this.dialogAlterar = false;
        this.selConsultor = null;
        this.selected = [];
        this.$toasted.global.defaultSuccess();
      })
      .catch(showError);
    },
    enviarTmk() {
      var clientes = []
      this.selected.map(s => {
        if(!this.isAgended(s.id_cliente)){
          clientes.push({fk_cliente:s.id_cliente})  
        } else{
          alert(`Clientes: ${s.nome_cliente} está agendado!`)
        }
      })
      axios.post(`${baseApiUrl}/telemarketing`, clientes)
      .then(() => {
        this.$toasted.global.defaultSuccess();
      })
      .catch(showError)
    },
    remove() {
      const id = this.client.id_cliente;
      axios
      .delete(`${baseApiUrl}/clientes/${id}`)
      .then(() => {
        this.$toasted.global.defaultSuccess();
        this.dialog = false;
        this.loadClients();
        this.clienteCpf(this.client.cpf_cliente)
        this.reset();
      })
      .catch(showError);
    },
    removeContrato() {
      const id = this.contrato.id_contrato;
      axios
      .delete(`${baseApiUrl}/contratos/${id}`)
      .then(() => {
        this.$toasted.global.defaultSuccess();
        this.dialogContrato = false;
        this.loadContratos(this.contrato.fk_cliente);
        this.reset();
      })
      .catch(showError);
    },
    removeTelefone() {
      axios
      .delete(`${baseApiUrl}/telefones/${this.telId.tel.id_telefone}`)
      .then();
      this.client.telefones.splice(this.telId.i, 1);
      this.dialogTelefone = false;
      this.telId = {};
    },
    addTel() {
      this.client.telefones.push({ numero_telefone: "" });
    },
    remTel(i, tel) {
      if (this.client.telefones.length > 1) {
        if (!tel.id_telefone) {
          this.client.telefones.splice(i, 1);
        } else {
          this.dialogTelefone = true;
          this.telTitle = `Excluir o Telefone ${tel.numero_telefone}`;
          this.telId = { i, tel };
        }
      } else {
        alert("Não é possível remover o único contato cadastrado!");
      }
    },
    formatoData(data) {
      if (data) {
        return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
      }
    },
    formatoMoeda(valor) {
      if (valor) {
        let v = (valor / 1).toFixed(2).replace(".", ",");
        return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
      }
    },
    isAgended(item) {
      var status;
      if(item){
        status = true
      }
      return status;
    },
    isTelemarketing(item) {
      var tmk;
      this.telemarketing.map((t) => {
        if (t.id_cliente === item) {
          tmk = true;
        }
      });
      return tmk;
    },
    clienteCpf(cpf) {
      let cpfOld = null
      if(localStorage.getItem('cpf')){
        cpfOld = cpf !== JSON.parse(localStorage.getItem('cpf')) ? JSON.parse(localStorage.getItem('cpf')) : ''
      }
      this.porCpf = [];
      this.alerta = {};
      if (cpf) {
        axios
        .get(`${baseApiUrl}/searchCpf/${cpf}`)
        .then((res) => {
          if (res.data[0].fk_usuario !== this.user.id && this.user.cargo === 'Consultor') {
            this.alerta = {
              exibir: true,
              msg: "Cliente percente a outra carteira - Consulte o seu Supervisor!",
              cor: "orange",
              icon: "mdi-alert-circle",
            };
          } else if(res.data[0].deletedAt !== null) {
            this.alerta = {
              exibir: true,
              msg: "Cliente foi Excluído do Sistema - Consulte o seu Supervisor!",
              cor: "error",
              icon: "mdi-alert-circle",
            };
          } 
          else {
            localStorage.setItem('cpf', JSON.stringify(res.data[0].cpf_cliente))
            this.porCpf = res.data;
            if(cpfOld) {
              this.loadClient(res.data[0], this.mode = 'save')
            }
            this.isAgended(res.data[0].agendamento)
          }
        })
        .catch((e) =>
          e ? this.loadPesquisa("", "save") : (this.dialog = false)
          );
      }
    },
    trimAllInputValues() {
      for (const key in this.contrato) {
        if (typeof this.contrato[key] === 'string') {
          this.contrato[key] = this.contrato[key].trim();
        }
      }
    },
    formatarTelefone(telefone) {
      let numeros = telefone.replace(/\D/g, '');
      if (numeros.length !== 11) return false;
      let primeiroDigito = numeros[0];
      if (numeros.split('').every(d => d === primeiroDigito)) return false;
      return true;
    },
    validateTelefone() {
      // Se for válido, formatamos o telefone
      if (this.formatarTelefone(this.tel.numero_telefone)) {
        const numeros = this.tel.numero_telefone.replace(/\D/g, '');
        this.tel.numero_telefone = `(${numeros.substring(0, 2)})${numeros.substring(2, 7)}-${numeros.substring(7)}`;
      }
    },
    validateAge() {
      if (this.client.idadeMin < 0 || this.client.idadeMin > 100) {
        this.ageError = 'Idade mínima deve estar entre 0 e 100.';
      } else if (this.client.idadeMax < 0 || this.client.idadeMax > 100) {
        this.ageError = 'Idade máxima deve estar entre 0 e 100.';
      } else if (this.client.idadeMin > this.client.idadeMax) {
        this.ageError = 'Idade mínima não pode ser maior que a máxima.';
      } else {
        this.ageError = null;
      }
    },
    isNew(item) {
      const mesAtual = moment().format('MM-YYYY')
      const mesCreatedAt = moment(item).format('MM-YYYY')
      if(mesAtual === mesCreatedAt){
        return true
      }
      return false
    }
  },
  computed: {
    ...mapState(["user"]),
    validationCpf() {
      const errors = [{msg:"", color:"error", status:true}];
      if (this.client.cpf_cliente){
        cpf.isValid(this.client.cpf_cliente)
        ? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
      }
      if (this.cpf){
        cpf.isValid(this.cpf) ? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
      }
      return errors;
    },
    tabelaBanco() {
      var tab = [];
      this.tabelas.map((t, i) => {
        this.bancos.map((b) => {
          if (t.fk_banco_tabela === b.id_banco) {
            tab[i] = {
              codigo_tabela:t.codigo_tabela,
              nome_tabela: t.nome_tabela,
              comissao_tabela: t.comissao_tabela,
              nome_banco: b.nome_banco,
            };
          }
        });
      });
      return tab;
    },
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    calcularIdade() {
      const erros = [{msg:' ', color:'', disabled:false}]
      if(this.client.nascimento_cliente){
       const hoje = moment()
       const nascimento = moment(this.client.nascimento_cliente)

       var anos = hoje.diff(nascimento, 'year');
       nascimento.add(anos, 'years');

       var meses = hoje.diff(nascimento, 'months');
       nascimento.add(meses, 'months');

       var dias = hoje.diff(nascimento, 'days');
       if(anos > 81 && meses >= 0 && dias >= 0){
        erros[0] = {msg:'Idade acima de 81 anos não permitida', color:'red',disabled:true}
      }
    }
    return erros

  },
  telefoneRules() {
    return [
      v => !!v || 'O telefone é obrigatório.',
      v => (v && v.length <= 14) || 'O telefone deve ter no máximo 14 caracteres.',
      v => {
        const valid = this.formatarTelefone(v);
        if (!valid) {
          this.telefoneError = "Telefone inválido";
          return false;
        } else {
          this.telefoneError = "";
          return true;
        }
      }
      ]
  }
},
mounted() {
  this.loadTabelas();
  this.loadBancos();
  this.loadProdutos();
  this.loadConvenios();
  this.loadPrazos();
  this.loadAgendamentos();
  this.loadTelemarketing();
  this.loadConsultores();
  Bus.$on('clienteCpf', item => {
    this.clienteCpf(item)
  })
  Bus.$on('loadClient', item => {
    this.loadClient(item, "save")
  })
},
};
</script>

<style scope>
  .e-toast-container .e-toast .e-toast-message .e-toast-content {
    color: aqua;
    font-size: 20px;
    font-weight: normal;
  }
  #idadeslider .v-input--dense > .v-input__control > .v-input__slot {
    border: 2px solid blue; /* Substitua 'blue' pela cor desejada */
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    background-color: grey;
  }
  .v-badge__badge {
    font-weight: bold; /* Força a cor do texto do badge para preto */
  }
</style>