<template>
	<v-dialog v-model="dialogNotas">
		<!-- <template v-slot:activator="{ on }">
			<v-col md="6" sm="12">
				<v-btn @click="loadLembrete('', 'save')" x-small color="error" v-on="on" outlined>Novo Lembrete</v-btn>
			</v-col>
		</template> -->
		<v-card>
			<v-card-title>
				Lembrete	
			</v-card-title>
			<v-form @submit.stop.prevent>
				<v-card-text>
					<v-row>
						<v-col md="3" sm="12">
							<v-text-field type="date" label="Data do Lembrete" v-model="lembrete.data_lembrete" :disabled="mode === 'remove'"></v-text-field>
						</v-col>
						<v-col md="6" sm="12">
							<v-text-field type="text" label="Nome da Nota" v-model="lembrete.nome_lembrete" :disabled="mode === 'remove'"></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="12" sm="12">
							<v-textarea label="Descrição" v-model="lembrete.descricao_lembrete" outlined :disabled="mode === 'remove'"></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn color="info" @click="save()" v-if="mode === 'save'">salvar</v-btn>
					<v-btn color="error" @click="remove()" v-else>delete</v-btn>
					<v-btn color="grey" @click="reset()">cancelar</v-btn>
				</v-card-actions>
			</v-form>

		</v-card>
	</v-dialog>
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import Bus from '@/barramento'
	import moment from 'moment'
	import { mapState } from 'vuex'
	export default {

		name: 'DialogNotas',
		data () {
			return {
				dialogNotas:false,
				lembrete:{},
				clientes:[],
				mode:'save'
			}
		},
		computed:{
			...mapState(["user"])
		},
		methods:{
			loadLembrete(item, modo ="save") {
				if(item.id_lembrete) {
					this.lembrete = {
						id_lembrete:item.id_lembrete,
						nome_lembrete:item.nome_lembrete,
						data_lembrete:moment(item.data_lembrete).format("YYYY-MM-DD"),
						descricao_lembrete:item.descricao_lembrete,
						fk_cliente:item.fk_cliente
					}
				} else {
					this.lembrete.fk_cliente = item.id_cliente
				}
				this.mode = modo
				this.dialogNotas = true
			},
			async save() {
				try {
					this.lembrete.fk_usuario = this.user.id
					const metodo = this.lembrete.id_lembrete ? 'put' : 'post'
					const id = this.lembrete.id_lembrete ? `/${this.lembrete.id_lembrete}` : ''

					await axios[metodo](`${baseApiUrl}/lembretes${id}`, this.lembrete)
					this.$toasted.global.defaultSuccess()
					Bus.$emit('loadLembretes')
					Bus.$emit('loadNotificacao')
					this.dialogNotas = false
					this.reset()
				} catch(err) {
					showError(err)
				}


			},
			async remove() {
				try {
					await axios.delete(`${baseApiUrl}/lembretes/${this.lembrete.id_lembrete}`)
					this.$toasted.global.defaultSuccess()
					Bus.$emit('loadLembretes')
					Bus.$emit('loadNotificacao')
					this.dialogNotas = false
					this.reset()
				} catch(err) {
					showError(err)
				}
			},
			reset() {
				this.lembrete = {}
				this.dialogNotas = false
			},
			formatoData(data){
				if(data){
					return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
				}
			},
			
		},
		mounted() {
			Bus.$on('loadLembrete', (item) => {
				this.loadLembrete(item.item,item.modo)
			})
		}
	}
</script>

<style lang="css" scoped>
</style>