<template>
	<div>
		<GChart type="ColumnChart" :data="chartData" :options="chartOptions"></GChart>
	</div>
</template>

<script>
import { GChart } from 'vue-google-charts'
export default {

	name: 'GoogleChartBar',
	components: { GChart },
	props:{ 
		dataChart:{ type:Array },
		options: { type: Object }
	},
	data() {
		return {
			chartData: [],
			chartOptions: this.options 
		}
	},
	methods: {
		formatoMoeda(valor) {
			if(valor){
				let v = (valor/1).toFixed(2).replace('.',',')
				return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
			}
		}
	},
	mounted(){
		const data = this.dataChart.map(d => [d.mes, {v:d.total,f:this.formatoMoeda(d.total)}])
		const header = ['Mês/Ano','Total']
		var arr = []
		// const datas = this.chartData.map(d => d.data).reverse()
		// const totals = this.chartData.map(d => d.total).reverse()
		arr = [header].concat(data)
		this.chartData = arr 

	}
};
</script>

<style lang="css" scoped>
</style>
