import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {
	theme: 'bubble',
	iconPack: 'fontawesome',
	position:'top-center',
	duration: 3000
})

Vue.toasted.register(
	'defaultSuccess',
	payload => !payload.msg ? 'Operação Realizada com Sucesso!' : payload.msg,
	{ type: 'success', icon: 'check'}
	)

Vue.toasted.register(
	'defaultError',
	payload => !payload.msg ? 'Oops..! Erro inresperado!' : payload.msg,
	{ type: 'error', icon: 'times' }
	)

// Vue.toasted.register(
// 	'chatMessage',
// 	payload => {
// 		if(payload){
// 			return `
// 			<v-col>
// 			<h3>${payload.msg}</h3><br>
// 			<small class="right">${payload.user}</small>
// 			</v-col>`
// 		}
// 	},
// 	{ 
// 		theme:'toasted-primary',
// 		type: 'success',  
// 		duration:5000,
// 		position:'bottom-right'
// 	})
Vue.toasted.register(
	'alertMessage',
	payload => {
		if(payload){
			return `
			<v-col>
			<h3>ATENÇÃO!</h3>
			<h4>${payload.msg}</h4>
			<v-spacer></spacer>
			</v-col>`
		}
	},
	{ 
		theme:'toasted-primary',
		duration:'infinity',
		type:'info',
		position:'top-center',
		icon:'warning',
		action: {
			text: 'Fechar',
			onClick: (e, toastObject) => {
				toastObject.goAway(0); 
			}
		},
		fullscreen:true
	})

