<template>
	<v-container>
		<v-dialog v-model="dialog" max-width="400">
			<template v-slot:activator="{ on }">
				<v-col md="6" sm="12">
					<v-btn @click="loadProduto('', 'save')" x-small color="error" v-on="on" outlined>Novo produto</v-btn>
				</v-col>
			</template>
			<v-card>
				<v-card-title primary-title>
					{{ titleForm }}
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form>
							<v-row>
								<v-col>		
									<v-text-field v-model="produto.nome_produto" label="Produto" required :disabled="mode === 'remove'" autofocus="true"></v-text-field>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-row>
									<v-col>
										<v-btn color="primary" class="mr-1" v-if="mode === 'save'" @click="save">salvar</v-btn>
										<v-btn color="error" class="mr-1" v-else @click="remove">exluir</v-btn>
										<v-btn color="grey" class="ml-1" dark @click="reset">cancelar</v-btn>			
									</v-col>
								</v-row>
							</v-card-actions>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-card>
			<v-col>
				<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>
			<v-data-table :headers="table" :items="produtos" :search="busca" class="elevation-1">
				<template v-slot:item.actions="{ item }">
					<v-btn icon small color="orange" @click="loadProduto(item, 'save')"><v-icon small>mdi-pencil</v-icon></v-btn>
					<v-btn icon small color="error" @click="loadProduto(item, 'remove')"><v-icon small>mdi-delete</v-icon></v-btn>
				</template>
				<template v-slot:item.createdAt="{ item }">
					{{ formatoData(item.createdAt) }}
				</template>
			</v-data-table>
		</v-card>	
	</v-container>
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import moment from 'moment'
	export default {
		name: 'Produtos',
		data() {
			return {
				busca:'',
				dialog:false,
				titleForm:'',
				mode:'save',
				produtos:[],
				produto:{},
				table:[
				{text:'Produto', value:'nome_produto'},
				{text:'Criado em:', value:'createdAt'},
				{text:'Ações', value:'actions'},
				]
			};
		},
		methods: {
			loadProdutos() {
				axios.get(`${baseApiUrl}/produtos`).then(res => {
					this.produtos = res.data
				})
				.catch(showError)
			},
			loadProduto(item, mode = 'save') {
				this.dialog = true,
				this.mode = mode
				this.produto = { ...item }
				this.titleForm = item.id_produto === undefined ? `Novo Produto` :
				this.mode === 'remove' ? `Excluir Produto ${ item.nome_produto } ?` : `Editar Produto - ${ item.nome_produto}`
			},
			save() {
				const metodo = this.produto.id_produto ? 'put' : 'post'
				const id = this.produto.id_produto ? `/${this.produto.id_produto}` : ''

				axios[metodo](`${baseApiUrl}/produtos${id}`, this.produto).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadProdutos()
					this.dialog = false
				})
				.catch(showError)
			},
			remove() {
				const id = this.produto.id_produto
				axios.delete(`${baseApiUrl}/produtos/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialog = false
					this.loadProdutos()
					this.reset()
				})
			},
			reset() {
				this.dialog = false
				this.produto = {}
			},
			formatoData(data) {
				if(data){
					return moment(data,"YYYY-MM-DD HH:mm").format("DD/MM/YYYY - HH:mm")
				}
			}
		},
		mounted() {
			this.loadProdutos()
		}
	};
</script>

<style lang="css" scoped>
</style>
