<template>
	<v-container>
		<PageTitle icon="mdi mdi-cake-variant" main="Aniversários" sub=""></PageTitle>
		<v-card class="mt-5">
			<v-container class="my-5" v-if="today.length">
				<v-card class="pa-2" flat v-for="(cliente,i) in today" :key="i">
					<v-layout row wrap :class="`pa-3 cliente ${status = cliente.ativo === 0 ? false : true}`">
						<v-flex sx12 md3>
							<div class="caption grey--text">Nome</div>
							<div style="font-size:0.7rem;">{{ cliente.nome_cliente }}</div>
							<small class="cpation grey--text">cpf: {{ cliente.cpf_cliente }}</small><br>
							<small class="cpation info--text">nasc: {{ formatoData(cliente.nascimento_cliente) }}</small>
						</v-flex>
						<v-flex sx12 md3>
							<div class="caption grey--text">Endereço</div>
							<div style="font-size:0.7rem;">{{ cliente.rua_cliente }}</div>
							<div class="info--text font-weight-bold" style="font-size:0.5rem;">{{ cliente.cidade_cliente }}</div>
						</v-flex>
						<v-flex xs4 sm2 md2>
							<div class="caption grey--text">Contato</div>
							<small  style="font-size:0.7rem;" v-for="n in cliente.telefones" :key="n.id_telefone">
								{{ n.numero_telefone }}
							</small>
						</v-flex>
						<v-flex>
							<div class="caption grey--text">Informação</div>
							<small :class="`${status = cliente.ativo === 0 || cliente.ativo === null ? false : true} inf`" style="font-size:0.7rem;">{{ cliente.info }}</small><br>
							<small  class="info--text font-weight-bold" style="font-size:0.6rem;">{{ cliente.nome }}</small>
						</v-flex>
						<v-flex xs12 sm12>
							<v-btn x-small dark color="green" @click="tratar(cliente.id_cliente)">já ligou?</v-btn>
							<v-btn x-small dark color="primary" @click="addConsultor(cliente.id_cliente)" v-show="!cliente.id_usuario && user.cargo === 'Administrador'">Add consultor</v-btn>
						</v-flex>
					</v-layout>
					<v-divider class="mt-3"></v-divider>
				</v-card>
			</v-container>
			<v-container class="text-center grey--text" v-else>
				Não há aniversante no momento!
			</v-container>
		</v-card>
		<v-dialog v-model="dialog" max-width="400">
			<v-car>
				<v-card-title>Alterar Consultor</v-card-title>
				<v-card-text>
					<v-select label="Consultor" :items="consultores" v-model="selConsultor" item-text="nome" item-value="id_usuario"></v-select>
				</v-card-text>
				<vcard-actions>
					<v-btn color="primary" @click="save">salvar</v-btn>
					<v-btn color="grey lighten-1" @click="reset">cancelar</v-btn>
				</vcard-actions>
			</v-car>
		</v-dialog>
	</v-container>
</template>

<script>
	import { mapState } from 'vuex'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import PageTitle from '@/templates/PageTitle'
	import moment from 'moment'
	import Bus from '@/barramento'
	export default {

		name: 'Aniversariantes',
		components:{ PageTitle },
		data () {
			return {
				dialog:false,
				enable:true,
				today:[],
				consultores:[],
				selConsultor:null,
				selected:null,
				client:{}
			}
		},
		methods: {
			loadClientes() {
				axios.get(`${baseApiUrl}/aniversarios`)
				.then(res => {
					res.data.map(an => {
						if(an.id_usuario === this.user.id || this.user.cargo === 'Administrador' || this.user.cargo === 'Supervisor') {
							this.today.push(an)
						}
					})
				})
			},
			addConsultor(id) {
				this.selected = id
				this.dialog = true
				axios.get(`${baseApiUrl}/usuarios`)
				.then(res => {
					res.data.map(us => {
						this.consultores.push(us.cargo === 'Consultor' ? us : null)
					})
				})
				.catch(showError)
			},
			save() {
				let novoConsultor  = []
				if(this.selConsultor !== null) {
					novoConsultor.push({
						id_cliente:this.selected,
						fk_usuario:this.selConsultor
					})
					this.client.novoConsultor = novoConsultor
					axios.put(`${baseApiUrl}/alterarConsultor/[${novoConsultor[0].fk_usuario}]`, this.client)
					.then(() => {
						this.today = []
						this.loadClientes()
						this.$toasted.global.defaultSuccess()
						this.reset()
					})
				}
			},
			reset() {
				this.dialog = false,
				this.selConsultor = null,
				this.selected = null
			},
			formatoData(data){
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")
			},
			tratar(item) {
				axios.delete(`${baseApiUrl}/aniversarios/${item}`)
				.then(() => {
					this.today = []
					this.loadClientes()
					this.$toasted.global.defaultSuccess()
					this.reset()
					Bus.$emit('reload')
				})
				.catch(showError)
			}
		},
		computed:mapState(['user']),
		mounted() {
			this.loadClientes()
		}
	}
</script>

<style lang="css" scoped>
	.cliente.false {
		border-left: 4px solid #0D47A1;	
	}
	.cliente.true {
		border-left: 4px solid tomato;
	}
	.false.inf {
		color: #0D47A1;
		font-weight: bolder;
	}
	.true.inf {
		color: tomato;
		font-weight: bolder;
	}
</style>