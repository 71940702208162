<template>
	<v-container fluid>
		<PageTitle icon="mdi mdi-file-check" main="Contratos para Formalização" sub="contratos ativos"/>
		<v-row align="center" justify="space-around">
			<v-col>	
				<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>
			<v-col md="2" sm="12" v-show="selected.length > 0">
				<v-btn @click="save" small color="primary">Formalizar</v-btn>
			</v-col>
		</v-row>
		<v-data-table id="tabela" :headers="table" :items="contratos" :search="busca" :loading="loading" loading-text="Carregando... Espere por favor!" v-model="selected" show-select  item-key="id_contrato" class="elevation-1 mt-2" dense>
			<template v-slot:item.numero_contrato="{ item }">
				<td colspan="1" class="font-weight-normal">	
					{{ item.numero_contrato }}
					<small class="grey--text">
						<v-flex>
							id: {{ item.id_contrato }}
						</v-flex>
						<v-flex>
							matrícula: {{ item.matricula_contrato }}
						</v-flex>
						<v-flex>
							data: {{ formatoData(item.data_contrato) }}
						</v-flex>
					</small>
				</td>
			</template>
			<template v-slot:item.banco_contrato="{ item}">
				<td colspan="1" class="font-weight-normal">
					{{ item.banco_contrato }}
					<small class="grey--text">
						<v-flex>
							produto/convenio: {{ item.produto_contrato }} - {{ item.convenio_contrato }}
						</v-flex>
						<v-flex>
							tabela/prazo: {{ item.tabela_contrato }} - {{ item.prazo_contrato }} X
						</v-flex>
					</small>
				</td>
			</template>
			<template v-slot:item.nome_cliente="{ item }">
				<td colspan="1" class="font-weight-normal">	
					{{ item.nome_cliente }}
					<small class="grey--text">
						<v-flex>
							CPF: {{ item.cpf_cliente }}
						</v-flex>
						<v-flex>
							apelido: {{ item.apelido_cliente }}
						</v-flex>
						<v-flex>
							atendimento: {{ item.atendimento }}
						</v-flex>
					</small>
				</td>
			</template>
			<template v-slot:item.valor_contrato="{ item}">
				<td colspan="1">
					<v-flex class="green--text darken-4--text font-weight-bold">
						{{ formatoMoeda(item.valor_contrato) }}
					</v-flex>	
					<v-flex class="dark--text">
						<small>{{ item.nome }}</small>
					</v-flex>
				</td>
			</template>
			<template v-slot:item.data_finalizado="{ item }">
				{{ formatoData(item.data_finalizado)}}
			</template>
			<template v-slot:item.notifica="{ item }">
				<v-alert :color="item.alert.cor" class="alerta font-italic font-weight-bold" dark>
				{{ item.alert.msg }}</v-alert>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
	import jsPDF from 'jspdf'
	import 'jspdf-autotable'
	import { mapState } from 'vuex'
	import PageTitle from '@/templates/PageTitle'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import moment from 'moment'
	export default {

		name: 'Finalizados',
		components: { PageTitle },
		data() {
			return {
				notifica:[],
				footer:'Vanderlandia de Souza Cardoso ME - CPNJ 11.505.692/0001-46 - End. Trav. Evência Brito S/N Ribeira do Pombal - BA',
				heading:"Protocolo",
				dialogFormalizacao:false,
				selected:[],
				loading:false,
				contratos:[],
				contrato:{},
				busca:'',
				table:[
					{text:'Contrato', value:'numero_contrato'},
					{text:'Banco', value:'banco_contrato'},
					{text:'valor', value:'valor_contrato'},
					{text:'Cliente', value:'nome_cliente'},
					{text:'Finalizado', value:'data_finalizado'},
					{text:'Notificação', value:'notifica', sortable:false},
					]
			};
		},
		methods:{
			loadContratos() {
				axios.get(`${baseApiUrl}/formalizacao`).then(res => {
					var arr = []	
					res.data.map((c,i) => {
						if(c.fk_usuario === this.user.id){
							arr[i] = c
						}else if(this.user.cargo === 'Administrador' || this.user.cargo === 'Supervisor' || this.user.cargo === 'Financeiro'){
							arr = res.data
						}
					// this.contratos = arr
						this.contratos = arr.map(a => {
							return {	
								apelido_cliente: a.apelido_cliente,
								atendimento: a.atendimento,
								banco_contrato: a.banco_contrato,
								cpf_cliente: a.cpf_cliente,
								data_contrato: a.data_contrato,
								data_finalizado: a.data_finalizado,
								fk_usuario: a.fk_usuario,
								id_contrato: a.id_contrato,
								matricula_contrato: a.matricula_contrato,
								metamensal: a.metamensal,
								nome: a.nome,
								nome_cliente: a.nome_cliente,
								numero_contrato: a.numero_contrato,
								produto_contrato: a.produto_contrato,
								tabela_contrato: a.tabela_contrato,
								valor_contrato: a.valor_contrato,
								alert:this.contarDias(a.data_finalizado)
							}
						})
					})
				})
				.catch(showError)
			},
			formatoData(data){
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
			},
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			},
			createPdf() {
				const numProt = moment(new Date()).format('YMD.Hms')

				const sel = this.selected.map(s => {
					return {
						data:this.formatoData(s.data_contrato),
						contrato:s.numero_contrato,
						banco:s.banco_contrato,
						valor:this.formatoMoeda(s.valor_contrato),
						cliente:s.nome_cliente,
					}
				})
				const doc = new jsPDF({
					orientation:'portrait',
					unit:'in',
					format:'letter'
				});
				doc.setFontSize(14).text(`${this.heading} - ${this.user.nome} - ${numProt}`, 0.5, 1.0);
				doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
				doc.autoTable({
					theme:'striped',
					columns:[
						{header:'Data', dataKey:'data'},
						{header:'Contrato', dataKey:'contrato'},
						{header:'Banco', dataKey:'banco'},
						{header:'Valor', dataKey:'valor'},
						{header:'Cliente', dataKey:'cliente'},
						],
					body: sel,
					margin: { left: 0.5, top: 1.25 }
				});

				doc
				.setFont("times")
				.setFontSize(10)
				.text(this.footer, 0.5, 10.5, { align: "left", maxWidth: "7.5" },
					doc.internal.pageSize.height - 0.5)
				;

				doc.save(`${this.heading}-${this.user.nome}.pdf`);

			},
			save(){
				var ativo = [], ids = []

				this.selected.map((s,i) => {
					ativo[i] = {
						id_contrato:s.id_contrato,
						ativo:false
					}
					ids[i] = s.id_contrato
				})
				this.contrato.ativo = ativo
				axios.put(`${baseApiUrl}/formalizacao/[${ids}]`, this.contrato)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadContratos()
					this.selected = []
				// this.reset()
				})
				.catch(showError)

				this.createPdf()

			},
			contarDias(data) {
				const hoje = new Date();
				hoje.setHours(0, 0, 0, 0); 

				const dataComparacao = new Date(data);
				dataComparacao.setHours(0, 0, 0, 0);

				const diferencaTempo = hoje.getTime() - dataComparacao.getTime();
				const diferencaDias = diferencaTempo / (1000 * 3600 * 24);

				if (diferencaDias > 0) {
					return { msg: `vencido há ${diferencaDias} dia(s)`, cor: 'error' };
				} else if (diferencaDias === 0) {
					return { msg: `vence HOJE!`, cor: 'error' };
				} else if (diferencaDias >= -2) {
					return { msg: `Restam ${-diferencaDias} dia(s)`, cor: 'orange' };
				} else {
					return { msg: `Restam ${-diferencaDias} dia(s)`, cor: 'green' };
				}
			}
		},
		computed:{
			...mapState(['user'])
		},
		mounted() {
			this.loadContratos()
		}
	};
</script>

<style lang="css" scoped>
	.alerta {
		font-size: 85%
	}
</style>
