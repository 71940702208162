import 'font-awesome/css/font-awesome.css'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './config/router'
import store from './config/store'

import './config/msgs'

import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue:null,
	valueAsInteger: false,
	allowNegative: false
})

import VueGoogleCharts from 'vue-google-charts'
Vue.use(VueGoogleCharts)

import mask from 'vuejs-mask'
Vue.use(mask)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
