<template>
	<v-container dense fluid>
		<PageTitle icon="mdi mdi-chart-bar" main="Metas" sub="" />
		<v-dialog v-model="dialog" max-width="1100">
			<template v-slot:activator="{ on }">
				<v-col md="6" sm="12">
					<v-btn @click="loadMeta('', 'save')" x-small color="error" v-on="on" outlined>Nova Meta</v-btn>
				</v-col>
			</template>
			<v-card>
				<v-card-title>{{ titleForm }}</v-card-title>
				<v-card-text>
					<v-form @submit.stop.prevent>
						<v-row>
							<v-col md="6" sm="12">
								<v-autocomplete label="Tabela/Banco" v-model="meta.tabela_meta" :items="tabelas"
									:item-text="item => `${item.nome_tabela} (${item.nome_banco})`"
									item-value="id_tabela" :disabled="modo === 'remove'"></v-autocomplete>
							</v-col>
							<v-col md="6" sm="12">
								<v-autocomplete label="Consultor" v-model="meta.consultor_meta" :items="consultores"
									item-text="nome" item-value="id_usuario" :disabled="modo === 'remove'" multiple
									chips small-chips></v-autocomplete>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="4" sm="12">
								<v-text-field label="Validade" v-model="meta.validade_meta" type="date"
									:disabled="modo === 'remove'"></v-text-field>
							</v-col>
							<v-col md="4" sm="12">
								<v-currency-field label="Valor" v-model="meta.valor_meta" :disabled="modo === 'remove'">
								</v-currency-field>
							</v-col>
							<v-col md="4" sm="12">
								<v-text-field label="Promotor(a)" v-model="meta.promotor_meta" :items="fnPromotores" disabled>
								</v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="info" @click="save" v-show="modo === 'save'">salvar</v-btn>
					<v-btn color="error" @click="remove" v-show="modo === 'remove'">deletar</v-btn>
					<v-btn @click="reset">cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col>
			<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
			</v-text-field>
		</v-col>
		<v-data-table :headers="table" :items="metas" class="elevation-1" :loading="loading" :search="busca"
			loading-text="carregando..." dense>
			<template v-slot:item.nome_tabela="{ item }">
				<v-flex>
					{{ item.nome_tabela }}
				</v-flex>
				<small>
					<v-flex>
						<b>Promotor(a):</b><b class="info--text">{{item.promotora_meta }}</b>
					</v-flex>
				</small>
				<small>
					<v-flex>
						<b>criada em:</b><b class="info--text">{{item.createdAt }}</b>
					</v-flex>
				</small>
			</template>
			<template v-slot:item.valor_meta="{ item}">
				{{ formatoMoeda(item.valor_meta) }}
			</template>
			<template v-slot:item.consultores="{ item }">
				<small>
					<v-flex v-for="(cons,i) in  item.consultores" :key="i">
						{{ cons.nome }}
					</v-flex>
				</small>
			</template>
			<template v-slot:item.validade_meta="{ item }">
				{{ formatoData(item.validade_meta)}}
			</template>
			<template v-slot:item.notifica="{ item }">
				<v-alert class="font-weight-bold text-center" :color="item.alert.cor" dark>
					<v-icon>{{ item.alert.icon }}</v-icon>
					{{ item.alert.msg }}
				</v-alert>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn icon small @click="loadMeta(item, 'save')">
					<v-icon small color="orange">mdi-pencil</v-icon>
				</v-btn>
				<v-btn icon small @click="loadMeta(item, 'remove')">
					<v-icon small color="error">mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import PageTitle from '@/templates/PageTitle'
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import moment from 'moment'
export default {

	name: 'Metas',
	components: { PageTitle },
	data() {
		return {
			busca: '',
			modo: '',
			titleForm: '',
			loading: true,
			dialog: false,
			notifica: [],
			meta: {},
			metas: [],
			produtos: [],
			tabelas: [],
			prazos: [],
			consultores: [],
			promotores: [],
			table: [
				{ text: 'Tabela', value: 'nome_tabela' },
				{ text: 'Banco', value: 'banco_meta' },
				{ text: 'Consultor(es)', value: 'consultores' },
				{ text: 'Validade', value: 'validade_meta' },
				{ text: 'Valor', value: 'valor_meta' },
				{ text: 'Notificação', value: 'notifica' },
				{ text: 'Ações', value: 'actions' }
			]
		}
	},
	methods: {
		loadMetas() {
			axios.get(`${baseApiUrl}/metas`).then(res => {
				this.metas = res.data.map(m => {
					return {
						id_meta: m.id_meta,
						id_tabela: m.id_tabela,
						codigo_tabela: m.codigo_tabela,
						nome_tabela: m.nome_tabela,
						banco_meta: m.nome_banco,
						promotora_meta: m.promotora_meta,
						validade_meta: m.validade_meta,
						valor_meta: m.valor_meta,
						consultores: m.consultores,
						alert: this.metaVencida(m.validade_meta),
						createdAt:this.formatoData(m.createdAt)
					}
				})

			})
				.catch(showError)
		},
		loadMeta(item, modo = 'save') {
			this.modo = modo
			this.dialog = true
			this.meta = {
				id_meta: item.id_meta,
				tabela_meta: item.id_tabela,
				consultor_meta: item.consultores,
				promotor_meta: item.promotora_meta,
				validade_meta: moment(item.validade_meta).format('YYYY-MM-DD'),
				valor_meta: item.valor_meta
			}
			this.titleForm = item === '' ? 'Nova meta' : this.modo === 'remove' ? `Excluir meta ?` : `Editar meta`
		},
		loadProdutos() {
			axios.get(`${baseApiUrl}/produtos`).then(res => {
				this.produtos = res.data
				this.loading = false

			})
				.catch(showError)
		},
		loadTabelas() {
			axios.get(`${baseApiUrl}/tabelas`).then(res => {
				this.tabelas = res.data.map(t => {
					return {
						id_tabela: t.id_tabela,
						nome_tabela: t.nome_tabela,
						codigo_tabela: t.codigo_tabela,
						nome_banco: t.nome_banco,
						id_banco: t.fk_banco_tabela
					}
				})
			})
				.catch(showError)
		},
		loadUsuarios() {
			axios.get(`${baseApiUrl}/usuarios`).then(res => {
				this.consultores = res.data
			})
				.catch(showError)
		},
		loadPromotores() {
			axios.get(`${baseApiUrl}/promotoras`).then(res => {
				this.promotores = res.data
			})
				.catch(showError)
		},
		save() {
			const metodo = this.meta.id_meta ? 'put' : 'post'
			const id = this.meta.id_meta ? `/${this.meta.id_meta}` : ''
			axios[metodo](`${baseApiUrl}/metas${id}`, this.meta).then(() => {
				this.$toasted.global.defaultSuccess()
				this.loadMetas()
				this.dialog = false
				this.reset()
			})
				.catch(showError)
		},
		remove() {
			const id = this.meta.id_meta
			axios.delete(`${baseApiUrl}/metas/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialog = false
					this.loadMetas()
					this.reset()
				})
				.catch(showError)
		},
		reset() {
			this.dialog = false,
				this.meta = {}
		},
		formatoData(data) {
			if (data) {
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")
			}
		},
		formatoMoeda(valor) {
			if (valor) {
				let v = (valor / 1).toFixed(2).replace('.', ',')
				return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
			}
		},
		metaVencida(data) {
			let resp = []
			let diff = moment().diff(moment(data, "YYYY-MM-DD"), 'days')
			resp = diff > 0 ? { msg: `vencida`, cor: 'error', icon: 'mdi-alert-circle' } : { msg: 'ativa', cor: 'success', icon: 'mdi-check-bold' }
			return resp
		}
	},
	computed: {
		fnPromotores() {
			return this.tabelas.map(t => {
				this.promotores.map(p => {
					p.bancos.map(b => {
						if(t.id_banco === b.fk_banco) {
							if(this.meta.tabela_meta === t.id_tabela) {
								this.meta.promotor_meta = p.nome_promotora
							}
						}
					})
				})

			})
		}
	},
	mounted() {
		this.loadMetas()
		this.loadProdutos()
		this.loadTabelas()
		this.loadUsuarios()
		this.loadPromotores()
	}
};
</script>

<style lang="css" scoped>
.alerta {
	font-size: 85%;
}
</style>
