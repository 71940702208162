<template>
	<v-container>
		<v-dialog v-model="dialog" max-width="1200">
			<template v-slot:activator="{ on }">
				<v-col md="6" sm="12">
					<v-btn @click="loadTabela('', 'save')" x-small color="error" v-on="on" outlined>Nova tabela</v-btn>
				</v-col>
			</template>
			<v-card>
				<v-card-title primary-title>
					{{ titleForm }}
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form>
							<v-row>
								<v-col>
									<v-checkbox label="Referência da Tabela" v-model="checked" :disabled="mode === 'remove'"></v-checkbox>
								</v-col>
								<v-col v-if="checked">
									<v-text-field v-model="tabela.referencia" label="Descrição" :disabled="mode === 'remove'"></v-text-field>
								</v-col>
								<v-col>		
									<v-text-field v-model="tabela.codigo_tabela" label="Código Tabela" required :disabled="mode === 'remove'" :autofocus="true"></v-text-field>
								</v-col>
								<v-col>		
									<v-autocomplete :items="convenios" item-text="nome_convenio" v-model="tabela.convenio" label="Convênio" required :disabled="mode === 'remove'"></v-autocomplete>
								</v-col>
								<v-col>	
									<v-autocomplete :items="produtos" item-text="nome_produto" v-model="tabela.nome_produto" label="Produto" required :disabled="mode === 'remove'"></v-autocomplete>
								</v-col>
								<v-col>		
									<v-select :items="perfil" v-model="tabela.nome_perfil" label="Perfil" required :disabled="mode === 'remove'"></v-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col>		
									<v-text-field v-model="tabela.especie" label="Espécie" required :disabled="mode === 'remove'"></v-text-field>
								</v-col>
								<v-col>		
									<v-text-field v-model="tabela.taxa" label="Taxa" required :disabled="mode === 'remove'"></v-text-field>
								</v-col>
								<v-col>		
									<v-text-field type="number" v-model="tabela.comissao_tabela" label="Comissão" required :disabled="mode === 'remove'"></v-text-field>
								</v-col>
								<v-col>	
									<v-autocomplete :items="bancos" item-text="nome_banco" item-value="id_banco" v-model="tabela.fk_banco_tabela" label="Banco" required :disabled="mode === 'remove'"></v-autocomplete>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-row>
									<v-col>
										<v-btn color="primary" class="mr-1" v-if="mode === 'save'" @click="save">salvar</v-btn>
										<v-btn color="error" class="mr-1" v-else @click="remove">exluir</v-btn>
										<v-btn color="grey" class="ml-1" dark @click="reset">cancelar</v-btn>			
									</v-col>
								</v-row>
							</v-card-actions>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-card>
			<v-col>
				<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>
			<v-data-table :headers="table" :items="tabelas" :search="busca" class="elevation-1">
				<template v-slot:item.actions="{ item }">
					<v-btn x-small class="mb-2 mt-2" color="orange" @click="loadTabela(item, 'save')" block dark>editar</v-btn>
					<v-btn x-small class="mb-2 mt-2" color="error"  @click="loadTabela(item, 'remove')" block>excluir</v-btn>
				</template>
				<template v-slot:item.taxa="{ item }">
					{{ formatoTaxa(item.taxa) }}
				</template>
				<template v-slot:item.createdAt="{ item }">
					{{ formatoData(item.createdAt) }}
				</template>
			</v-data-table>
		</v-card>	
	</v-container>
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import moment from 'moment'
	export default {

		name: 'Tabela',

		data() {
			return {
				checked:false,
				busca:'',
				dialog:false,
				titleForm:'',
				mode:'save',
				tabelas:[],
				tabela:{},
				bancos:[],
				convenios:[],
				produtos:[],
				taxas:[],
				table:[
					{text:'Cod', value:'codigo_tabela'},
					{text:'Tabela', value:'nome_tabela'},
					{text:'Taxa %', value:'taxa'},
					{text:'Comissão', value:'comissao_tabela'},
					{text:'Banco', value:'nome_banco'},
					{text:'Criado em:', value:'createdAt'},
					{text:'Ações', value:'actions'},
					],
				perfil:[
					{text:'Digital', value:'Digital'},
					{text:'Físico', value:'Fisica'},
					{text:'Digital/Físico', value:'Digital-Fisica'},
					]
			};
		},
		methods: {
			loadTabelas() {
				axios.get(`${baseApiUrl}/tabelas`).then(res => {
					this.tabelas = res.data
				})
				.catch(showError)
			},
			loadBancos() {
				axios.get(`${baseApiUrl}/bancos`).then(res => {
					this.bancos = res.data
				})
				.catch(showError)
			},
			loadConvenios() {
				axios.get(`${baseApiUrl}/convenios`).then(res => {
					this.convenios = res.data
				})
				.catch(showError)
			},
			loadProdutos() {
				axios.get(`${baseApiUrl}/produtos`).then(res => {
					this.produtos = res.data
				})
				.catch(showError)
			},
			loadTabela(item, mode = 'save') {
				if(item) {
					const termo = item.nome_tabela
					const termoDividido= termo.split('/')
					item.convenio = termoDividido[0].trim()
					item.nome_produto = termoDividido[1].trim()
					item.nome_perfil = termoDividido[2].trim()
					item.referencia = termoDividido.length === 6 ? termoDividido[3].trim() : ''
					item.especie = termoDividido.length === 6 ? termoDividido[4].trim() : termoDividido[3].trim()
					item.taxa = termoDividido.length === 6 ? termoDividido[5].trim() : termoDividido[4].trim()
					this.checked = item.referencia ? true : false 
				}

				this.dialog = true,
				this.mode = mode
				this.tabela = { ...item }
				this.titleForm = item.id_tabela === undefined ? `Nova Tabela` :
				this.mode === 'remove' ? `Excluir Tabela ${ item.nome_tabela } ?` : `Editar Tabela - ${ item.nome_tabela}`
			},
			save() {
				this.tabela.taxa = this.tabela.taxa.replace(/,/g,'.')
				const metodo = this.tabela.id_tabela ? 'put' : 'post'
				const id = this.tabela.id_tabela ? `/${this.tabela.id_tabela}` : ''

				axios[metodo](`${baseApiUrl}/tabelas${id}`, this.tabela).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadTabelas()
					this.dialog = false
				})
				.catch(showError)
			},
			remove() {
				const id = this.tabela.id_tabela
				axios.delete(`${baseApiUrl}/tabelas/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialog = false
					this.loadTabelas()
					this.reset()
				})
				.catch(showError)
			},
			reset() {
				this.dialog = false
				this.tabela = {}
				this.checked = false
				this.loadTabelas()
			},
			formatoData(data) {
				if(data){
					return moment(data,"YYYY-MM-DD HH:mm").format("DD/MM/YYYY - HH:mm")
				}
			},
			formatoTaxa(item) {
				if(item) {
					return item.toString().replace('.',',')
				}
			}
		},
		mounted() {
			this.loadTabelas()
			this.loadConvenios()
			this.loadProdutos()
			this.loadBancos()
		}
	};
</script>

<style lang="css" scoped>
</style>
