<template>
	<div>
		<v-row justify="space-around">
			<v-menu bottom origin="center center" transition="slide-x-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-badge :content="msgs.length" :value="msgs.length" color="green" overlap>
						<v-icon v-bind="attrs" v-on="on" v-text="'mdi-bell'" :class="msgs.length > 0 ? 'bell' : ''" :color="msgs.length > 0 ? 'yellow' : ''"></v-icon>
					</v-badge>
				</template>

				<v-list>
					<v-list-item-group>
						<v-list-item v-for="(item,i) in msgs" :key="i">
							<v-list-item-content>
								<v-list-item-title @click.prevent="$router.push(item.to)" :class="`${item.color}--text`" class="font-weight-bold">
									{{ item.msg }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-menu>
		</v-row>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import Bus from '@/barramento'
	export default {

		name: 'Notification',

		data () {
			return {
				msgs:[],
				aniversarios:[],
				agendamentos:[]
			}
		},
		methods:{
			loadNotification() {
				this.msgs = []
				this.aniversarios = []
				this.agendamentos = []
				axios.get(`${baseApiUrl}/aniversarios`)
				.then(res => {
					if(res.data) {
						res.data.map(an => {
							if(an.id_usuario === this.user.id || this.user.cargo === 'Administrador' || this.user.cargo ==='Supervisor') {
								this.aniversarios.push(an)
							}
						})
						if(this.aniversarios.length > 0) {
							this.msgs.push({
								msg: `${this.aniversarios.length} Cliente(s) fazendo ANIVERSÁRIO Hoje!`,
								to:'/aniversariantes',
								color:'indigo'
							})
						}
					}
				})
				.catch(showError)

				axios.get(`${baseApiUrl}/agendamentos`)
				.then(res => {
					if(res.data) {
						res.data.agendamentosParaHoje.map(ag => {
							if(ag.id_usuario === this.user.id || this.user.cargo === 'Administrador' || this.user.cargo ==='Supervisor') {
								this.agendamentos.push(ag)
							}
						})
						if(this.agendamentos.length > 0) {
							this.msgs.push({
								msg: `${this.agendamentos.length} Cliente(s) AGENDADO(S) para HOJE ou AGENDAMENTO(S) VENCIDO(S)!`,
								to:'/agendamentos',
								color:'info'
							})
						}
					}
				})
				.catch(showError)
			}
		},
		computed: { ...mapState(['user'])},
		mounted() {
			this.loadNotification()
			Bus.$on('reload', () => {
				this.loadNotification()
			})
		}
	}
</script>

<style lang="css" scoped>
	.notify-dropdown {
		position: relative;
		height: 100%
	}

	.notify-button {
		display: flex;
		align-items: center;
		color: #fff;
		font-weight: 100;
		height: 100%;
		padding: 0px 20px;
	}

	.notify-dropdown:hover {
		background-color: rgba(0,0,0,0.2);
	}

	.notify-dropdown-content {
		position: absolute;
		right: 0px;
		background-color: #f9f9f9;
		min-width: 250px;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		padding: 10px;
		font-weight: bold;
		z-index: 1;

		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity 0.5s linear;
	}

	.notify-dropdown:hover .notify-dropdown-content {
		visibility: visible;
		opacity: 1;
	}

	.notify-dropdown-content a {
		text-decoration: none;
		color: #000;
		padding: 10px; 
	}

	.notify-dropdown-content a:hover {
		text-decoration: none;
		color: #000;
		background-color: #ededed;

	}

	.bell{
		font-size: 150%;
		color: 'yellow';
		-webkit-animation: ring 4s .7s ease-in-out infinite;
		-webkit-transform-origin: 50% 4px;
		-moz-animation: ring 4s .7s ease-in-out infinite;
		-moz-transform-origin: 50% 4px;
		animation: ring 4s .7s ease-in-out infinite;
		transform-origin: 50% 4px;
	}
	@-webkit-keyframes ring {
		0% { -webkit-transform: rotateZ(0); }
		1% { -webkit-transform: rotateZ(30deg); }
		3% { -webkit-transform: rotateZ(-28deg); }
		5% { -webkit-transform: rotateZ(34deg); }
		7% { -webkit-transform: rotateZ(-32deg); }
		9% { -webkit-transform: rotateZ(30deg); }
		11% { -webkit-transform: rotateZ(-28deg); }
		13% { -webkit-transform: rotateZ(26deg); }
		15% { -webkit-transform: rotateZ(-24deg); }
		17% { -webkit-transform: rotateZ(22deg); }
		19% { -webkit-transform: rotateZ(-20deg); }
		21% { -webkit-transform: rotateZ(18deg); }
		23% { -webkit-transform: rotateZ(-16deg); }
		25% { -webkit-transform: rotateZ(14deg); }
		27% { -webkit-transform: rotateZ(-12deg); }
		29% { -webkit-transform: rotateZ(10deg); }
		31% { -webkit-transform: rotateZ(-8deg); }
		33% { -webkit-transform: rotateZ(6deg); }
		35% { -webkit-transform: rotateZ(-4deg); }
		37% { -webkit-transform: rotateZ(2deg); }
		39% { -webkit-transform: rotateZ(-1deg); }
		41% { -webkit-transform: rotateZ(1deg); }

		43% { -webkit-transform: rotateZ(0); }
		100% { -webkit-transform: rotateZ(0); }
	}

	@-moz-keyframes ring {
		0% { -moz-transform: rotate(0); }
		1% { -moz-transform: rotate(30deg); }
		3% { -moz-transform: rotate(-28deg); }
		5% { -moz-transform: rotate(34deg); }
		7% { -moz-transform: rotate(-32deg); }
		9% { -moz-transform: rotate(30deg); }
		11% { -moz-transform: rotate(-28deg); }
		13% { -moz-transform: rotate(26deg); }
		15% { -moz-transform: rotate(-24deg); }
		17% { -moz-transform: rotate(22deg); }
		19% { -moz-transform: rotate(-20deg); }
		21% { -moz-transform: rotate(18deg); }
		23% { -moz-transform: rotate(-16deg); }
		25% { -moz-transform: rotate(14deg); }
		27% { -moz-transform: rotate(-12deg); }
		29% { -moz-transform: rotate(10deg); }
		31% { -moz-transform: rotate(-8deg); }
		33% { -moz-transform: rotate(6deg); }
		35% { -moz-transform: rotate(-4deg); }
		37% { -moz-transform: rotate(2deg); }
		39% { -moz-transform: rotate(-1deg); }
		41% { -moz-transform: rotate(1deg); }

		43% { -moz-transform: rotate(0); }
		100% { -moz-transform: rotate(0); }
	}

	@keyframes ring {
		0% { transform: rotate(0); }
		1% { transform: rotate(30deg); }
		3% { transform: rotate(-28deg); }
		5% { transform: rotate(34deg); }
		7% { transform: rotate(-32deg); }
		9% { transform: rotate(30deg); }
		11% { transform: rotate(-28deg); }
		13% { transform: rotate(26deg); }
		15% { transform: rotate(-24deg); }
		17% { transform: rotate(22deg); }
		19% { transform: rotate(-20deg); }
		21% { transform: rotate(18deg); }
		23% { transform: rotate(-16deg); }
		25% { transform: rotate(14deg); }
		27% { transform: rotate(-12deg); }
		29% { transform: rotate(10deg); }
		31% { transform: rotate(-8deg); }
		33% { transform: rotate(6deg); }
		35% { transform: rotate(-4deg); }
		37% { transform: rotate(2deg); }
		39% { transform: rotate(-1deg); }
		41% { transform: rotate(1deg); }

		43% { transform: rotate(0); }
		100% { transform: rotate(0); }
	}
</style>