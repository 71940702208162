<template>
	<v-container>
		<v-dialog
		v-model="data.modal"
		max-width="500"
		transition="dialog-transition"
		@click:outside="closeDialog"
		>
		<v-card>
			<v-form @submit.stop.prevent>
				<v-card-title>
					Agendamento Cliente <br>
				</v-card-title>
				<v-card-text>
					<v-col md="6" sm="12">
						<v-text-field
						type="date"
						v-model="agendamento.data_agendamento"
						label="Data Agendamento"
						></v-text-field>
					</v-col>
					<v-col>
						<v-textarea
						v-model="agendamento.informacao_agendamento"
						label="Observação"
						outlined
						></v-textarea>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" @click="saveAg">salvar</v-btn>
					<v-btn color="grey" dark @click="closeDialog()">cancelar</v-btn>
					<!-- <v-btn color="error" v-show="agendamento">excluir</v-btn> -->
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</v-container>
</template>

<script>
import axios from "axios";
import { baseApiUrl, showError } from "@/global";
import Bus from '@/config/bus'
export default {

	name: 'DialogAgendamento',
	props:{
		data:{},
	},
	data () {
		return {
			agendamento:{},
			telemarketing:[],
			telemark:{}
		}
	},
	methods:{
		saveAg() {
			var id = this.data.cliente.id_telemarketing
			this.agendamento.fk_cliente = this.data.cliente.fk_cliente
			this.telemark.status ='agendado'
			this.telemark.fk_cliente = this.data.cliente.fk_cliente
			axios.put(`${baseApiUrl}/telemarketing/${id}`,this.telemark)
			.then()
			.catch(showError)
			axios
			.post(`${baseApiUrl}/agendamentos`, this.agendamento)
			.then(() => {
				this.$toasted.global.defaultSuccess();
				this.reset()
				Bus.$emit('reload')
			})
			.catch(showError)
		},
		reset() {
			this.agendamento = {};
			this.data.modal = false
		},
		closeDialog() {
			this.reset()
		}
	},
	mounted() {
		
	}
}
</script>

<style lang="css" scoped>
</style>