<template>
	<v-container>
		<PageTitle icon="mdi mdi-home" main="Receitas e Despesas" sub=""/>
		<v-col class="font-weight-bold">
			{{ mesAtual }}
		</v-col>
		<v-col>
			<v-row>
				<v-col class="pb-2">
					<v-card>
						<v-row class="no-gutters">
							<div class="col-auto">
								<div class="success fill-height">&nbsp;</div>
							</div>
							<div class="col pa-3 py-4 success--text">
								<h5 class="text-truncate text-uppercase">Receitas</h5>
								<h1><v-icon color="success">mdi-plus</v-icon> {{ formatoMoeda(total_receitas) || `R$ 0,00` }}</h1>
								<small class="grey--text font-weight-bold"><v-icon small color="success">mdi-arrow-up</v-icon></small>
							</div>
						</v-row>
					</v-card>
				</v-col>
				<v-col class="pb-2">
					<v-card>
						<v-row class="no-gutters">
							<div class="col-auto">
								<div class="error fill-height">&nbsp;</div>
							</div>
							<div class="col pa-3 py-4 error--text">
								<h5 class="text-truncate text-uppercase">Despesas</h5>
								<h1><v-icon color="error">mdi-minus</v-icon> {{ formatoMoeda(total_despesas) || `R$ 0,00` }}</h1>
								<small class="grey--text font-weight-bold"><v-icon small color="error">mdi-arrow-down</v-icon></small>
							</div>
						</v-row>
					</v-card>
				</v-col>
				<!-- <v-col lg="3" cols="sm" class="pb-2">
					<v-card>
						<v-row class="no-gutters">
							<div class="col-auto">
								<div class="orange fill-height">&nbsp;</div>
							</div>
							<div class="col pa-3 py-4 orange--text">
								<h5 class="text-truncate text-uppercase">Pendentes</h5>
								<h1>* R$ 0,00</h1>
								<small class="grey--text font-weight-bold"><v-icon small color="orange">mdi-clock-alert-outline</v-icon></small>
							</div>
						</v-row>
					</v-card>
				</v-col> -->
				<v-col class="pb-2">
					<v-card :color="liquido < 0 ? `error` : `success`">
						<v-row class="no-gutters">
							<div class="col-auto">
								<div class="white fill-height">&nbsp;</div>
							</div>
							<div class="col pa-3 py-4 white--text">
								<h5 class="text-truncate text-uppercase">Total</h5>
								<h1>{{ formatoMoeda(liquido) || `R$ 0,00`}} </h1>
								<small class="grey--text font-weight-bold"><v-icon small color="white">mdi-currency-usd</v-icon></small>
							</div>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
		<v-toolbar dense flat>
			<template>
				<v-tabs v-model="tab" align-with-title color="error">
					<v-tabs-slider color="red"></v-tabs-slider>
					<v-tab v-for="item in items" :key="item" class="font-weight-bold">
						{{ item }}
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-card>
			<v-card-text>
				<!-- <v-row>
					<v-col>
						<v-btn class="ml-1" x-small dark color="dark">todas</v-btn>
						<v-btn class="ml-1" x-small dark color="orange">pendentes</v-btn>
						<v-btn class="ml-1" x-small dark color="success">recebidas</v-btn>
						<v-btn class="ml-1" x-small dark color="error">atrasadas</v-btn>
					</v-col>
				</v-row> -->
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-card flat>
							<v-card-title>Receitas</v-card-title>
							<v-card-text>				
								<v-col sm="2">
									<v-btn x-small color="primary" @click="loadFinanceiro('', 'save')">Novo Lançamento</v-btn>
								</v-col>
								<DataTable :items="receitas"/>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card flat>
							<v-card-title>Despesas</v-card-title>
							<v-card-text>
								<v-col sm="2">
									<v-btn x-small color="primary" @click="loadFinanceiro('', 'save')">Novo Lançamento</v-btn>
								</v-col>			
								<DataTable :items="despesas"/>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card flat>
							<v-card-title>Consumidoras</v-card-title>
							<v-card-text>
								<v-col sm="2">
									<v-btn x-small color="primary" @click="dialogCon = true">Nova Consumidora</v-btn>
								</v-col>			
								<v-data-table :headers="tabCon" :items="consumidoras">
									<template v-slot:item.actions="{ item }">
										<v-btn x-small color="orange" class="mr-1 ml-1" @click="loadConsumidora(item, 'save')">editar</v-btn>
										<v-btn x-small color="error" class="mr-1 ml-1" @click="loadConsumidora(item, 'remove')">excluir</v-btn>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<v-tab-item>
						<v-card flat>
							<v-card-title>Categorias</v-card-title>
							<v-card-text>
								<v-col sm="2">
									<v-btn x-small color="primary" @click="dialogCat = true">Nova Categoria</v-btn>
								</v-col>			
								<v-data-table :headers="tabCat" :items="categorias">
									<template v-slot:item.actions="{ item }">
										<v-btn x-small color="orange" class="mr-1 ml-1" @click="loadCategoria(item, 'save')">editar</v-btn>
										<v-btn x-small color="error" class="mr-1 ml-1" @click="loadCategoria(item, 'remove')">excluir</v-btn>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-tab-item><v-tab-item>
						<v-card flat>
							<v-card-title>Categorias</v-card-title>
							<v-card-text>
								<v-data-table :headers="tabBanco" :items="bancos">
									<template v-slot:item.saldo="{ item }">
										{{formatoMoeda(item.saldo)}}
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
		<v-dialog v-model="dialog" max-width="65%">
			<v-card>
				<v-card-title>{{ title }}</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col sm="4">
								<v-text-field type="date" label="Data" v-model="transacao.data" :disabled="modo === 'remove'"></v-text-field>
							</v-col>
							<v-col sm="4">
								<v-text-field type="text" label="Descrição" v-model="transacao.descricao" :disabled="modo === 'remove'"></v-text-field>
							</v-col>
							<v-col sm="4">
								<v-select :items="consumidoras" item-text="nome" item-value="consumidora_id" label="Fonte Consumidora" v-model="transacao.consumidora_id" :disabled="modo === 'remove'"></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col sm="4">
								<v-currency-field label="Valor" type="text" size="sm" v-model="transacao.valor" :disabled="modo === 'remove'"></v-currency-field>
							</v-col>
							<v-col sm="4">
								<v-autocomplete label="Categoria" :items="categorias" item-text="descricao" item-value="categoria_id" v-model="transacao.categoria_id" :disabled="modo === 'remove'"></v-autocomplete>
							</v-col>
							<v-col>
								<v-text-field type="text" label="Financeira" v-model="transacao.financeira" :disabled="modo === 'remove'"></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-col>
						<v-btn class="mr-1" color="info" @click="save" v-if="modo === 'save'">Salvar</v-btn>
						<v-btn class="mr-1" color="error" @click="remove(transacao.transacao_id)" v-else>Excluir</v-btn>
						<v-btn color="grey lighten-1" dark @click="reset">Cancelar</v-btn>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogCon" max-width="500" transition="dialog-transition">
			<v-card>
				<v-card-title>
					{{ title }}
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col>
							<v-text-field label="Nome da Consumidora" v-model="consumidora.nome" :disabled="modo === 'remove'"></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn small color="info" v-if="modo === 'save'" @click="saveCon">Salvar</v-btn>
					<v-btn small color="error" v-else @click="removeCon">Excluir</v-btn>
					<v-btn small color="grey" @click="reset">cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogCat" max-width="500" transition="dialog-transition">
			<v-card>
				<v-card-title>
					{{ title }}
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col>
							<v-text-field label="Nome da Categoria" v-model="categoria.descricao" :disabled="modo === 'remove'"></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn small color="info" v-if="modo === 'save'" @click="saveCat">Salvar</v-btn>
					<v-btn small color="error" v-else @click="removeCat">Excluir</v-btn>
					<v-btn small color="grey" @click="reset">cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import PageTitle from '@/templates/PageTitle'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import DataTable from '@/components/admin/DataTable'
	import moment from 'moment'
	import Bus from '@/config/bus'
	export default {
		components:{ PageTitle, DataTable },
		name: 'ReceitasDespesas',

		data () {
			return {
				mesAtual:{},
				modo:'save',
				liquido:0.00,
				despesas:[],
				receitas:[],
				tab:null,
				transacao:{},
				total_despesas:0.00,
				total_receitas:0.00,
				title:'',
				dialogCat:false,
				dialogCon:false,
				dialog:false,
				categoria:{},
				consumidora:{},
				consumidoras:[],
				items:['Receitas','Despesas','Consumidoras','Categorias','Bancos'],
				categorias:[],
				tabCat:[
					{text:'ID', value:'categoria_id'},
					{text:'Descrição', value:'descricao'},
					{text:'Ações', value:'actions'},
					],
				tabCon:[
					{text:'ID', value:'consumidora_id'},
					{text:'Nome', value:'nome'},
					{text:'Ações', value:'actions'},
					],
				tabBanco:[
					{text:'ID', value:'banco_id'},
					{text:'Nome', value:'nome'},
					{text:'Saldo', value:'saldo'},
					],
				bancos:[
					{banco_id:1,nome:'Bradesco', saldo:54014.82},
					{banco_id:2,nome:'Santander', saldo:2769.97},
					{banco_id:3,nome:'BMG', saldo:12181.34},
					{banco_id:4,nome:'Itau', saldo:10370.71},
					{banco_id:5,nome:'Especie', saldo:1914.80}
					]
			}
		},
		methods:{	
			loadReceitasDespesas() {
				axios.get(`${baseApiUrl}/financeiro`).then(res => {
					this.despesas =res.data.despesas
					this.receitas = res.data.receitas
					this.total_despesas = res.data.total_despesas.total
					this.total_receitas = res.data.total_receitas.total
					this.liquido = res.data.liquido
					moment.locale('pt-br')
					this.mesAtual = moment().format('MMMM').toUpperCase()
					res.data.despesas.map(d => {
					const banco = this.bancos.find(b => b.nome.toUpperCase()  === d.financeira.toUpperCase())
					if(banco) {
						banco.saldo -= d.valor

					} else {
						this.$toasted.alertMessage({msg:'Banco não encontrado'})
					}

					})
					res.data.receitas.map(r => {
					const banco = this.bancos.find(b => b.nome.toUpperCase()  === r.financeira.toUpperCase())
					if(banco) {
						banco.saldo += r.valor

					} else {
						this.$toasted.alertMessage({msg:'Banco não encontrado'})
					}

					})

				})
				.catch(showError)
			},
			loadFinanceiro(item, mode = 'save') {
				this.dialog = true
				this.modo = mode
				this.transacao.tipo = this.tab === 0 ? 'RECEITA' : 'DESPESA'
				if(item){
					item.data  = moment().format('YYYY-MM-DD')
					this.transacao = {...item }
					this.title = mode === 'save' ? 'Editar Lançamento' : mode === 'remove' ? 'Excluir Lançamento ?' : 'Novo Lançamento'
				}
			},
			loadCategorias() {
				axios.get(`${baseApiUrl}/financeiro`)
				.then(res => {
					this.categorias = res.data.categorias
				})
				.catch(showError)
			},
			loadCategoria(item, mode = 'save') {
				
				this.dialogCat = true
				this.modo = mode
				if(item) {
					this.categoria = { ...item }
				}
			},
			async loadConsumidoras() {
				const res = await axios.get(`${baseApiUrl}/financeiro`)
				try{
					this.consumidoras = res.data.consumidoras
				} catch(err) {
					showError(err)
				}
			},
			loadConsumidora(item, mode = 'save') {
				this.dialogCon = true
				this.modo = mode
				if(item) {
					this.consumidora = { ...item }
				}
			},
			saveCat() {
				const metodo = this.categoria.categoria_id ? 'put' : 'post'
				const id = this.categoria.categoria_id ? `/${this.categoria.categoria_id}` : ''

				axios[metodo](`${baseApiUrl}/categorias${id}`, this.categoria).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadCategorias()
					this.dialogCat = false
				})
				.catch(showError)
			},
			saveCon() {
				const metodo = this.consumidora.consumidora_id ? 'put' : 'post'
				const id = this.consumidora.consumidora_id ? `/${this.consumidora.consumidora_id}` : ''

				axios[metodo](`${baseApiUrl}/consumidoras${id}`, this.consumidora).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadConsumidoras()
					this.dialogCon = false
				})
				.catch(showError)
			},
			removeCat() {
				const id = this.categoria.categoria_id
				axios.delete(`${baseApiUrl}/categorias/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialogCat = false
					this.reset()
				})
			},
			removeCon() {
				const id = this.consumidora.consumidora_id
				axios.delete(`${baseApiUrl}/consumidoras/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialogCon = false
					this.reset()
				})
			},
			async save() {
				try {
					await axios.post(`${baseApiUrl}/financeiro`, this.transacao)

					this.$toasted.global.defaultSuccess()
					this.loadReceitasDespesas()
					this.dialog = false
					this.reset

				} catch(err){
					showError(err)
				}
			},
			async remove(item) {
				try {	
					await axios.delete(`${baseApiUrl}/financeiro/${item}`)
					this.$toasted.global.defaultSuccess()
					this.reset()
				} catch(err) {
					showError(err)
				}
			},
			reset() {
				this.dialog = false
				this.transacao = {}
				this.loadReceitasDespesas()
				this.dialogCat = false
				this.dialogCon = false
				this.categoria = {},
				this.consumidora = {},
				this.loadCategorias()
				this.loadConsumidoras()
			},
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			},
		},
		mounted() {
			this.loadCategorias()
			this.loadConsumidoras()
			this.loadReceitasDespesas()
			Bus.$on('loadFinanceiro', (item) =>{
				this.loadFinanceiro(item.item, item.modo)
			})
		}
	}
</script>

<style lang="css" scoped>
</style>