<template>
	<v-container>
		<PageTitle icon="mdi mdi-chart-bar" main="Lembretes" sub="" />
		<DialogNotas/>
		<v-data-table :headers="table" :items="lembretes">
			<template v-slot:item.data_lembrete ="{ item }">
				<v-flex>{{ formatoData(item.data_lembrete) }}</v-flex>	
				<v-flex class="info--text font-weight-bold">
					<small>{{ item.nome }}</small>
				</v-flex>	
			</template>
			<template v-slot:item.nome_cliente ="{ item }">
				<v-flex>{{ item.nome_cliente }}</v-flex>
				<v-flex>
					<small class="font-weight-bold">{{ item.cpf_cliente }}</small>
				</v-flex>
			</template>
			<template v-slot:item.createdAt ="{ item }">
				{{ formatoData(item.createdAt) }}
			</template>
			<template v-slot:item.updatedAt ="{ item }">
				{{ formatoData(item.updatedAt) }}
			</template>
			<template v-slot:item.actions ="{ item }">
				<v-btn class="mt-1 mb-1" color="orange" @click="loadLembrete(item,'save')" dark x-small block>editar</v-btn>
				<v-btn class="mt-1 mb-1" color="info" @click="loadLembrete(item,'remove')" dark x-small block>concluir</v-btn>
			</template>
		</v-data-table>
	</v-container>

</template>

<script>
	import PageTitle from '@/templates/PageTitle'
	import DialogNotas from '../clientes/modals/DialogNotas'
	import { baseApiUrl, showError } from '@/global'
	import axios from 'axios'
	import moment from 'moment'
	import Bus from '@/barramento'
	import { mapState } from 'vuex'
	export default {

		name: 'Notas',
		components: { PageTitle, DialogNotas },
		data () {
			return {
				lembretes:[],
				table:[
					{text:'Data', value:'data_lembrete'},
					{text:'Cliente', value:'nome_cliente'},
					{text:'Lembrete', value:'nome_lembrete'},
					{text:'Descrição', value:'descricao_lembrete'},
					{text:'Criado em', value:'createdAt'},
					{text:'Atualizado em', value:'updatedAt'},
					{text:'Ações', value:'actions'},
					]
			}
		},
		computed:{
			...mapState(["user"]),
		},
		methods:{
			async loadLembretes() {
				try {
					const res = await axios.get(`${baseApiUrl}/lembretes`)
					res.data.lembretes.map(l => {
						if(l.fk_usuario === this.user.id || this.user.cargo === 'Administrador' || this.user.cargo === 'Supervisor' || this.user.cargo === 'Financeiro') {
							this.lembretes.push(l)
						}
					})

				} catch(err) {
					showError(err)
				}
			},
			loadLembrete(item, modo) {
				Bus.$emit('loadLembrete', { item, modo })
			},
			formatoData(data){
				if(data){
					return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
				}
			},
		},
		mounted() {
			this.loadLembretes()
			Bus.$on('loadLembretes', () => {
				this.lembretes = []
				this.loadLembretes()
			})
		}
	}
</script>

<style lang="css" scoped>
</style>