<template>
	<v-container>
		<v-dialog v-model="dialog" max-width="400">
			<template v-slot:activator="{ on }">
				<v-col md="6" sm="12">
					<v-btn @click="loadBanco('', 'save')" x-small color="error" v-on="on" outlined>Novo banco</v-btn>
				</v-col>
			</template>
			<v-card>
				<v-card-title primary-title>
					{{ titleForm }}
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form>
							<v-row>
								<v-col>		
									<v-text-field v-model="banco.codigo_banco" label="Código Banco" required :disabled="mode === 'remove'" :autofocus="true" @keydown.enter.prevent="save"></v-text-field>
								</v-col>
								<v-col>		
									<v-text-field v-model="banco.nome_banco" label="Banco" required :disabled="mode === 'remove'" @keydown.enter.prevent="save"></v-text-field>
								</v-col>
							</v-row>
							<v-card-actions>
								<v-row>
									<v-col>
										<v-btn color="primary" class="mr-1" v-if="mode === 'save'" @click="save">salvar</v-btn>
										<v-btn color="error" class="mr-1" v-else @click="remove">exluir</v-btn>
										<v-btn color="grey" class="ml-1" dark @click="reset">cancelar</v-btn>			
									</v-col>
								</v-row>
							</v-card-actions>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-card>
			<v-col>
				<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>
			<v-data-table :headers="table" :items="bancos" class="elevation-1" :search="busca" :loading="!bancos.length"
			loading-text="Carregando... Espere por favor!">
			<template v-slot:item.data-table-expand="{ expand, isExpanded }">
				<v-icon @click="expand(!isExpanded)" color="blue" small>mdi-eye</v-icon>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn x-small class="mr-1 ml-1" color="orange" @click="loadBanco(item, 'save')" dark>editar</v-btn>
				<v-btn x-small class="mr-1 ml-1" color="error" @click="loadBanco(item, 'remove')">excluir</v-btn>
			</template>
			<template v-slot:item.createdAt="{ item }">
				{{ formatoData(item.createdAt) }}
			</template>
		</v-data-table>
	</v-card>	
</v-container>
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import moment from 'moment'
	export default {
		name: 'Bancos',
		data() {
			return {
				busca:'',
				expanded: [],
				dialog:false,
				dialog_produto:false,
				titleForm:'',
				mode:'save',
				bancos:[],
				banco:{},
				table:[
					{text:'Código', value:'codigo_banco'},
					{text:'Banco', value:'nome_banco'},
					{text:'Criado em:', value:'createdAt'},
					{text:'Ações', value:'actions'},
					]
			};
		},
		methods: {
			loadBancos() {
				axios.get(`${baseApiUrl}/bancos`).then(res => {
					this.bancos = res.data
				})
				.catch(showError)
			// this.loadProdutos()
			},
			loadBanco(item, mode = 'save') {
				this.dialog = true,
				this.mode = mode
				this.banco = { ...item }
				this.titleForm = item.id_banco === undefined ? `Novo Banco` :
				this.mode === 'remove' ? `Excluir Banco ${ item.nome_banco } ?` : `Editar Banco - ${ item.nome_banco}`
			},
			save() {
				const metodo = this.banco.id_banco ? 'put' : 'post'
				const id = this.banco.id_banco ? `/${this.banco.id_banco}` : ''

				axios[metodo](`${baseApiUrl}/bancos${id}`, this.banco).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadBancos()
					this.dialog = false
				})
				.catch(showError)
			},
			remove() {
				const id = this.banco.id_banco
				axios.delete(`${baseApiUrl}/bancos/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess()
					this.dialog = false
					this.loadBancos()
					this.reset()
				})
			},
			reset() {
				this.dialog = false
				this.banco = [] 
			},
			formatoData(data) {
				return moment(data,"YYYY-MM-DD HH:mm").format("DD/MM/YYYY")
			}
		},
		mounted() {
			this.loadBancos()
		}
	};
</script>

<style lang="css" scoped>
</style>
