<template>
  <v-app>
    <Loading v-if="validatingToken"/>
    <Content v-else/>
    <Header v-if="user"/>
    <!-- <Chat :data="data" v-if="user"/> -->
    <!-- <v-fab-transition>
      <v-btn
      v-if="user"
      fab
      small
      dark
      bottom
      right
      fixed
      class="v-btn--example info"
      @click="openChat"
      >
      <v-badge left color="error" :content="'!'" :value="icon">
        <v-icon>mdi-chat</v-icon>
      </v-badge>
    </v-btn>
  </v-fab-transition> -->
</v-app>
</template>

<script>
  import axios from 'axios'
  import { baseApiUrl, userKey } from '@/global'
  import { mapState } from 'vuex'
  import Header from '@/templates/Header'
  import Loading from '@/templates/Loading'
  import Content from '@/templates/Content'
// import Chat from '@/components/chat/Chat'
  import Bus from '@/barramento'
  import audio from '@/audio/89780465.mp3'
  export default {
    name: 'App',
  components: { Header, Content, Loading /*Chat*/ },
    computed: mapState(['user']),
    data() {
      return {
        data:{},
        validatingToken:true,
        icon:false,
        text: 'Lorem ipsum dolor sit amet',
        vertical: true,
        bday:[]
      }
    },
    methods: {
    // openChat() {
    //   this.data = {
    //     modal:true
    //   }
    //   this.icon = false
    // },
      async validateToken() {
        this.validatingToken = true

        const json = localStorage.getItem(userKey)
        const userData = JSON.parse(json)
        this.$store.commit('setUser', null)

        if(!userData) {
          this.validatingToken = false
          this.$router.push({ name: 'auth'}).catch(() => {})
          return
        }

        const res = await axios.post(`${baseApiUrl}/validateToken`, userData)
        if(res.data) {
          this.$store.commit('setUser', userData)
        } else {
          localStorage.removeItem(userKey)
          this.$router.push({ name: 'auth' }).catch(() => {})
        }

        this.validatingToken = false
      }
    },
    mounted() {
      Bus.$on('notify', data => {
        this.icon = data.value
        const track = audio
        let sound = new Audio(track)
        window.onload = sound.play()
      })
      this.validateToken()
      document.addEventListener('keydown', (e) => {
        if (e.key === 'PrintScreen' || e.key === 'Insert') {
          e.preventDefault();
          this.$toasted.global.defaultError({ msg:'Captura de tela desabilitada!'})

        }
      });
    }
  };
</script>
