<template>
	<v-dialog v-model="dialogContrato" fullscreen>
		<v-card>
			<v-card-title>{{ contratoTitle }}</v-card-title>
			<v-card-text>
				<v-form @submit.stop.prevent>
					<v-row>
						<v-col md="4" sm="12">
							<v-text-field
							label="Matrícula"
							v-model="contrato.matricula_contrato"
							type="number"
							size="sm"
							:disabled="mode === 'remove'"
							:rules="requerido"

							></v-text-field>
						</v-col>
						<v-col md="4" sm="12">
							<v-text-field
							label="Número Contrato"
							v-model="contrato.numero_contrato"
							type="number"
							size="sm"
							:disabled="mode === 'remove'"
							:rules="requerido"

							></v-text-field>
						</v-col>
						<v-col md="2" sm="12">
							<v-text-field
							label="Data Contrato"
							v-model="contrato.data_contrato"
							type="date"
							size="sm"
							:disabled="mode === 'remove'"
							:rules="requerido"

							></v-text-field>
						</v-col>
						<v-col md="2" sm="12">
							<v-currency-field
							label="Valor Contrato"
							v-model="contrato.valor_contrato"
							type="text"
							size="sm"
							:disabled="mode === 'remove'"
							:rules="valorReq"

							></v-currency-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="2" sm="12">
							<v-autocomplete
							:items="bancos"
							item-text="nome_banco"
							item-value="nome_banco"
							v-model="contrato.banco_contrato"
							label="Banco"
							required
							:disabled="mode === 'remove'"
							:rules="requerido"
							@input="loadTabelas()"
							></v-autocomplete>
						</v-col>
						<v-col md="4" sm="12">
							<v-autocomplete
							:items="tabelas"
							item-text="nome_tabela"
							item-value="nome_tabela"
							v-model="contrato.tabela_contrato"
							label="Tabela"
							required
							:disabled="mode === 'remove'"
							:rules="requerido"
							@change="atualizarTaxa"
							></v-autocomplete>
						</v-col>
						<v-col>
							<v-text-field label="Taxa" v-model="contrato.taxa" :disabled="disabled"></v-text-field>
						</v-col>
						<v-col md="3" sm="12">
							<v-select
							:items="produtos"
							v-model="contrato.produto_contrato"
							item-text="nome_produto"
							item-value="nome_produto"
							label="Produto"
							:disabled="mode === 'remove'"
							:rules="requerido"
							></v-select>
						</v-col>
						<v-col md="2" sm="12">
							<v-select
							:items="convenios"
							v-model="contrato.convenio_contrato"
							item-text="nome_convenio"
							label="Convênio"
							:disabled="mode === 'remove'"
							:rules="requerido"
							></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="2" sm="12">
							<v-text-field
							type="number"
							v-model="contrato.prazo_contrato"
							label="Prazo"
							:disabled="mode === 'remove'"
							:rules="requerido"
							></v-text-field>
						</v-col>
						<v-col md="3" sm="12">
							<v-select
							:items="pagamentos"
							v-model="contrato.tipo_pagamento"
							label="Tipo Pagamento"
							:disabled="mode === 'remove'"
							:rules="requerido"
							></v-select>
						</v-col>
						<v-col md="3">
							<v-select
							:items="formalizacao"
							v-model="contrato.formalizacao"
							label="Tipo Formalizacão"
							:disabled="mode === 'remove'"
							require
							:rules="requerido"
							></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-textarea
							label="Informações"
							outlined
							v-model="contrato.informacoes"
							:disabled="mode === 'remove'"

							></v-textarea>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-col md="12" sm="12" class="text-center">
					<v-btn
					color="primary"
					@click="saveContrato"
					v-show="mode === 'save'"
					>salvar</v-btn
					>
					<v-btn
					color="error"
					@click="removeContrato"
					v-show="mode === 'remove'"
					>excluir</v-btn
					>
					<v-btn color="grey" class="ml-2" dark @click="reset"
					>cancelar</v-btn
					>
				</v-col>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { validationMixin } from "vuelidate";
	import { required } from "vuelidate/lib/validators";
	import { mapState } from "vuex";
	import { cpf } from "cpf-cnpj-validator";
	import axios from "axios";
	import { baseApiUrl, showError } from "@/global";
	// import moment from "moment";
	import Bus from '@/barramento'
	export default {
		name: 'DialogContrato',

		data () {
			return {
				itemcpf:'',
				disabled:true,
				dialogContrato:false,
				mode:'save',
				contrato:{},
				contratoTitle:'',
				mixins: [validationMixin],
				validations: {
					client: {
						cpf_cliente: { required },
					},
				},
				requerido: [(v) => !!v || "*"],
				valorReq: [(v) => !!v || v == 0 || "*"],
				tabelas:[],
				bancos:[],
				produtos:[],
				convenios:[],
				prazos:[],
				pagamentos: [{ text: "OP", value: "OP" },{ text: "CONTA", value: "CONTA" }],
				formalizacao: [{ text: "Digital", value: "Digital" },{ text: "Física", value: "Fisica" }]
			}
		},
		methods:{
			loadContrato(item, mode ='save') {
				this.loadBancos()
				this.loadProdutos()
				this.loadConvenios()
				this.loadPrazos()
				this.mode = mode
				this.dialogContrato =true
				this.contrato.fk_cliente =  item
				this.contratoTitle = 'Contrato'
			},
			loadTabelas() {
				this.tabelas = []
				axios
				.get(`${baseApiUrl}/tabelas`)
				.then((res) => {
					res.data.map(t => {
						console.log(t)
						if(this.contrato.banco_contrato && t.nome_banco === this.contrato.banco_contrato){
							this.tabelas.push(t)
						}
					})
				})
				.catch(showError);
			},
			loadBancos() {
				axios
				.get(`${baseApiUrl}/bancos`)
				.then((res) => {
					this.bancos = res.data;
				})
				.catch(showError);
			},
			loadProdutos() {
				axios
				.get(`${baseApiUrl}/produtos`)
				.then((res) => {
					this.produtos = res.data;
				})
				.catch(showError);
			},
			loadConvenios() {
				axios
				.get(`${baseApiUrl}/convenios`)
				.then((res) => {
					this.convenios = res.data;
				})
				.catch(showError);
			},
			loadPrazos() {
				axios
				.get(`${baseApiUrl}/prazos`)
				.then((res) => {
					this.prazos = res.data;
				})
				.catch(showError);
			},
			loadAgendamentos() {
				axios
				.get(`${baseApiUrl}/agendamentos`)
				.then((res) => {
					this.agendamentos = res.data.agendamentosDB;
				})
				.catch(showError);
			},
			saveContrato() {
				this.trimAllInputValues()
				const metodo = this.contrato.id_contrato ? "put" : "post";
				this.contrato.fk_usuario =
				metodo === "post" ? this.user.id : this.contrato.fk_usuario;
				const id = this.contrato.id_contrato
				? `/${this.contrato.id_contrato}`
				: "";
				this.contrato.ativo =
				this.contrato.formalizacao === "digital" ? false : true;
				axios[metodo](`${baseApiUrl}/contratos${id}`, this.contrato)
				.then(() => {
					this.$toasted.global.defaultSuccess();
					this.dialogContrato = false;
					this.reset();
					Bus.$emit('clienteCpf', this.itemcpf)
					// this.$router.push('/esteira')
				})
				.catch(showError);
			},
			removeContrato() {
				const id = this.contrato.id_contrato;
				axios
				.delete(`${baseApiUrl}/contratos/${id}`)
				.then(() => {
					this.$toasted.global.defaultSuccess();
					this.dialogContrato = false;
					this.loadContratos(this.contrato.fk_cliente);
					this.reset();
				})
				.catch(showError);
			},
			reset() {
				this.contrato = {};
				this.dialogContrato = false;
			},
			trimAllInputValues() {
				for (const key in this.contrato) {
					if (typeof this.contrato[key] === 'string') {
						this.contrato[key] = this.contrato[key].trim();
					}
				}
			},
			atualizarTaxa() {
				this.contrato.taxa = null
				const tab = []
				this.tabelas.map(t =>{
					const regex = /REFIN|PORT|RFN|CARTAO|SAQUE|ATIVACAO|COMPLEMENTAR/i
					if(this.contrato.tabela_contrato === t.nome_tabela){
						if (regex.test(t.nome_tabela)) {
							if(this.contrato.banco_contrato === t.nome_banco) {
								tab.push(t)
							}	

							this.disabled = false
						} else {
							if(this.contrato.banco_contrato === t.nome_banco) {
								tab.push(t)
							}
							this.contrato.taxa = t.taxa
							this.disabled = true
						}
					}
				})
				this.contrato.bancoTabela = tab
			}
		},
		computed:{
			...mapState(["user"]),
			validationCpf() {
				const errors = [{msg:"", color:"error", status:true}];
				if (this.client.cpf_cliente){
					cpf.isValid(this.client.cpf_cliente)
					? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
				}
				if (this.cpf){
					cpf.isValid(this.cpf) ? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
				}
				return errors;
			},
			// tabelaBanco() {
			// 	var tab = [];
			// 	this.tabelas.map(t => {
			// 			if(this.contrato.banco_contrato === t.nome_banco) {
			// 				tab.push(t)
			// 			}	
			// 	})
			// 	return tab;
			// }
		},
		mounted() {
			Bus.$on('loadContrato', dados => {
				this.itemcpf = dados.item.cpf_cliente
				this.loadContrato(dados.item.id_cliente,dados.item.mode)
			})
		}
	}
</script>

<style lang="css" scoped>
</style>