<template>
	<v-container>
		<v-dialog
		persistent
		v-model="data.modal"
		max-width="500"
		transition="dialog-transition"
		@click:outside="closeDialog"
		>
		<v-card>
			<v-form @submit.stop.prevent>
				<v-card-title>Justificativas </v-card-title>
				<v-card-text>
					<v-col>
						<v-textarea
						v-model="text.observacoes"
						label="Justificativas"
						outlined
						></v-textarea>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" @click="save">salvar</v-btn>
					<v-btn color="grey" dark @click="reset">cancelar</v-btn>
					<!-- <v-btn color="error" v-show="agendamento">excluir</v-btn> -->
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</v-container>
</template>

<script>
import axios from "axios"
import { baseApiUrl, showError } from "@/global"
import Bus from '@/config/bus'
export default {

	name: 'DialogMotivo',
	props:{
		data:{}
	},
	data () {
		return {
			text:{},
			telemarketing:[]
		}
	},
	methods:{
		save() {
			var id = this.data.cliente.id_telemarketing
			this.text.status = this.data.status
			this.text.fk_cliente = this.data.cliente.fk_cliente
			axios.put(`${baseApiUrl}/telemarketing/${id}`, this.text)
			.then(() => {
				this.$toasted.global.defaultSuccess();
				this.closeDialog();
			Bus.$emit('reload')
			})
			.catch(showError);
		},
		loadTelemarketing(){
			axios.get(`${baseApiUrl}/telemarketing`)
			.then(res => {
				this.telemarketing = res.data
			})
			.catch(showError)
		},
		reset() {
			this.text = {};
			this.data.modal = false
			this.loadTelemarketing()
		},
		closeDialog() {
			this.loadTelemarketing()
			this.reset()
		}
	},
	mounted() {
		this.loadTelemarketing()
	}
}
</script>

<style lang="css" scoped>
</style>