<template>
	<div>
		<v-app-bar app color="red accent-4" dark height="50%">
			<v-app-bar-nav-icon @click.stop="drawer=!drawer"></v-app-bar-nav-icon>
			<v-toolbar-title class="titulo">
			</v-toolbar-title>
			<v-btn depressed x-small to="/notas" color="white" class="black--text">
				lembretes
				<v-badge :content="notifica.qtd" color="success" v-if="notifica.qtd">
				</v-badge>
			</v-btn>
			<v-spacer></v-spacer>
			<Notification class="mr-5"/>
			<UserDropdown v-if="!hideUserDropdown"/>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app color="blue-grey darken-2" dark>
			<v-toolbar-title class="mt-2 text-center">
				<img src="@/img/logo-brand.png" width="50%">
			</v-toolbar-title>
			<v-list dense>
				<v-list-item v-for="(item,i) in items" :key="i">
					<v-list-item :to="item.link" v-if="!item.sublinks">
						<v-list-item-icon>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-group  no-action v-else>
						<template v-slot:activator>
							<v-list-item-icon>
								<v-icon v-text="item.icon" small></v-icon>
							</v-list-item-icon>
							<v-list-item-title v-text="item.text"></v-list-item-title>
						</template>
						<v-list-item v-for="sublink in item.sublinks" :key="sublink.text" :to="sublink.link" >
							<v-list-item-title v-text="sublink.text">
							</v-list-item-title>
						</v-list-item>
					</v-list-group>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>	
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global' 
	import { mapState } from 'vuex'
	import UserDropdown from './UserDropdown'
	import Notification from './Notification'
	import Bus from '@/barramento'
	
	export default {

		name: 'Header',
		components: { UserDropdown, Notification },
		props: {
			titulo: String,
			hideToggle: Boolean,
			hideUserDropdown: Boolean
		},
		data() {
			return {
				meta:0,
				drawer:true,
				items:[],
				notifica:{}
			}
		},
		computed:mapState(['user']),
		methods:{
			loadMetas(){
				axios.get(`${baseApiUrl}/metas`).then(res => {	
					res.data.map(m => {
						if(m.consultor_meta === this.user.nome){
							this.meta = 1
						}
					})
				})
				.catch(showError)
			},
			fnLink(){
				if(this.user.cargo === 'Consultor'){
					this.items = [
						{text:'Painel de Controle', icon:'mdi-home', link:'/'},
						{text:'Clientes', icon:'mdi-handshake', link:'/clientes'},
						{text:'Telemarketing', icon:'mdi-phone', link:'/telemarketing'},
						{text:'Esteira', icon:'mdi-road-variant', link:'/esteira'},
						{text:'Formalização', icon:'mdi-file-check', link:'/formalizacao'},
						{text:'Contratos', icon:'mdi-file-document', link:'/contratos'},
						{text:'Agendamentos', icon:'mdi-calendar-account', link:'/agendamentos'},
						{text:'Aniversariantes', icon:'mdi-cake-variant', link:'/aniversariantes'},	
						]
				} else if(this.user.cargo === 'Administrador' || this.user.cargo === 'Financeiro'){
					this.items = [
						{text:'Painel de Controle', icon:'mdi-home', link:'/'},
						{text:'Clientes', icon:'mdi-handshake', link:'/clientes'},
						{text:'Telemarketing', icon:'mdi-phone', link:'/telemarketing'},
						{text:'Esteira', icon:'mdi-road-variant', link:'/esteira'},
						{text:'Formalização', icon:'mdi-file-check', link:'/formalizacao'},
						{text:'Contratos', icon:'mdi-file-document', link:'/contratos'},
						{text:'Agendamentos', icon:'mdi-calendar-account', link:'/agendamentos'},
						{text:'Aniversariantes', icon:'mdi-cake-variant', link:'/aniversariantes'},
						{text:'Usuários', icon:'mdi-account-group', link:'/usuarios'},
						{text:'Financeiro', icon:'mdi-currency-brl', sublinks:[
							{text:'Bancos e Condições',  link:'/financeiro'},
							{text:'Receitas e Despesas', link:'/receitas_despesas'},
							]},
						{text:'Relatórios', icon:'mdi-chart-line', link:'/relatorios'},
						{text:'Metas', icon:'mdi-chart-bar', link:'/metas'},
						{text:'Torpedo Wa', icon:'mdi-whatsapp', link:'/wa'},
						{text:'Lixeira', icon:'mdi-delete', link:'/lixeira'},
						]
				} else if(this.user.cargo === 'Supervisor') {
					this.items = [
						{text:'Painel de Controle', icon:'mdi-home', link:'/'},
						{text:'Clientes', icon:'mdi-handshake', link:'/clientes'},
						{text:'Telemarketing', icon:'mdi-phone', link:'/telemarketing'},
						{text:'Esteira', icon:'mdi-road-variant', link:'/esteira'},
						{text:'Formalização', icon:'mdi-file-check', link:'/formalizacao'},
						{text:'Contratos', icon:'mdi-file-document', link:'/contratos'},
						{text:'Agendamentos', icon:'mdi-calendar-account', link:'/agendamentos'},
						{text:'Aniversariantes', icon:'mdi-cake-variant', link:'/aniversariantes'},	
						{text:'Lixeira', icon:'mdi-delete', link:'/lixeira'}
						]
				}

			},
			async loadNotificacao(){
				try {
					const res = await axios.get(`${baseApiUrl}/lembretes`)
					this.notifica = res.data.notificacao
				} catch(err) {
					showError(err)
				}
			},
			async ultimoContrato() {
				try {
					const res = await axios.get(`${baseApiUrl}/esteira`);
					const contratosValidos = res.data.ultimoContrato.filter(ut => ut !== null);

					contratosValidos.forEach(ut => {
						if ((this.user.cargo === 'Administrador' || this.user.cargo === 'Supervisor') && ut.msg && typeof ut.msg === 'string' && ut.msg.length > 0) {
							Bus.$emit('alerta', contratosValidos);
						}
						if (this.user.id === ut.id_usuario && ut.msg && typeof ut.msg === 'string' && ut.msg.length > 0) {
							this.$toasted.global.alertMessage({ msg: ut.msg });
						}
					});
				} catch (error) {
					console.error('Erro ao buscar último contrato:', error);
				}
			},


		},
		mounted(){
			this.fnLink()
			this.loadMetas()
			this.loadNotificacao()
			Bus.$on('loadNotificacao', () =>{
				this.loadNotificacao()
			})
			this.ultimoContrato()
		},

	};
</script>

<style lang="css" scoped>

</style>
