<template>
	<v-container fluid dense>
		<PageTitle icon="mdi mdi-whatsapp" main="Torpedo Whatsapp"></PageTitle>
		<v-card>
			<v-col md="6">
				<v-select v-model="destino" label="Destino" @change="loadDestino" :items="options"></v-select>
			</v-col>
			<v-col v-if="destino">
				<v-btn small outlined color="green" @click="csvExportContatos(dados)">
					<v-icon>mdi-microsoft-excel</v-icon>
				</v-btn>
			</v-col>
		</v-card>
		<v-data-table v-if="destino" id="tabela" :headers="table" :items="dados" class="elevation-1 mt-2" dense>
			<template v-slot:item.contato ="{ item }">
				<span class="font-weight-bold" v-for="(num,i) in item.contato" :key="i">
					{{ num.numero_telefone }}
				</span>
			</template>	
		</v-data-table>
		<v-progress-linear v-if="progress" :value="progress"></v-progress-linear>
		
	</v-container>
</template>

<script>
	import PageTitle from '@/templates/PageTitle'
	import axios from 'axios'
	import moment from 'moment'
	import { baseApiUrl, showError } from '@/global'
	export default {

		name: 'Wa',
		components: { PageTitle },
		data () {
			return {
				progress:0,
				destino:null,
				dados:[],
				uploadDialog:false,
				file:null,
				files:[],
				loading:false,
				qrcode:{},
				msg:null,
				conectado:false,
				inactivityTimer: null,
				table:[
					{text:'Nome', value:'nome'},
					{text:'Consultor', value:'consultor'},
					{text:'Contato', value:'contato'},
					],

				options:[
					{text:'Agendamentos', value:'agendamentos'},
					{text:'Aniversariantes', value:'aniversarios'},
					]
			}

		},
		methods: {
			loadDestino() {
				if(this.destino === 'aniversarios'){
					this.loadNiver()
				}else
				if(this.destino === 'agendamentos'){
					this.loadAgendados()
				}
			},
			loadNiver() {
				axios.get(`${baseApiUrl}/aniversarios`).then(res => {
					this.dados = res.data.map(cl => {
						return {
							nome:cl.nome_cliente,
							consultor:cl.nome,
							contato:cl.telefones
						}
					})
				})
				.catch(showError)
			},
			loadAgendados() {
				axios.get(`${baseApiUrl}/agendamentos`).then(res => {
					this.dados = res.data.agendamentosParaAmanha.map(ag => {
						return {
							nome:ag.nome_cliente,
							data:ag.data_agendamentos,
							consultor:ag.nome,
							contato:ag.telefones
						}
					})
				})
				.catch(showError)	
			},
			csvExportContatos(arr) {
				const contatos = []
				arr.forEach(a => {
					a.contato.forEach(t => {
						const numeroTelefone = t.numero_telefone.replace(/[^0-9]/g, "");
						contatos.push({
							nome: a.nome,
							telefone: numeroTelefone
						});
					});
				});

				this.exportCSV(contatos, 'contatos_WA_saesoconsig');
			},

			exportCSV(arr, arquivoName) {
				let content = "data:text/csv;charset=utf-8,";
				content += [
					Object.keys(arr[0]).join(";"),
					...arr.map(item => Object.values(item).join(";"))
					].join("\n").replace(/(^\[)|(\]$)/gm, "");

				const data = encodeURI(content);
				const num = moment(new Date()).format('DMY.Hms');
				const link = document.createElement("a");

				link.setAttribute("href", data);
				link.setAttribute("download", `${arquivoName}-${num}.csv`);
				link.click();

				this.reset();
			},
			reset() {
				this.destino = {}
			},
			formatoData(data){
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
			},
			
		}
	}
</script>

<style lang="css" scoped>
	.qrc {
		font-family: 'Courier New', monospace; 
		font-size: 5px;
		letter-spacing: 0px;
		line-height: 5px;
	}
</style>