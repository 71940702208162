<template>
	<v-container>
		<PageTitle icon="mdi mdi-home" main="Financeiro" sub="bancos, condições e comissões"/>
		<v-card>
			<v-toolbar>
				<template v-slot:extension>
					<v-tabs v-model="tab" align-with-title color="error">
						<v-tabs-slider color="red"></v-tabs-slider>
						<v-tab v-for="item in items" :key="item">
							{{ item }}
						</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<Tabelas/>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<Bancos/>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<Produtos/>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<Convenios/>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<Taxas/>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<Prazos/>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<Promotoras/>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</v-container>
</template>

<script>
import PageTitle from '@/templates/PageTitle'
import Bancos from '@/components/admin/bancos/Bancos'
import Produtos from '@/components/admin/bancos/Produtos'
import Convenios from '@/components/admin/bancos/Convenios'
import Tabelas from '@/components/admin/bancos/Tabelas'
import Promotoras from '@/components/admin/bancos/Promotoras'
export default {

	name: 'Financeiro',
	components: { PageTitle, Bancos, Produtos, Convenios, Tabelas, Promotoras },
	data() {
		return {
			tab:'null',
			items: ['Tabelas', 'Bancos','Produtos','Convênios', 'Promotoras']
		};
	},
};
</script>

<style lang="css" scoped>
</style>
