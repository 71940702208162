import { render, staticRenderFns } from "./Telemarketing.vue?vue&type=template&id=65d5b371&scoped=true"
import script from "./Telemarketing.vue?vue&type=script&lang=js"
export * from "./Telemarketing.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d5b371",
  null
  
)

export default component.exports