<template>
	<v-container>
		<v-overlay :value="showCelebration" color="green">
			<v-card class="pa-5 success" outlined>
				<div style="width:100%;height:0;padding-bottom:56%;position:relative;"><img :src="selectedGif" width="100%" height="100%" style="position:absolute"/></div>
				<v-card-title class="text-h5">
					Parabéns! Você atingiu sua meta!
				</v-card-title>
				<v-card-text>
					{{ selectedFrase }}
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" @click="showCelebration = false">
						Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-overlay>
		<v-snackbar top centered dark v-model="snackbar" elevation-1 timeout="20000" color="green">
			<v-row>
				<v-icon>mdi-cake-variant</v-icon><v-col lg="8" class="font-weight-bold" style="font-size:22px;">Aniversariante(s) do dia!</v-col>
				<v-spacer></v-spacer>
				<v-btn icon @click.native="snackbar = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-col cols="12" style="font-size:20px;">
					Envie uma mensagem de felicitação! 
					<v-icon size="60" color="indigo">mdi-cake-variant</v-icon>
				</v-col>

				<v-icon color="red" size="50">mdi-balloon</v-icon><v-col v-for="us in bday" :key="us.id" style="font-size:20px;">
					{{us.nome}} ({{us.cargo}}) - {{ us.celular }} <v-icon color="orange" size="50">mdi-balloon</v-icon>
				</v-col>
			</v-row>
		</v-snackbar>
		<PageTitle icon="mdi mdi-home" main="Painel Notificações" sub=""/>
		<v-col>
			<v-card v-show="showAlerta && user.cargo === 'Consultor'">
				<v-alert colored-border border="top" color="info" elevation="2" v-show="alertMetas.length > 0">
					<v-card-title class="info--text"> <v-icon color="info">mdi-alert-circle</v-icon>Você possui meta(s) definida(s)</v-card-title>
					<v-flex v-for="meta in alertMetas" :key="meta.id_meta" >
						<v-col>
							<v-card class="mt-2 grey lighten-3">
								<v-flex row>
									<v-col sm="2">
										<b>ID: </b>
										<span>
											{{ meta.codigo }}
										</span>
									</v-col>
									<v-col>
										<b>Tabela: </b>
										<span>
											{{ meta.tabela }}
										</span>
									</v-col>
									<v-col>
										<b>Valor: </b> 
										<span>
											{{ formatoMoeda(meta.valor) }}
										</span>
									</v-col>
									<v-col>
										<b>Validade:</b>
										<span class="font-weight-bold blue-grey--text">
											{{ formatoData(meta.validade) }}
										</span>
									</v-col>
								</v-flex>
							</v-card>
						</v-col>
					</v-flex>
					<v-col>
						<v-divider color="red"></v-divider>
					</v-col>
					<v-card-title>Progresso Metas</v-card-title>
					<v-flex>
						<v-col>
							<v-data-table :items="stacksMetasConsultor" :headers="table" hide-default-footer dense>
								<template v-slot:item.data_contrato="{ item }">
									<v-flex style="font-size: 11px;">
										{{ formatoData(item.data_contrato) }}
									</v-flex>
								</template>
								<template v-slot:item.validade_meta="{ item }">
									<v-flex style="font-size: 11px;" class="green--text">
										{{ formatoData(item.validade_meta) }}
									</v-flex>
								</template>
								<template v-slot:item.codigo_tabela_contrato="{ item }">
									<v-flex style="font-size: 11px;">
										{{ item.codigo_tabela_contrato }}
									</v-flex>
								</template>
								<template v-slot:item.tabela_contrato="{ item }">
									<v-flex style="font-size: 11px;">
										{{ item.tabela_contrato }}
									</v-flex>
									<v-flex><small>{{ item.banco_contrato }}</small></v-flex>
								</template>
								<template v-slot:item.total="{ item }">
									<v-flex style="font-size: 11px;">
										{{ formatoMoeda(item.total) }}
									</v-flex>
									<small style="font-size: 9px" class="info--text">
									meta - {{ formatoMoeda(item.valor_meta) }}</small>
								</template>
								<template v-slot:item.porcent="{ item }">
									<v-flex style="font-size: 11px;">
										<v-progress-circular :rotate="360" :size="45" :width="3" :value="item.porcent" :color="item.porcent > 0 && item.porcent < 25 ? 'error': item.porcent > 25 && item.porcent < 50 ? 'orange' :
										item.porcent > 50 && item.porcent < 75 ? 'indigo' : 'green'">
										<v-flex>{{ item.porcent.toFixed(1) }}%</v-flex>
									</v-progress-circular>
								</v-flex>
							</template>
							<template v-slot:item.nome="{ item }">
								<v-flex style="font-size: 11px;">
									{{ item.nome }}
								</v-flex>
							</template>
						</v-data-table>
					</v-col>
				</v-flex>
			</v-alert>
		</v-card>
		<v-row v-show="user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
			<v-col lg="4" cols="sm" class="pb-2" v-show="finalizados.length > 0">
				<v-card>
					<v-row class="no-gutters">
						<div class="col-auto">
							<div class="green fill-height">&nbsp;</div>
						</div>
						<div class="col pa-3 py-4 green--text">
							<h5 class="text-truncate text-uppercase">Finalizados Mês Atual</h5>
							<h1>{{ formatoMoeda(total_finalizados) }}</h1>
							<small>.</small>
						</div>
					</v-row>
				</v-card>
			</v-col>
			<v-col lg="4" cols="sm" class="pb-2">
				<v-card>
					<v-row class="no-gutters">
						<div class="col-auto">
							<div class="primary fill-height">&nbsp;</div>
						</div>
						<div class="col pa-3 py-4 primary--text">
							<h5 class="text-truncate text-uppercase"> Esteira Mês Atual</h5>
							<h1>{{ formatoMoeda(total_esteira) }}</h1>
							<small class="grey--text font-weight-bold"><v-icon small color="indigo">mdi-arrow-left</v-icon> {{ formatoMoeda(total_anterior) }}</small>
						</div>
					</v-row>
				</v-card>
			</v-col>
			<v-col lg="4" cols="sm" class="pb-2">
				<v-card>
					<v-row class="no-gutters">
						<div class="col-auto">
							<div class="indigo fill-height">&nbsp;</div>
						</div>
						<div class="col pa-3 py-4 indigo--text">
							<h5 class="text-truncate text-uppercase">Projeção Mês Atual</h5>
							<h1>{{ formatoMoeda(total_finalizados + total_esteira)}}*</h1>
							<small>total finalizados + total esteira</small>
						</div>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-col>
	<v-col>
		<v-flex v-for="(item,i) in alertaContrato" :key="i">
			<div class="primary--text">{{ item.msg }}</div>	
		</v-flex>
		
		<v-card v-show="user.cargo === 'Administrador' || user.cargo === 'Supervisor' && stacksMetasPrazo.length > 0" flat color="blue-grey">
			<v-card-title class="white--text font-weight-bold">Meta Individual por Produto(Tabela)</v-card-title>
			<v-col>
				<v-flex class="caption green">
					Dentro da validade
				</v-flex>
				<v-data-table :items="stacksMetasPrazo" :headers="table" hide-default-footer dense>
					<template v-slot:item.data_contrato="{ item }">
						<v-flex style="font-size: 11px;">
							{{ formatoData(item.data_contrato) }}
						</v-flex>
					</template>
					<template v-slot:item.validade_meta="{ item }">
						<v-flex style="font-size: 11px;" class="green--text">
							{{ formatoData(item.validade_meta) }}
						</v-flex>
					</template>
					<template v-slot:item.codigo_tabela_contrato="{ item }">
						<v-flex style="font-size: 11px;">
							{{ item.codigo_tabela_contrato }}
						</v-flex>
					</template>
					<template v-slot:item.tabela_contrato="{ item }">
						<v-flex style="font-size: 11px;">
							{{ item.tabela_contrato }}
						</v-flex>
						<v-flex><small>{{ item.banco_contrato }}</small></v-flex>
					</template>
					<template v-slot:item.total="{ item }">
						<v-flex style="font-size: 11px;">
							{{ formatoMoeda(item.total) }}
						</v-flex>
						<small style="font-size: 9px" class="info--text">
						meta - {{ formatoMoeda(item.valor_meta) }}</small>
					</template>
					<template v-slot:item.porcent="{ item }">
						<v-flex style="font-size: 11px;">
							<v-progress-circular :rotate="360" :size="45" :width="3" :value="item.porcent" :color="item.porcent > 0 && item.porcent < 25 ? 'error': item.porcent > 25 && item.porcent < 50 ? 'orange' :
							item.porcent > 50 && item.porcent < 75 ? 'indigo' : 'green'">
							<v-flex>{{ item.porcent.toFixed(1) }}%</v-flex>
						</v-progress-circular>
					</v-flex>
				</template>
				<template v-slot:item.nome="{ item }">
					<v-flex style="font-size: 11px;">
						{{ item.nome }}
					</v-flex>
				</template>
			</v-data-table>
		</v-col>
	</v-card>
</v-col>
<v-col md="12" v-show="agendados.length > 0">
	<v-alert colored-border border="left" color="red" elevation="1" class="text-center" v-show="this.user.cargo === 'Administrador' || user.cargo === 'Supervisor'">
		<v-row class="justify-center">
			<v-icon color="red" small>mdi-calendar</v-icon>
			<v-subheader class="info--text">Quem Agendou Hoje</v-subheader>
		</v-row>
		<v-card-text>
			<v-card flat>
				<v-layout row wrap>
					<v-flex xs12 md3>
						<div class="caption grey--text">Consultor</div>
					</v-flex>
					<v-flex xs12 md6>
						<div class="caption grey--text">Clientes</div>
					</v-flex>
				</v-layout>
				<v-layout v-for="(agendado,i) in agendados" :key="i" row wrap>
					<v-flex xs12 md3>
						
						<v-tab><v-badge :content="agendado.qtd">
							<div class="font-weight-bold line-height">{{ agendado.nome }}</div>
						</v-badge></v-tab>
						
					</v-flex>
					<v-flex xs12 md3 v-for="(cli,i) in agendado.clientes" :key="i">
						<small style="font-size:0.6rem;line-height:1px;">{{ cli.nome_cliente }}</small><br>
						<small v-for="(num,i) in cli.telefones" :key="i" class="font-weight-bold">
							{{ num.numero_telefone }}
						</small><br>
						<small style="font-size:0.5rem;line-height:1px;" class="font-weight-bold indigo--text">{{ cli.cidade_cliente }}</small><br>
						<small><b>OBS:</b>{{ cli.informacao }}</small>
					</v-flex>
				</v-layout>
			</v-card>
		</v-card-text>
	</v-alert>
</v-col>
<v-col>
	<v-row>
		<v-col lg="12" cols="sm" class="pb-2" v-show="finIndividual.length > 0">
			<v-card flat color="indigo lighten-5">
				<v-card-title class="blue-grey--text">
					Finalizados por Consultor
				</v-card-title>
				<v-card-text>
					<v-col v-for="(item,i) in finIndividual" :key="i" v-show="user.id === item.fk_usuario || user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
						<v-row>
							<v-col>
								<v-flex><h4>{{ item.nome }}</h4></v-flex>
								<v-flex color="grey" style="font-size:10px" class="font-weight-bold">{{ item.qtd_contrato }} contratos
								</v-flex>
							</v-col>
							<v-col>
								<v-flex class="success--text"><h3>{{ formatoMoeda(item.total) }}</h3></v-flex>
							</v-col>
							<v-col>
								<v-flex>
									<v-progress-linear :color="item.meta <= 25 ? 'red darken-3' : item.meta <= 50 ? 'orange accent-2': 'light-green darken-2'" height="10" :value="item.meta" striped>
										<template v-slot:default="{ value }">
											<strong style ="font-size:10px;" class="primary--text" :class="item.meta < 50 ? 'grey--text' : 'white--text'">{{ value.toFixed(1) }}%</strong>
										</template>
									</v-progress-linear>
								</v-flex>
							</v-col>
							<v-col>
							</v-col>
							<v-col>
								<v-flex class="font-weight-bold grey--text">
									Meta do dia: <span class="indigo--text">{{ formatoMoeda(calcularMetas(item.metamensal,item.total).metaDiaria) }}</span>
								</v-flex>
							</v-col>
						</v-row>
					</v-col>
				</v-card-text>	
			</v-card>
		</v-col>
		<v-col lg="12" cols="sm" class="pb-2">
			<v-card flat color="green lighten-5">
				<v-card-title class="blue-grey--text">
					Esteira por Consultor (<b class="blue-grey--text">Atual</b>)
				</v-card-title>
				<v-card-text>
					<v-col>
						<v-row>
							<v-col v-for="(item,i) in esteiraIndividual" :key="i" v-show="user.id === item.id_usuario || user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
								<v-flex><h4>{{ item.nome }}</h4></v-flex>
								<v-flex class="primary--text">
									<h3>{{ formatoMoeda(item.total) }}</h3>
								</v-flex>
								<v-flex color="grey" style="font-size:10px" class="font-weight-bold">{{ item.qtd }} contratos
								</v-flex>
							</v-col>
							<v-col sm="2" v-if="user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
								<v-card color="primary" dark>
									<v-subheader class="font-weight-bold">TOTAL</v-subheader> 
									<v-card-text>
										{{ formatoMoeda(total_esteira) }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
					<v-col v-if="esteiraIndividualMargem.length > 0">
						<v-divider color="error"></v-divider>
						<v-divider color="black"></v-divider>
						<small class="font-weight-bold primary--text">Nova Margem Aumento Salarial</small>
						<v-row class="success lighten-4">
							<v-col v-for="(item,i) in esteiraIndividualMargem" :key="i" v-show="user.id === item.id_usuario || user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
								<v-flex><h4>{{ item.nome }}</h4></v-flex>
								<v-flex class="primary--text">
									<h3>{{ formatoMoeda(item.total) }}</h3>
								</v-flex>
							</v-col>
							<v-col sm="2" v-if="user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
								<v-card color="success" dark>
									<v-subheader class="font-weight-bold">TOTAL</v-subheader> 
									<v-card-text>
										{{ formatoMoeda(total_esteiraMargem) }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
				</v-card-text>	
			</v-card>
		</v-col>
		<v-col lg="12">
			<v-card flat color="grey lighten-3" v-show="lastEsteiraIndividual.length > 0">
				<v-card-title class="blue-grey--text">
					Esteira por Consultor (<b class="blue-grey--text">Mês Anterior</b>)
				</v-card-title>
				<v-card-text>
					<v-col>
						<v-row>
							<v-col v-for="(item,i) in lastEsteiraIndividual" :key="i" v-show="user.id === item.id_usuario || user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
								<v-flex><h4>{{ item.nome }}</h4></v-flex>
								<v-flex class="grey--text">
									<h3>{{ formatoMoeda(item.total) }}</h3>
								</v-flex>
							</v-col>
							<v-col sm="2" v-if="user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
								<v-card color="blue-grey" dark>
									<v-subheader class="font-weight-bold">TOTAL</v-subheader> 
									<v-card-text>
										{{ formatoMoeda(total_anterior) }}
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>	
					</v-col>
					<v-col v-if="lastEsteiraIndividualMargem.length > 0">
						<v-divider color="error"></v-divider>
						<v-divider color="black"></v-divider>
						<small class="font-weight-bold grey--text">Nova Margem Aumento Salarial</small>
						<v-row class="success lighten-4">
							<v-col>
								<v-row>
									<v-col v-for="(item,i) in lastEsteiraIndividualMargem" :key="i" v-show="user.id === item.id_usuario || user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
										<v-flex><h4>{{ item.nome }}</h4></v-flex>
										<v-flex class="grey--text">
											<h3>{{ formatoMoeda(item.total) }}</h3>
										</v-flex>
									</v-col>
									<v-col sm="2" v-if="user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
										<v-card color="blue-grey" dark>
											<v-subheader class="font-weight-bold">TOTAL</v-subheader> 
											<v-card-text>
												{{ formatoMoeda(total_anterior_margem) }}
											</v-card-text>
										</v-card>
									</v-col>
								</v-row>	
							</v-col>
						</v-row>
					</v-col>
				</v-card-text>	
			</v-card>
		</v-col>

		<v-col lg="6" cols="sm" class="pb-2" v-show="user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
			<v-card v-if="totalMesFinalizado.length > 0" flat>
				<v-card-text>
					<v-col>
						<GBar :dataChart="totalMesFinalizado" :options="chartOptions"></GBar>
					</v-col>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col lg="6" cols="sm" class="pb-2" v-show="user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
			<v-card v-if="finIndividual.length > 0" flat>
				<v-card-text>
					<v-col>
						<GPie :dataChart="finIndividual" :options="chartOptions2"></GPie>
					</v-col>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</v-col>
<v-col v-show="user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
	<v-card>
		<v-card-text>
			<v-col >
				<GLine :dataChart="totalLastYears" :options="chartOptions3"></GLine>
			</v-col>
		</v-card-text>
	</v-card>
	<v-divider color="error"></v-divider>
	<v-flex class="font-weight-bold">Gráficos por Consultores</v-flex>
	<v-divider color="black"></v-divider>
	<v-card>
		<v-card-text>
			<v-col >
				<GLineC :dataChart="totalLastYearsConsultor" :options="chartOptions3"></GLineC>
			</v-col>
		</v-card-text>
	</v-card>
</v-col>
<v-col v-show="pesquisas.length > 0 && user.cargo === 'Administrador' || user.cargo === 'Supervisor' || user.cargo === 'Financeiro'">
	<v-card>
		<v-card-title>Pesquisa</v-card-title>
		<v-card-text>
				<GBarP :dataChart="pesquisas"></GBarP>
		</v-card-text>
	</v-card>
</v-col>
</v-container>
</template>

<script>
	import a94UN from '@/img/94UN.gif'
	import AzOE from '@/img/AzOE.gif'
	import BavR from '@/img/BavR.gif'
	import Tc0v from '@/img/Tc0v.gif'
	import GBar from '@/GoogleChartBar'
	import GPie from '@/GoogleChartPie'
	import GLine from '@/GoogleChartLine'
	import GLineC from '@/GoogleChartLineConsultor'
	import GBarP from '@/GoogleChartBarPesquisa'
	import { mapState } from 'vuex'
	import PageTitle from '@/templates/PageTitle'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import moment from 'moment'
	import Bus from '@/barramento'
	export default {
		name: 'Home',
		components: { PageTitle,GBar, GPie, GLine, GLineC, GBarP },

		data() {
			return {
				pesquisas:[],
				alertaContrato:[],
				selectedGif:'',
				selectedFrase:'',
				showCelebration: false,
				bday:[],
				snackbar:false,
				closeBday:false,
				data:{},
				alertMetas:[],
				agendados:[],
				metas:[],
				stacksMetasPrazo:[],
				stacksMetasConsultor:[],
				momentInstance: moment(),
				finalizados:[],
				total_finalizados:0,
				totalMesFinalizado:[],
				totalLastYears:[],
				totalLastYearsConsultor:[],
				esteira:[],
				total_esteira:0,
				total_esteiraMargem:0,
				total_anterior:0,
				total_anterior_margem:0,
				esteiraIndividual:[],
				esteiraIndividualMargem:[],
				lastEsteiraIndividual:[],
				lastEsteiraIndividualMargem:[],
				finIndividual:{},
				haxisformat:'',
				chartOptions:{
					title:'',
					responsive:true,
					maintainAspectRatio:false,
					width:'auto',
					legend: {
						position:'right'
					},
					vAxis:{title: 'Valores em R$'},
					hAxis:{
						title:'Meses',
					}
				},
				chartOptions2:{
					title:'',
					responsive:true,
					maintainAspectRatio:false,
					width:'auto',
					legend: {
						position:'right'
					},
					hAxis:{
						title:'Valores em R$',
						ticks: this.haxisformat
					},
					chartArea:{
						height: 500
					}
				},
				chartOptions3:{
					title:'Comparativo Anual',
					responsive:true,
					maintainAspectRatio:false,
					width:'auto',
					legend: {
						position:'right'
					},
					vAxis:{title: 'Valores em R$'},
					hAxis:{
						title:'Meses',
					}
				},
				table:[
					{text:'Data Contrato', value:'data_contrato', sortable:false},
					{text:'Validade Meta', value:'validade_meta', sortable:false},
					{text:'Cod. Tabela', value:'codigo_tabela_contrato', sortable:true},
					{text:'Tabela', value:'tabela_contrato', sortable:false},
					{text:'Total', value:'total', sortable:false},
					{text:'Progresso', value:'porcent', sortable:false},
					{text:'Consultor', value:'nome', sortable:true},
					],
				gifUrls:[a94UN,AzOE,BavR,Tc0v],
				frases:[
					'Seu empenho nos motiva a todos. Seguimos adiante, buscando mais conquistas!',
					'A paixão que você demonstra pelo seu trabalho é contagiante. Que possamos sempre mirar mais alto!',
					'Seu compromisso é o alicerce para novas realizações. Avançamos juntos rumo ao sucesso!',
					'A energia que você traz para cada desafio é admirável. Continuemos a superar nossas metas!',
					'Sua persistência é um exemplo a ser seguido. Que continuemos a trilhar caminhos de vitórias!',
					'O seu trabalho árduo ilumina o caminho para o sucesso. Juntos, vamos alcançar ainda mais!',
					'Sua força de vontade nos inspira a todos a sermos melhores. Em frente, por mais triunfos!',
					'A excelência do seu esforço nos guia a novas alturas. Que nossas aspirações nos levem além!',
					'Sua paixão pelo que faz reverbera sucesso. Vamos juntos, alcançando cada vez mais!',
					'O brilho da sua dedicação nos orienta. Continuemos a perseguir nossos sonhos com determinação!'
					]	
			};
		},
		methods: {
			loadFinalizados() {
				axios.get(`${baseApiUrl}/finalizados`).then(res => {
					this.finalizados = res.data.finalizados
					this.total_finalizados = res.data.totalFinalizados[0].total
					this.finIndividual = res.data.finalizadoPorConsultor
				
				})
				.catch(showError)
			},
			loadFullFinalizados() {
				axios.get(`${baseApiUrl}/fullFinalizados`).then(res => {
					this.totalMesFinalizado = res.data.getYearCurr
					this.totalLastYears = res.data.getComparesion
					this.totalLastYearsConsultor = res.data.getComparesionConsultor
					this.chartOptions.title = `Produção ${new Date().getUTCFullYear()}`
				})
				.catch(showError)
			},
			async loadEsteira() {
				await axios.get(`${baseApiUrl}/esteira`).then(res => {
					this.total_esteira = res.data.totalMesAtual[0].total
					this.total_esteiraMargem = res.data.totalMesAtualMargem[0].total
					this.total_anterior = res.data.totalMesAnterior[0].total
					this.total_anterior_margem = res.data.totalMesAnteriorMargem[0].total
					this.esteiraIndividual = res.data.totalEsteiraPorConsultor
					this.esteiraIndividualMargem = res.data.totalEsteiraPorConsultorMargem
					this.lastEsteiraIndividual = res.data.totalEsteiraPorConsultorMesAnterior
					this.lastEsteiraIndividualMargem = res.data.totalEsteiraPorConsultorMesAnteriorMargem
					
				})
				.catch(showError)
			},
			async loadMetas(){
				await axios.get(`${baseApiUrl}/metas`).then(res => {
					this.metas = res.data
				})
				.catch(showError)
			},
			async loadStacksMetas() {
				await axios.get(`${baseApiUrl}/stacksMetas`).then(res => {
					this.stacksMetasPrazo = res.data.noPrazo
					this.stacksMetasPrazo.map(mt => {
						if(mt.id_usuario === this.user.id) {
							this.stacksMetasConsultor.push(mt)
						}
					})
				})
				.catch(showError)	
			},
			async loadBirthday() {
				await axios.get(`${baseApiUrl}/aniversarios/birthday`)
				.then(res => this.bday = res.data)
				.catch(showError)
			},
			async loadAgendamentos() {
				await axios.get(`${baseApiUrl}/agendamentos`)
				.then(res => this.agendados = res.data.getAgendadosHojeDB)
			},
			async loadUsuarios(){
				await axios.get(`${baseApiUrl}/usuarios`).then(res => {
					this.usuarios = res.data
				})
				.catch(showError)
			},
			async loadPesquisas() {
				await axios.get(`${baseApiUrl}/pesquisa`).then(res => {
					this.pesquisas = res.data
				})
				.catch(showError)
			},
			formatoData(data){
				if(data){
					return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
				}
			},
			formatoMoeda(valor) {
				if(valor){
					let v = (valor/1).toFixed(2).replace('.',',')
					return `R$ ${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
				}
			},
			dataAtualFormatada(){
				var data = new Date(),
				dia  = data.getDate().toString(),
				diaF = (dia.length == 1) ? '0'+dia : dia,
				mes  = (data.getMonth()+1).toString(),
				mesF = (mes.length == 1) ? '0'+mes : mes,
				anoF = data.getFullYear();


				return diaF+"/"+mesF+"/"+anoF;
			},
			closeNotifyBday() {
				this.snackbar = false
				localStorage.setItem('closeBday', 'true')
			},
			calcularMetas(metaTotal, quantidadeJaAlcancada) {
    // Obter a data atual
				const hoje = new Date();

    // Obter o mês, o ano e o dia atuais
    // Mês é baseado em zero (janeiro é 0)
				const mesAtual = hoje.getMonth() + 1; 
				const anoAtual = hoje.getFullYear();
				const diaAtual = hoje.getDate();
				const diasNoMes = new Date(anoAtual, mesAtual, 0).getDate();
				const numeroDeSemanas = Math.ceil(diasNoMes / 7);

    // Calcular a meta diária e semanal
				const metaDiaria = metaTotal / diasNoMes;
				const metaSemanal = metaTotal / numeroDeSemanas;

    // Calcular o restante da meta diária
				const restanteDiario = Math.max(metaDiaria * diaAtual - quantidadeJaAlcancada, 0);

    // Calcular o restante da meta semanal
				const semanasCompletas = Math.floor(diaAtual / 7);
				const diasRestantes = diaAtual % 7;
				const restanteSemanal = Math.max(metaSemanal * semanasCompletas + metaDiaria * diasRestantes - quantidadeJaAlcancada, 0);

    // Resultado
				const resultados = {
					mesAtual: mesAtual,
					anoAtual: anoAtual,
					diaAtual: diaAtual,
					diasNoMes: diasNoMes,
					numeroDeSemanas: numeroDeSemanas,
					metaDiaria: metaDiaria.toFixed(2),
					metaSemanal: metaSemanal.toFixed(2),
					quantidadeJaAlcancada: quantidadeJaAlcancada.toFixed(2),
					restanteDiario: restanteDiario.toFixed(2),
					restanteSemanal: restanteSemanal.toFixed(2)
				};

				return resultados;
			},
			selectRandomGif() {
				const randomIndex = Math.floor(Math.random() * this.gifUrls.length);
				const randomfrases = Math.floor(Math.random()* this.frases.length)
				this.selectedGif = this.gifUrls[randomIndex]
				this.selectedFrase = this.frases[randomfrases]
				this.showCelebration = true
			}

		},
		watch: {
			bday(newVal) {
				const foiFechada = localStorage.getItem('closeBday');
				if (!foiFechada && newVal.length > 0) {
					this.snackbar = true;
				}
			}
		},
		computed: {
			...mapState(['user']),
			height() {
				let sw = null
				switch (this.$vuetify.breakpoint.name) {
				case 'xs': return 220
				case 'sm': return 400
				case 'md': return 500
				case 'lg': return 600
				case 'xl': return 800
				}
				return sw
			},
			showAlerta() {
				return this.metas.map(m => {
					if(m.consultores.map(c => c.nome === this.user.nome) && moment(m.validade_meta).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
						this.alertMetas.push({
							codigo:m.codigo_tabela,
							tabela:m.nome_tabela,
							valor:m.valor_meta,
							validade:m.validade_meta
						})
					}
				})
			}
		},
		mounted() {
			setInterval(() => {
				this.momentInstance = moment()
			}, 1000)
			this.loadFinalizados()
			this.loadFullFinalizados()
			this.loadEsteira()
			this.loadUsuarios()
			this.loadMetas()
			this.loadStacksMetas()
			this.loadAgendamentos()
			this.loadBirthday()
			Bus.$on('alerta', dados => {
				this.alertaContrato = dados
			})
			this.loadPesquisas()
		}
	};
</script>

<style lang="css" scoped>
</style>
