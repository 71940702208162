<template>
	<v-container>
		<v-col>
			<v-dialog v-model="data.modal" transition="dialog-transition" @click:outside="closeDialog" fullscreen>
				<v-card>
					<v-card-title primary-title>
						Editar Cliente {{ data.nome_cliente }}
						<v-spacer></v-spacer>
					</v-card-title>
					<v-card-text>
						<v-form @submit.stop.prevent>
							<input id="client-id" type="hidden" v-model="data.id_cliente" />
							<v-row>
								<v-col md="6" sm="12">
									<v-text-field
									id="client-nome"
									label="Nome"
									v-model="data.nome_cliente"
									type="text"
									size="sm"
									:disabled="mode === 'remove'"
									:autofocus="true"
									></v-text-field>
								</v-col>
								<v-col md="6" sm="12">
									<v-text-field
									id="client-apelido"
									label="Apelido"
									v-model="data.apelido_cliente"
									type="text"
									size="sm"
									:disabled="mode === 'remove'"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col md="2" sm="12">
									<v-text-field
									id="client-cpf"
									label="CPF"
									v-model="data.cpf_cliente"
									type="text"
									size="sm"
									:disabled="mode === 'remove'"
									v-mask="['###.###.###-##']"
									:error-messages="validationCpf[0].msg"
									:color="validationCpf[0].color"
									></v-text-field>
								</v-col>
								<v-col md="2" sm="12">
									<v-text-field
									id="client-nascimento"
									label="Nascimento"
									v-model="data.nascimento_cliente"
									type="date"
									size="sm"
									:disabled="mode === 'remove'"
									></v-text-field>
								</v-col>
								<v-col md="6" sm="12">
									<v-text-field
									id="client-rua"
									label="Logradouro"
									v-model="data.rua_cliente"
									type="text"
									size="sm"
									:disabled="mode === 'remove'"
									/>
								</v-col>
							</v-row>
							<v-row>
								<v-col md="4" sm="12">
									<v-text-field
									id="client-localidade"
									label="Localidade"
									v-model="data.localidade_cliente"
									type="text"
									size="sm"
									:disabled="mode === 'remove'"
									/>
								</v-col>
								<v-col md="6" sm="12">
									<v-text-field
									id="client-cidade"
									label="Cidade"
									v-model="data.cidade_cliente"
									type="text"
									size="sm"
									:disabled="mode === 'remove'"
									/>
								</v-col>
								<v-col md="2" sm="12">
									<v-select
									:items="options"
									v-model="data.atendimento"
									label="Atendimento"
									:disabled="mode === 'remove'"
									></v-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col
								md="2"
								sm="12"
								v-for="(tel, i) in data.telefones"
								:key="i"
								>
								<v-text-field
								v-model="tel.numero_telefone"
								:disabled="mode === 'remove'"
								v-mask="['(##)#####-####']"
								elevation-2
								label="Telefone"
								:append-icon="
								i + 1 == data.telefones.length ? 'mdi-plus-circle' : ''
								"
								@click:append="addTel"
								:prepend-icon="i >= 0 ? 'mdi-minus-circle' : ''"
								@click:prepend="remTel(i, tel)"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row> </v-row>
						<v-row>
							<v-col md="12" sm="12" class="text-center">
								<v-btn color="primary" v-if="mode === 'save'" @click="save"
								>Salvar</v-btn
								>
								<v-btn color="error" v-if="mode === 'remove'" @click="remove"
								>Excluir</v-btn
								>
								<v-btn color="grey" class="ml-2" @click="reset" dark
								>Cancelar</v-btn
								>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogTelefone" width="400">
			<v-card>
				<v-card-title>{{ telTitle }}</v-card-title>
				<v-card-actions>
					<v-btn color="primary" @click="removeTelefone()">confirmar</v-btn>
					<v-btn color="grey" @click="reset" class="ml-2">cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-col>
</v-container>
</template>

<script>
	import { validationMixin } from "vuelidate"
	import { required } from "vuelidate/lib/validators"
	import { cpf } from "cpf-cnpj-validator"
	import axios from "axios";
	import { baseApiUrl, showError } from "@/global"
	import Bus from '@/config/bus'
	export default {

		name: 'DialogClientes',
		props:{
			data:{},
		},
		data () {
			return {
				dialogTelefone:false,
				telTitle:'',
				telId:'',
				cpf:'',
				mode:'save',
				options: [
				{ text: "Presencial", value: "presencial" },
				{ text: "Telefone", value: "telefone" },
				],
				pagamentos: [
				{ text: "OP", value: "OP" },
				{ text: "CONTA", value: "CONTA" },
				],
				formalizacao: [
				{ text: "Digital", value: "Digital" },
				{ text: "Física", value: "Fisica" },
				],
				mixins: [validationMixin],
				validations: {
					client: {
						cpf_cliente: { required },
					},
				}
			}
		},
		methods: {
			reset() {
				this.data.modal = false	
			},
			closeDialog() {
				this.reset()
			},
			save() {
				const id = `/${this.data.id_cliente}`
				const status = this.data.status
				axios.put(`${baseApiUrl}/clientes${id}`, this.data)
				.then(() => {
					Bus.$emit('refresh', status)
					this.$toasted.global.defaultSuccess()
					this.closeDialog()
				})
				.catch(showError);
			},
			removeTelefone() {
				axios
				.delete(`${baseApiUrl}/telefones/${this.telId.tel.id_telefone}`)
				.then();
				this.data.telefones.splice(this.telId.i, 1);
				this.dialogTelefone = false;
				this.telId = {};
			},
			addTel() {
				this.data.telefones.push({ numero_telefone: "" });
			},
			remTel(i, tel) {
				if (this.data.telefones.length > 1) {
					if (!tel.id_telefone) {
						this.data.telefones.splice(i, 1);
					} else {
						this.dialogTelefone = true;
						this.telTitle = `Excluir o Telefone ${tel.numero_telefone}`;
						this.telId = { i, tel };
					}
				} else {
					alert("Não é possível remover o único contato cadastrado!");
				}
			},
		},
		computed:{
			validationCpf() {
				const errors = [{msg:"", color:"error", status:true}];
				if (this.data.cpf_cliente){
					cpf.isValid(this.data.cpf_cliente)
					? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
				}
				if (this.cpf){
					cpf.isValid(this.cpf) ? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
				}
				return errors;
			},
		}
	}
</script>

<style lang="css" scoped>
</style>