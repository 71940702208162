<template>
	<v-dialog v-model="dialogPesquisa" persistent fullscreen>
		<v-container>
		<!-- <template v-slot:activator="{ on }">
			<v-col md="6" sm="12">
				<v-btn @click="loadpesquisa('', 'save')" x-small color="error" v-on="on" outlined>Novo pesquisa</v-btn>
			</v-col>
		</template> -->
		<v-card>
			<v-card-title>
				Antes de preencher o cadastro faça a pesquisa
			</v-card-title>
			<v-card-text>		
				<v-form ref="form">
					<v-row>
						<v-col cols="12">
							<p>Já fez empréstimo em algum lugar antes da Rede Facilita?</p>
							<v-radio-group v-model="pesquisa.emprestimoAnterior" :rules="respRules">
								<v-radio label="Sim" value="sim"></v-radio>
								<v-radio label="Não" value="nao"></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<p>De que forma soube da Rede Facilita?</p>
							<v-row>
								<v-col>
									<v-checkbox label="WhatsApp" v-model="pesquisa.comoSoube" value="WhatsApp" :rules="respRules"></v-checkbox>
									<v-checkbox label="Instagram" v-model="pesquisa.comoSoube" value="Instagram" :rules="respRules"></v-checkbox>
									<v-checkbox label="Facebook" v-model="pesquisa.comoSoube" value="Facebook" :rules="respRules"></v-checkbox>
								</v-col>
								<v-col>
									<v-checkbox label="Canabrava FM" v-model="pesquisa.comoSoube" value="CanabravaFM" :rules="respRules"></v-checkbox>
									<v-checkbox label="Rádio Povo" v-model="pesquisa.comoSoube" value="RadioPovo" :rules="respRules"></v-checkbox>
									<v-checkbox label="Pombal FM" v-model="pesquisa.comoSoube" value="PombalFM" :rules="respRules"></v-checkbox>
								</v-col>
								<v-col>
									<v-checkbox label="Tucano FM" v-model="pesquisa.comoSoube" value="TucanoFM" :rules="respRules"></v-checkbox>
									<v-checkbox label="Amparo FM" v-model="pesquisa.comoSoube" value="AmparoFM" :rules="respRules"></v-checkbox>
									<v-checkbox label="Indicação de amigo" v-model="pesquisa.comoSoube" value="IndicacaoAmigo" :rules="respRules"></v-checkbox>
								</v-col>
							</v-row>
						</v-col>
						<v-col>
							<v-btn class="mr-1 ml-1" color="info" @click="save()" v-if="mode === 'save'" :disabled="!isValid">salvar</v-btn>
							<v-btn class="mr-1 ml-1" color="grey" @click="reset()">cancelar</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
		</v-card>
	</v-container>
</v-dialog>
</template>

<script>
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import Bus from '@/barramento'
	import moment from 'moment'
	import { mapState } from 'vuex'
	export default {

		name: 'DialogPesquisa',
		data () {
			return {
				dialogPesquisa:false,
				pesquisa:{},
				pesquisas:[],
				mode:'save',
				cpf:'',
				respRules: [
					v => !!v || 'campo é obrigatório'
					],
			}
		},
		computed:{
			...mapState(["user"]),
			isValid() {
				return this.respRules.every(rule => rule(this.pesquisa) === true);
			}
		},
		methods:{
			loadPesquisas() {
				axios.get(`${baseApiUrl}/pesquisa`)
				.then(res => {
					this.pesquisas = res.data
				})
			},
			loadPesquisa(item, modo = "save") {
				const pesquisaEncontrada = this.pesquisas.find(p => p.cpf === item);

				if (pesquisaEncontrada) {
					Bus.$emit('loadClient', "");

					this.reset();
				} else {
					this.cpf = item;
					this.pesquisa = {
						cpf: this.cpf,
						emprestimoAnterior: item.emprestimoAnterior,
						comoSoube: item.comoSoube,
					};
					this.mode = modo;
					this.dialogPesquisa = true;
				}
			},

			async save() {
				if (this.$refs.form.validate()) {
					await axios.post(`${baseApiUrl}/pesquisa`, this.pesquisa)
					.then(() => {

						// this.loadPesquisas()
						this.$toasted.global.defaultSuccess()
						Bus.$emit('loadClient', "")
						this.reset()
					})
					.catch(showError)
				}

			},
			// async remove() {
			// 	try {
			// 		await axios.delete(`${baseApiUrl}/pesquisas/${this.pesquisa.id_pesquisa}`)
			// 		this.$toasted.global.defaultSuccess()
			// 		Bus.$emit('loadClient')
			// 		this.dialogPesquisa = false
			// 		this.reset()
			// 	} catch(err) {
			// 		showError(err)
			// 	}
			// },
			reset() {
				this.pesquisa = {}
				this.dialogPesquisa = false
			},
			formatoData(data){
				if(data){
					return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
				}
			},

		},
		mounted() {
			this.loadPesquisas()
			Bus.$on('loadPesquisa', (item) => {
				this.loadPesquisa(item.item,item.modo)
			})
		}
	}
</script>

<style lang="css" scoped>
</style>