<template>
	<v-container>	
		<div v-for="(consultor, index) in dataChart" :key="index">
			<h3>{{ `Consultor: ${consultor.nome}` }}</h3>
			<GChart type="LineChart" :data="generateChartData(consultor)" :options="chartOptions"></GChart>
			<v-divider color="error"></v-divider>
			<v-divider color="black"></v-divider>
		</div>
	</v-container>
</template>

<script>
	export default {
		name: 'GoogleChartLinePerConsultor',
		props: {
			dataChart: { type: Array, default: () => [] },
			options: { type: Object, default: () => ({}) }
		},
		data() {
			return {
				chartOptions: this.options
			};
		},
		methods: {
			generateChartData(consultor) {
				const meses = ['jan.', 'fev.', 'mar.', 'abr.', 'mai.', 'jun.', 'jul.', 'ago.', 'set.', 'out.', 'nov.', 'dez.']
				let chartData = [['Mês', ...consultor.anos.map(ano => ano.ano.toString())]];

				meses.forEach((mes) => {
					let row = [mes];
					consultor.anos.forEach(ano => {
						const data = ano.meses.map(m => {return {mes:m.mes, total:m.total}})
						const mesData = data.find(m => m.mes === mes)
						row.push(mesData ? mesData.total : 0);
					});
					chartData.push(row);
				});

				return chartData;
			}
		}
	};
</script>

<style lang="css" scoped>
</style>
