<template>
	<v-main>
		<router-view/>
		<Footer/>	
	</v-main>
</template>

<script>
	import Footer from './Footer'
	export default {
		name: 'Content',
		components:{ Footer }
	};
</script>

<style lang="css" scoped>
</style>
