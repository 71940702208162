<template>
	<footer>
		<v-col align="center">	
			<img src="../../public/img/logo-footer.png" width="10%" alt="">
		</v-col>
	</footer>
</template>

<script>
export default {

	name: 'Footer',

	data() {
		return {

		};
	},
};
</script>

<style lang="css" scoped>

</style>
