<template>
	<v-container dense>
		<PageTitle icon="mdi mdi-delete" main="Lixeira" sub="" />
		<v-container>
			<v-card>
				<v-card-title primary-title>
					Usuários Excluídos
				</v-card-title>			
				<v-data-table :headers="hduser" :items=usuarios dense>
					<template v-slot:item.deletedAt="{ item }">
						{{formatoData(item.deletedAt)}}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn text x-small outlined color="primary" @click="recuperar(item)">
							recuperar
						</v-btn>
						<v-btn text x-small outlined color="error" @click="remove(item)">
							excluir definitivamente
						</v-btn>
					</template>
				</v-data-table>
			</v-card>
		</v-container>
		<v-container>
			<v-card>
				<v-card-title primary-title>
					Clientes Excluídos
				</v-card-title>			
				<v-data-table :headers="hdclient" :items=clientes dense>
					<template v-slot:item.deletedAt="{ item }">
						{{ formatoData(item.deletedAt) }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn text x-small outlined color="primary" @click="recuperar(item)">
							recuperar
						</v-btn>
						<v-btn text x-small outlined color="error" @click="remove(item)">
							excluir definitivamente
						</v-btn>
					</template>
				</v-data-table>
			</v-card>
		</v-container>
	</v-container>
</template>

<script>
import PageTitle from "@/templates/PageTitle"
import axios from "axios";
import { baseApiUrl, showError } from "@/global"
import moment from 'moment'
export default {
	name: 'Lixeira',
	components:{ PageTitle },
	data() {
		return {
			usuarios: [],
			clientes:[],
			hduser:[
			{text:'Nome',value:'nome'},
			{text:'Data de Exclusão',value:'deletedAt'},
			{text:'Função',value:'cargo'},
			{text:'Ações',value:'actions'}
			],
			hdclient:[
			{text:'Nome',value:'nome_cliente'},
			{text:'Idade',value:'idade'},
			{text:'Data de Exclusão',value:'deletedAt'},
			{text:'Ações',value:'actions'}
			]
			
		};
	},
	methods: {
		loadRemovidos() {
			axios.get(`${baseApiUrl}/lixeira`)
			.then(res => {
				this.usuarios = res.data.usuarios
				this.clientes = res.data.clientes
			})
			.catch(showError)
		},
		remove(item) {
			axios.delete(`${baseApiUrl}/lixeira`, {data: item})
			.then(() => {
				this.$toasted.global.defaultSuccess()
				this.loadRemovidos()
			})
			.catch(showError)
		},
		recuperar(item) {
			axios.post(`${baseApiUrl}/lixeira`, item)
			.then(() => {
				this.$toasted.global.defaultSuccess()
				this.loadRemovidos()
			})
			.catch(showError)
		},
		formatoData(data) {
			if (data) {
				return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
			}
		}
	},
	mounted() {
		this.loadRemovidos()
	}
}
</script>

<style lang="css" scoped>
</style>