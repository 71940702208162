<template>
	<v-container>
		<PageTitle icon="mdi mdi-calendar-account" main="Agendamentos" sub=""></PageTitle>
		<v-dialog v-model="dialog"  max-width="500" transition="dialog-transition">
			<v-card>
				<v-card-title>
					{{ titleForm }}
				</v-card-title>
				<v-card-text>
					<v-col md="6" sm="12">
						<v-text-field type="date" v-model="agendamento.data_agendamento" label="Agendar para:" :disabled="mode === 'remove'" :autofocus="true" :error-messages="validData" :color="validData ? 'success' : 'error'"></v-text-field>
					</v-col>
					<v-col>
						<v-textarea v-model="agendamento.informacao_agendamento" label="Observação" outlined :disabled="mode === 'remove'"></v-textarea>
					</v-col>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" @click="save" v-show="mode === 'save'">salvar</v-btn>
					<v-btn color="error" @click="remove" v-show="mode === 'remove'">excluir</v-btn>
					<v-btn color="grey" dark @click="reset">cancelar</v-btn>
				</v-card-actions>
			</v-card>	  
		</v-dialog>
		<v-row align="center" justify="space-around">
			<v-col>
				<template v-if="!busca">
					<v-tabs v-model="tab" align-with-title color="error">
						<v-tabs-slider :color="tab === 0 ? 'success' : tab === 1 ? 'orange' : 'error'"></v-tabs-slider>
						<v-tab v-for="(item,i) in itemsTab" :key="i" class="font-weight-bold " :class="`${item.color}--text`">
							{{ item.text }}
						</v-tab>
					</v-tabs>
				</template>
			</v-col>
			<v-col>
				<v-text-field v-model="busca" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>
			</v-col>

		</v-row>
		<v-row>
			<v-col md="6" sm="12" v-show="selected.length > 1">
				<v-btn color="success" small dark center class="elevation-5 ml-1 mr-1" @click="exportar">
					exportar clientes 
				</v-btn>
				<v-btn color="error" small dark center class="elevation-5 ml-1 mr-1" @click="remove">
					excluir seleção 
				</v-btn>
			</v-col>
		</v-row>
		<v-col v-if="!busca">
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<v-card flat color="success lighten-3">
						<v-card-title>Agendados para Hoje</v-card-title>
						<v-card-text>
							<v-data-table :headers="table" :items="hoje" :loading="!hoje.length && agendamento.length == 0" show-select item-key="id_agendamento" loading-text="Carregando... Espere por favor!" v-model="selected" class="elevation-1 mt-5">
								<template v-slot:item.alert="{ item }">
									<v-flex xs1 v-show="item.alert.exibir">
										<v-icon :color="item.alert.cor">{{ item.alert.icon }}</v-icon>
									</v-flex>
								</template>
								<template v-slot:item.data_agendamento="{ item }">
									{{ formatoData(item.data_agendamento)}}
									<small class="primary--text" v-if="user.cargo === 'Administrador'">up:{{formatoData(item.atualizadoEm)}}</small>
								</template>
								<template v-slot:item.telefones="{ item }">
									<td>
										<small v-for="(num,i) in item.telefones" :key="i">
											<span style="font-weight: bold">{{ num.numero_telefone }}</span>
										</small>
									</td>				
								</template>
								<template v-slot:item.actions="{ item }">
									<v-btn block x-small color="info"  class="mb-2 mt-2" @click="addContrato(item ,'save')">Add Contrato</v-btn>
									<v-btn block x-small color="green" class="mb-2 mt-2" dark  @click="loadAgendamento(item ,'save')">Reagendar</v-btn>
									<v-btn block x-small color="error" class="mb-2 mt-2"  @click="loadAgendamento(item ,'remove')">Exlcuir</v-btn>
								</template>
							</v-data-table>			
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat color="orange lighten-3">
						<v-card-title>Agendados para Amanhã</v-card-title>
						<v-card-text>				
							<v-data-table :headers="table" :items="amanha" :loading="!amanha.length && agendamento.length == 0" show-select item-key="id_agendamento" loading-text="Carregando... Espere por favor!" v-model="selected" class="elevation-1 mt-5">
								<template v-slot:item.alert="{ item }">
									<v-flex xs1 v-show="item.alert.exibir">
										<v-icon :color="item.alert.cor">{{ item.alert.icon }}</v-icon>
									</v-flex>
								</template>
								<template v-slot:item.data_agendamento="{ item }">
									{{ formatoData(item.data_agendamento)}}
									<small class="primary--text" v-if="user.cargo === 'Administrador'">up:{{formatoData(item.atualizadoEm)}}</small>
								</template>
								<template v-slot:item.telefones="{ item }">
									<td>
										<small v-for="(num,i) in item.telefones" :key="i">
											<span style="font-weight: bold">{{ num.numero_telefone }}</span>
										</small>
									</td>				
								</template>
								<template v-slot:item.actions="{ item }">
									<v-btn block x-small color="info"  class="mb-2 mt-2" @click="addContrato(item ,'save')">Add Contrato</v-btn>
									<v-btn block x-small color="green" class="mb-2 mt-2" dark  @click="loadAgendamento(item ,'save')">Reagendar</v-btn>
									<v-btn block x-small color="error" class="mb-2 mt-2"  @click="loadAgendamento(item ,'remove')">Exlcuir</v-btn>
								</template>
							</v-data-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat color="error lighten-3">
						<v-card-title>Agendamentos não tratados</v-card-title>
						<v-card-text>				
							<v-data-table :headers="table" :items="vencidos" :loading="!vencidos.length && agendamento.length == 0" show-select item-key="id_agendamento" loading-text="Carregando... Espere por favor!" v-model="selected" class="elevation-1 mt-5">
								<template v-slot:item.alert="{ item }">
									<v-flex xs1 v-show="item.alert.exibir">
										<v-icon :color="item.alert.cor">{{ item.alert.icon }}</v-icon>
									</v-flex>
								</template>
								<template v-slot:item.data_agendamento="{ item }">
									{{ formatoData(item.data_agendamento)}}
									<small class="primary--text" v-if="user.cargo === 'Administrador'">up:{{formatoData(item.atualizadoEm)}}</small>
								</template>
								<template v-slot:item.telefones="{ item }">
									<td>
										<small v-for="(num,i) in item.telefones" :key="i">
											<span style="font-weight: bold">{{ num.numero_telefone }}</span>
										</small>
									</td>				
								</template>
								<template v-slot:item.actions="{ item }">
									<v-btn block x-small color="info"  class="mb-2 mt-2" @click="addContrato(item ,'save')">Add Contrato</v-btn>
									<v-btn block x-small color="green" class="mb-2 mt-2" dark  @click="loadAgendamento(item ,'save')">Reagendar</v-btn>
									<v-btn block x-small color="error" class="mb-2 mt-2"  @click="loadAgendamento(item ,'remove')">Exlcuir</v-btn>
								</template>
							</v-data-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
		<v-col v-else>
			<v-card flat color="info lighten-3">
				<v-card-title>Resultado Busca</v-card-title>
				<v-card-text>				
					<v-data-table :headers="table" :items="agendamentos" :loading="!agendamentos.length && agendamento.length == 0" show-select item-key="id_agendamento" loading-text="Carregando... Espere por favor!" v-model="selected" class="elevation-1 mt-5">
						<template v-slot:item.alert="{ item }">
							<v-flex xs1 v-show="item.alert.exibir">
								<v-icon :color="item.alert.cor">{{ item.alert.icon }}</v-icon>
							</v-flex>
						</template>
						<template v-slot:item.data_agendamento="{ item }">
							{{ formatoData(item.data_agendamento)}}
							<small class="primary--text" v-if="user.cargo === 'Administrador'">up:{{formatoData(item.atualizadoEm)}}</small>
						</template>
						<template v-slot:item.telefones="{ item }">
							<td>
								<small v-for="(num,i) in item.telefones" :key="i">
									<span style="font-weight: bold">{{ num.numero_telefone }}</span>
								</small>
							</td>				
						</template>
						<template v-slot:item.actions="{ item }">
							<v-btn block x-small color="info"  class="mb-2 mt-2" @click="addContrato(item ,'save')">Add Contrato</v-btn>
							<v-btn block x-small color="green" class="mb-2 mt-2" dark  @click="loadAgendamento(item ,'save')">Reagendar</v-btn>
							<v-btn block x-small color="error" class="mb-2 mt-2"  @click="loadAgendamento(item ,'remove')">Exlcuir</v-btn>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-dialog v-model="dialogContrato" fullscreen>
			<v-card>
				<v-card-title>{{ contratoTitle }}</v-card-title>
				<v-card-text>
					<v-form @submit.stop.prevent>
						<v-row>
							<v-col md="4" sm="12">
								<v-text-field
								id="contrato-matricula"
								label="Matrícula"
								v-model="contrato.matricula_contrato"
								type="number"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-text-field>
							</v-col>
							<v-col md="4" sm="12">
								<v-text-field
								id="contrato-numero"
								label="Número Contrato"
								v-model="contrato.numero_contrato"
								type="number"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-text-field>
							</v-col>
							<v-col md="2" sm="12">
								<v-text-field
								id="contrato-data"
								label="Data Contrato"
								v-model="contrato.data_contrato"
								type="date"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-text-field>
							</v-col>
							<v-col md="2" sm="12">
								<v-currency-field
								id="contrato-valor"
								label="Valor Contrato"
								v-model="contrato.valor_contrato"
								type="text"
								size="sm"
								:disabled="mode === 'remove'"
								:rules="valorReq"
								></v-currency-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="4" sm="12">
								<v-autocomplete
								:items="tabelaBanco"
								:item-text="
								(item) => item.nome_tabela + ' (' + item.nome_banco + ')'
								"
								:item-value="(item) => item"
								v-model="contrato.bancoTabela"
								label="Tabela"
								required
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-autocomplete>
							</v-col>
							<v-col md="3" sm="12">
								<v-select
								:items="produtos"
								v-model="contrato.produto_contrato"
								item-text="nome_produto"
								item-value="nome_produto"
								label="Produto"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
							<v-col md="2" sm="12">
								<v-select
								:items="convenios"
								v-model="contrato.convenio_contrato"
								item-text="nome_convenio"
								label="Convênio"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col md="2" sm="12">
								<v-select
								:items="prazos"
								v-model="contrato.prazo_contrato"
								item-text="prazo"
								label="Prazo"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
							<v-col md="3" sm="12">
								<v-select
								:items="pagamentos"
								v-model="contrato.tipo_pagamento"
								label="Tipo Pagamento"
								:disabled="mode === 'remove'"
								:rules="requerido"
								></v-select>
							</v-col>
							<v-col md="3">
								<v-select
								:items="formalizacao"
								v-model="contrato.formalizacao"
								label="Tipo Formalizacão"
								:disabled="mode === 'remove'"
								require
								:rules="requerido"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea
								label="Informações"
								outlined
								v-model="contrato.informacoes"
								:disabled="mode === 'remove'"
								></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-col md="12" sm="12" class="text-center">
						<v-btn
						color="primary"
						@click="saveContrato"
						v-show="mode === 'save'"
						>salvar</v-btn
						>
						<v-btn color="grey" class="ml-2" dark @click="reset"
						>cancelar</v-btn
						>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import { validationMixin } from "vuelidate";
	import { required } from "vuelidate/lib/validators";
	import { cpf } from "cpf-cnpj-validator";
	import { mapState } from 'vuex'
	import PageTitle from '@/templates/PageTitle'
	import axios from 'axios'
	import { baseApiUrl, showError } from '@/global'
	import Bus from '@/barramento'
	import moment from 'moment'
	// import _ from 'lodash'
	export default {

		name: 'Agendamentos',
		components:{ PageTitle },
		data() {
			return {
				selected:[],
				titleForm:'', 
				mode:'',
				dialog:false,
				dialogContrato: false,
				contrato:{},
				contratoTitle:'',
				requerido: [(v) => !!v || "*"],
				valorReq: [(v) => !!v || v == 0 || "*"],
				tabelas: [],
				bancos: [],
				produtos: [],
				convenios: [],
				prazos: [],
				busca:'',
				buscaDebounce: null,
				agendamentos:[],
				hoje:[],
				amanha:[],
				vencidos:[],
				agendamento:{},
				ruleData:[
					v => v && this.validData
					],
				table:[
					{text:'Data Agendado', value:'data_agendamento'},
					{text:'Cliente', value:'nome_cliente'},
					{text:'CPF', value:'cpf_cliente'},
					{text:'Consultor(a)', value:'nome'},
					{text:'Telefone', value:'telefones'},
					{text:'Informacoes', value:'informacao_agendamento'},
					{text:'Ações', value:'actions'}
					],
				pagamentos: [
					{ text: "OP", value: "OP" },
					{ text: "CONTA", value: "CONTA" },
					],
				formalizacao: [
					{ text: "Digital", value: "Digital" },
					{ text: "Física", value: "Fisica" },
					],
				mixins: [validationMixin],
				validations: {
					client: {
						cpf_cliente: { required },
					},
				},
				tab:null,
				itemsTab:[
					{text:'Hoje', color:'success'},
					{text:'Amanhã',color:'orange'},
					{text:'Não tratados', color:'error'}
					]

			};
		},
		methods: {
			async loadAgendamentos() {
				try {
					const res = await axios.get(`${baseApiUrl}/agendamentos`);
					const hoje = res.data.agendamentosParaHoje
					const amanha = res.data.agendamentosParaAmanha
					const vencidos = res.data.agendamentosVencidos

					const cargosPermitidos = ['Administrador', 'Supervisor', 'Financeiro'];

					const arrHoje = cargosPermitidos.includes(this.user.cargo) ? hoje : hoje.filter(a => this.user.id === a.fk_usuario);
					const arrAmanha = cargosPermitidos.includes(this.user.cargo) ? amanha : amanha.filter(a => this.user.id === a.fk_usuario);
					const arrVencidos = cargosPermitidos.includes(this.user.cargo) ? vencidos : vencidos.filter(a => this.user.id === a.fk_usuario);
					this.hoje = arrHoje.map(a => ({
						id_agendamento: a.id_agendamento,
						fk_cliente: a.fk_cliente,
						nome: a.nome,
						data_agendamento: a.data_agendamento,
						nome_cliente: a.nome_cliente,
						cpf_cliente: a.cpf_cliente,
						telefones: a.telefones,
						informacao_agendamento: a.informacao_agendamento,
						criadoEm: a.criado,
						atualizadoEm: a.atualizado,
					}));
					this.amanha = arrAmanha.map(a => ({
						id_agendamento: a.id_agendamento,
						fk_cliente: a.fk_cliente,
						nome: a.nome,
						data_agendamento: a.data_agendamento,
						nome_cliente: a.nome_cliente,
						cpf_cliente: a.cpf_cliente,
						telefones: a.telefones,
						informacao_agendamento: a.informacao_agendamento,
						criadoEm: a.criado,
						atualizadoEm: a.atualizado,
					}));
					this.vencidos = arrVencidos.map(a => ({
						id_agendamento: a.id_agendamento,
						fk_cliente: a.fk_cliente,
						nome: a.nome,
						data_agendamento: a.data_agendamento,
						nome_cliente: a.nome_cliente,
						cpf_cliente: a.cpf_cliente,
						telefones: a.telefones,
						informacao_agendamento: a.informacao_agendamento,
						criadoEm: a.criado,
						atualizadoEm: a.atualizado,
						alert: this.agVencido(a.data_agendamento)
					}));
				} catch (error) {
					showError(error);
				}
			},

			loadAgendamento(item, mode = 'save') {
				this.agendamento = {
					id_agendamento:item.id_agendamento,
					fk_cliente:item.fk_cliente,
					data_agendamento:moment(item.data_agendamento,'YYYY-MM-DD').format('YYYY-MM-DD'),
					informacao_agendamento:item.informacao_agendamento
				}
				this.mode = mode
				this.dialog = true
				this.titleForm = item !== '' && this.mode === 'remove' ? `Excluir Agendamento - ${ item.nome_cliente} ?` : `Alterar Agendamento - ${ item.nome_cliente }`
			},
			loadTabelas() {
				axios
				.get(`${baseApiUrl}/tabelas`)
				.then((res) => {
					this.tabelas = res.data;
				})
				.catch(showError);
			},
			loadBancos() {
				axios
				.get(`${baseApiUrl}/bancos`)
				.then((res) => {
					this.bancos = res.data;
				})
				.catch(showError);
			},
			loadProdutos() {
				axios
				.get(`${baseApiUrl}/produtos`)
				.then((res) => {
					this.produtos = res.data;
				})
				.catch(showError);
			},
			loadConvenios() {
				axios
				.get(`${baseApiUrl}/convenios`)
				.then((res) => {
					this.convenios = res.data;
				})
				.catch(showError);
			},
			loadPrazos() {
				axios
				.get(`${baseApiUrl}/prazos`)
				.then((res) => {
					this.prazos = res.data;
				})
				.catch(showError);
			},
			save() {
				const metodo = this.agendamento.id_agendamento ? 'put' : 'post'

				const id = this.agendamento.id_agendamento ? `/${this.agendamento.id_agendamento}` : ''
				axios[metodo](`${baseApiUrl}/agendamentos${id}`, this.agendamento).then(() => {
					this.$toasted.global.defaultSuccess()
					this.loadAgendamentos()
					this.dialog = false
					this.reset()
				})
				.catch(showError)
			},
			addContrato(item, mode = "save") {
				this.contrato.fk_cliente = item.fk_cliente;
				this.mode = mode;
				this.dialogContrato = true;
				this.contratoTitle = "Cadastrar Novo Contrato";
			},
			saveContrato() {
				const metodo = this.contrato.id_contrato ? "put" : "post";
				this.contrato.fk_usuario =
				metodo === "post" ? this.user.id : this.contrato.fk_usuario;
				const id = this.contrato.id_contrato
				? `/${this.contrato.id_contrato}`
				: "";
				this.contrato.ativo =
				this.contrato.formalizacao === "digital" ? false : true;
				axios[metodo](`${baseApiUrl}/contratos${id}`, this.contrato)
				.then(() => {
					this.$toasted.global.defaultSuccess();
					this.loadAgendamentos();
					this.reset();
				})
				.catch(showError);
			},
			remove() {
				let promises = []
				if(this.selected.length > 1) {
					for(var i in this.selected){
						promises.push(
							axios.delete(`${baseApiUrl}/agendamentos/${this.selected[i].id_agendamento}`)
							
							)
					}
					Promise.all(promises).then(() => {
						this.$toasted.global.defaultSuccess()
						this.loadAgendamentos()
						this.reset()
						Bus.$emit('reload')
					}).catch(showError)
				}else{
					const id = this.agendamento.id_agendamento
					axios.delete(`${baseApiUrl}/agendamentos/${id}`)
					.then(() => {

						this.$toasted.global.defaultSuccess()
						this.dialog = false
						this.loadAgendamentos()
						this.reset()
						Bus.$emit('reload')
					})
					.catch(showError)
				}
			},
			reset(){
				this.dialog = false
				this.dialogContrato = false
				this.agendamento = {}
				this.selected = []
			},
			formatoData(data){
				if(data){
					return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY")	
				}
			},
			agVencido(data) {
				let resp = {}
				let dias = moment().diff(moment(data,"YYYY-MM-DD"),'days')
				resp = dias >= 0 ? { cor: 'error', exibir:true, icon:'mdi-alert-box' } :''
				return resp
			},
			exportar() {
				if(this.selected) {
					const clientes = [];
					this.selected.forEach(sel => {
						if (Array.isArray(sel.telefones) && sel.telefones.length) {
							sel.telefones.forEach(telefone => {
								clientes.push({
									nome: sel.nome_cliente,
									tel: telefone.numero_telefone
								});
							});
						} else {
							clientes.push({
								nome: sel.nome_cliente,
								tel: '' 
							});
						}
					});
					this.exportCSV(clientes, 'contatos_agendamentos_saesoconsig')
				}
			},
			exportCSV(arr, arquivoName) {
				let content = "data:text/csv;charset=utf-8,";
				content += [
					Object.keys(arr[0]).join(";"),
					...arr.map(item => Object.values(item).join(";"))
					].join("\n").replace(/(^\[)|(\]$)/gm, "");

				const data = encodeURI(content);
				const num = moment(new Date()).format('DMY.Hms');
				const link = document.createElement("a");

				link.setAttribute("href", data);
				link.setAttribute("download", `${arquivoName}-${num}.csv`);
				link.click();

				this.reset();
			},
			async searchAgendamentos() {
				if (!this.busca) {
					this.loadAgendamentos();
					return;
				}
				try {
					let arr = []
					
					const res = await axios.get(`${baseApiUrl}/agendamentos`,{
						params:{
							q:this.busca
						}
					})
					if(this.user.cargo === 'Administrador' || this.user.cargo === 'Supervisor' || this.user.cargo === 'Financeiro') {
						arr = res.data.getBuscaAgendamentos
					}else {	
						res.data.getBuscaAgendamentos.map(a => {
							if(this.user.id === a.fk_usuario){		
								arr.push(a) 
							}

						})
					}
					this.agendamentos = arr.map(a => {
						return {
							id_agendamento:a.id_agendamento,
							fk_cliente:a.fk_cliente,
							nome:a.nome,
							data_agendamento:a.data_agendamento,
							nome_cliente:a.nome_cliente,
							cpf_cliente:a.cpf_cliente,
							telefones:a.telefones,
							informacao_agendamento:a.informacao_agendamento,
							criadoEm:a.criado,
							atualizadoEm:a.atualizado,
							alert:this.agVencido(a.data_agendamento)
						}
					})
					
				} catch (error) {
					console.error("Erro ao buscar agendamentos:", error);
				}
			}
		},
		watch: {
			busca:{
				handler: 'searchAgendamentos',
				immediate: false,
				deep: false,
			}
		},
		computed: {
			...mapState(['user']),
			validData() {
				const erros = []
				let hoje = moment().format('YYYY-MM-DD')
				this.agendamento.data_agendamento >= hoje ? erros : erros.push('Data não pode ser menor nem igual a hoje!')

				return erros
			},
			validationCpf() {
				const errors = [{msg:"", color:"error", status:true}];
				if (this.client.cpf_cliente){
					cpf.isValid(this.client.cpf_cliente)
					? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
				}
				if (this.cpf){
					cpf.isValid(this.cpf) ? errors[0] = {msg:'',color:'success',status:false} : errors[0] = {msg:"CPF inválido!",color:'error', status:true};
				}
				return errors;
			},
			tabelaBanco() {
				var tab = [];
				this.tabelas.map((t, i) => {
					this.bancos.map((b) => {
						if (t.fk_banco_tabela === b.id_banco) {
							tab[i] = {
								nome_tabela: t.nome_tabela,
								comissao_tabela: t.comissao_tabela,
								nome_banco: b.nome_banco,
							};
						}
					});
				});
				return tab;
			}
		},
		mounted() {
			this.loadAgendamentos()
			this.loadTabelas();
			this.loadBancos();
			this.loadProdutos();
			this.loadConvenios();
			// this.loadPrazos();
			
		}
	};
</script>

<style lang="css" scoped>
</style>
