<template>
	<v-container>
    <v-dialog v-model="dialog" max-width="400">
      <template v-slot:activator="{ on }">
        <v-col md="6" sm="12">
          <v-btn @click="loadPromotora('', 'save')" x-small color="error" v-on="on" outlined>Nova Promotora</v-btn>
        </v-col>
      </template>
      <v-card>
        <v-card-title primary-title>
          {{ titleForm }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col>   
                <v-text-field v-model="promotora.nome_promotora" label="Nome" required :disabled="mode === 'remove'" @keydown.enter.prevent="save"></v-text-field>
              </v-col>
              <v-col>   
                <v-select :items="bancos" v-model="promotora.fk_banco" item-text="nome_banco" item-value="id_banco" label="Banco" chips multiple required :disabled="mode === 'remove'" @keydown.enter.prevent="save" dense></v-select>

              </v-col>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn color="primary" class="mr-1" v-if="mode === 'save'" @click="save">salvar</v-btn>
                    <v-btn color="error" class="mr-1" v-else @click="remove">exluir</v-btn>
                    <v-btn color="grey" class="ml-1" dark @click="reset">cancelar</v-btn>     
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table :headers="table" :items="promotoras" class="elevation-1" :search="busca" :loading="loading"
    loading-text="Carregando... Espere por favor!">
    <template v-slot:item.bancos ="{ item }">
      <small v-for="b in item.bancos" :key="b.fk_banco" class="mr-1 info--text font-weight-bold" style="font-size: 65%">{{ b.nome_banco }}</small>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn icon small color="orange" @click="loadPromotora(item, 'save')"><v-icon small>mdi-pencil</v-icon></v-btn>
      <v-btn icon small color="error" @click="loadPromotora(item, 'remove')"><v-icon small>mdi-delete</v-icon></v-btn>
    </template>
  </v-data-table>
</v-container>
</template>

<script>
  import axios from 'axios'
  import { baseApiUrl, showError } from '@/global'
  export default {

    name: 'Promotoras',

    data () {
      return {
        loading:true,
        busca:'',
        mode:'save',
        titleForm:'',
        dialog:false,
        promotora:{},
        promotoras:[],
        bancos:[],
        table:[
        {text:'Promotora', value:'nome_promotora'},
        {text:'Banco', value:'bancos'},
        {text:'Ações', value:'actions'},
        ]
      }
    },
    methods:{
      loadPromotoras() {
        this.loading
        axios.get(`${baseApiUrl}/promotoras`)
        .then(res => {
          if(res.data) {
            this.loading = false
          }
          this.promotoras = res.data
        })
        .catch(showError)
      },
      loadPromotora(item, mode = 'save') {
        this.dialog = true,
        this.mode = mode
        if(item) {
          this.promotora = {
            id_promotora: item.id_promotora,
            nome_promotora:item.nome_promotora,
            fk_banco:item.bancos.map(b => b.fk_banco)
          }
        }
        else {
          this.promotora = {}
        }

        this.titleForm = item.id_promotora === undefined ? `Novo Promotora` :
        this.mode === 'remove' ? `Excluir Promotora ${ item.nome_promotora } ?` : `Editar Promotora - ${ item.nome_promotora}`
      },
      loadBancos() {
        axios.get(`${baseApiUrl}/bancos`)
        .then(res => this.bancos = res.data)
        .catch(showError)
      },
      save() {
        const metodo = this.promotora.id_promotora ? 'put' : 'post'
        const id = this.promotora.id_promotora ? `/${this.promotora.id_promotora}` : ''

        axios[metodo](`${baseApiUrl}/promotoras${id}`, this.promotora).then(() => {
          this.$toasted.global.defaultSuccess()
          this.loadPromotoras()
          this.dialog = false
        })
        .catch(showError)
      },
      remove() {

      },
      reset() {
        this.promotora = {}
        this.dialog = false
      }
    },
    mounted() {
      this.loadPromotoras()
      this.loadBancos()
    }
  }
</script>

<style lang="css" scoped>
</style>